import { AddIcon, CheckIcon, StarIcon } from '@chakra-ui/icons';
import { useDisclosure, useTheme } from '@chakra-ui/react';
import { BsPeople } from 'react-icons/bs';
import { FiMap } from 'react-icons/fi';
import { GoDeviceMobile } from 'react-icons/go';
import { useIntl } from 'react-intl';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { StartTimeLineArticle } from './StartTimeLineArticle';
import { InstallAppModal } from './modals/InstallAppModal';

export const StartTimeline: React.FC = () => {
    const theme = useTheme();
    const intl = useIntl();
    const textColor = 'gray.400';
    const titleColor = theme.colors.whiteAlpha[800];
    const backgroundColors = theme.colors.elevation[500];
    const anchorColor = theme.colors.primary[300]

    const installAppDisclosure = useDisclosure();

    const commonContentStyle = {
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        borderRadius: '10px',
        color: theme.colors.primary[300]
    };
    return (
        <>
            <style>{`
                .vertical-timeline-element--work h2 {
                    color: ${titleColor} !important;
                }
                .vertical-timeline-element-content p {
                    margin: 0 !important;
                }
            `}</style>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: backgroundColors, ...commonContentStyle }}
                    date={intl.formatMessage({ id: 'about.timeline.register.subtitle' })}
                    iconStyle={{ background: theme.colors.surface[500], color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${anchorColor}` }}
                    icon={<StarIcon color={theme.colors.secondary[500]} />}
                >
                    <StartTimeLineArticle 
                        title={intl.formatMessage({ id: 'about.timeline.register.title' })}
                        text={intl.formatMessage({ id: 'about.timeline.register.text' })}
                        buttonLinkTo={'/register'}
                        textColor={textColor}
                        web
                    />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: backgroundColors, ...commonContentStyle }}
                    date={intl.formatMessage({ id: 'about.timeline.downloadApp.subtitle' })}
                    iconStyle={{ background: theme.colors.surface[500], color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${anchorColor}` }}
                    icon={<GoDeviceMobile color={theme.colors.secondary[500]} />}
                >
                    <StartTimeLineArticle 
                        title={intl.formatMessage({ id: 'about.timeline.downloadApp.title' })}
                        text={intl.formatMessage({ id: 'about.timeline.downloadApp.text' })}
                        onClick={installAppDisclosure.onOpen}
                        textColor={textColor}
                        mobile
                    />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: backgroundColors, ...commonContentStyle }}
                    date={intl.formatMessage({ id: 'about.timeline.addTeam.subtitle' })}
                    iconStyle={{ background: theme.colors.surface[500], color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${anchorColor}` }}
                    icon={<BsPeople color={theme.colors.secondary[500]} />}
                >
                    <StartTimeLineArticle 
                        title={intl.formatMessage({ id: 'about.timeline.addTeam.title' })}
                        text={intl.formatMessage({ id: 'about.timeline.addTeam.text' })}
                        buttonLinkTo={'/docs/employees'}
                        textColor={textColor}
                        web
                        mobile
                    />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: backgroundColors, ...commonContentStyle }}
                    date={intl.formatMessage({ id: 'about.timeline.addZones.subtitle' })}
                    iconStyle={{ background: theme.colors.surface[500], color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${anchorColor}` }}
                    icon={<FiMap color={theme.colors.secondary[500]} />}
                >
                    <StartTimeLineArticle 
                        title={intl.formatMessage({ id: 'about.timeline.addZones.title' })}
                        text={intl.formatMessage({ id: 'about.timeline.addZones.text' })}
                        buttonLinkTo={'/docs/zones'}
                        textColor={textColor}
                        mobile
                        web
                    />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: backgroundColors, ...commonContentStyle }}
                    date={intl.formatMessage({ id: 'about.timeline.teamAccount.subtitle' })}
                    iconStyle={{ background: theme.colors.surface[500], color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${anchorColor}` }}
                    icon={<GoDeviceMobile color={theme.colors.secondary[500]} />}
                >
                    <StartTimeLineArticle 
                        title={intl.formatMessage({ id: 'about.timeline.teamAccount.title' })}
                        text={intl.formatMessage({ id: 'about.timeline.teamAccount.text' })}
                        buttonLinkTo={'/docs/employees#team_account'}
                        textColor={textColor}
                        mobile
                        web
                    />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: backgroundColors, ...commonContentStyle }}
                    date={intl.formatMessage({ id: 'about.timeline.attendance.subtitle' })}
                    iconStyle={{ background: theme.colors.surface[500], color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${anchorColor}` }}
                    icon={<AddIcon color={theme.colors.secondary[500]} />}
                >
                    <StartTimeLineArticle 
                        title={intl.formatMessage({ id: 'about.timeline.attendance.title' })}
                        text={intl.formatMessage({ id: 'about.timeline.attendance.text' })}
                        buttonLinkTo={'/docs/attendance'}
                        textColor={textColor}
                        mobile
                    />
                </VerticalTimelineElement>
                <VerticalTimelineElement iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }} icon={<CheckIcon />} />
            </VerticalTimeline>
            <InstallAppModal isOpen={installAppDisclosure.isOpen} onClose={installAppDisclosure.onClose} />
        </>
    );
};
