import { useState } from 'react';
import { AttendanceReport, StoredWorkDayReport, User } from '../../types';
import { db } from './db';
import { v4 } from 'uuid';
import { DocumentStatus } from '../../types/enums/DocumentStatus';
import { AttendanceStatement } from '../../types/documents';
import { transformUploadedStatementToStored } from '../../utils/DocumentUtils';
import moment from 'moment';

export const useStoreLocalReport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    async function storeNewStatement(data: AttendanceReport, loggedUser: User) {
        setIsLoading(true);
        try {
            const id = await db.attendanceStatements.add({
                companyId: loggedUser.companyId!,
                externalId: undefined,
                name: data.name,
                userId: data.userId,
                dateFrom: data.dateFrom,
                dateTo: data.dateTo,
                reports: data.reports.map(
                    (day) =>
                        ({
                            date: day.date,
                            id: v4(),
                            shifts: day.shifts.map((shift) => ({
                                ...shift,
                                id: v4(),
                            })),
                        }) as StoredWorkDayReport
                ),

                identifier: createNewStatementIdentifier(data),
                status: DocumentStatus.DRAFT,
                lastChangeAt: new Date().toISOString(),
                createdAt: new Date().toISOString(),
                lastUploadAt: null,
                suspendedHours: 0,
                totalHours: data.totalHours,
            });
            setIsLoading(false);
            return id;
        } catch (error) {
            console.error('Error storing report', error);
            setIsLoading(false);
            setIsError(true);
        }
    }

    async function storeLocalCopy(data: AttendanceStatement, loggedUser: User) {
        const mappedData = transformUploadedStatementToStored(data, loggedUser);
        setIsLoading(true);

        try {
            const id = await db.attendanceStatements.add(mappedData);
            setIsLoading(false);
            return id;
        } catch (error) {
            console.error('Error storing report', error);
            setIsLoading(false);
            setIsError(true);
        }
    }

    function createNewStatementIdentifier(data: AttendanceReport): string {
        const dateFrom = moment(data.dateFrom).format('DD_MM_YY');
        const dateTo = moment(data.dateTo).format('DD_MM_YY');
        return `${'VD'}_${data.name.replace(' ', '_')}-${dateFrom}-${dateTo}`;
    }

    return {
        storeNewStatement,
        isLoading,
        isError,
        storeLocalCopy,
    };
};

export const useDeleteLocalStatement = () => {
    async function deleteStatement(localId: number) {
        return await db.attendanceStatements.delete(localId);
    }

    return { deleteStatement };
};
