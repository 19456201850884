import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

export const IntroDone: React.FC<{ onFinish: VoidFunction }> = ({ onFinish }) => {
    const intl = useIntl();

    return (
        <Box pl={5}>
            <Heading>{intl.formatMessage({ id: 'intro.quickStart.done.title' })}</Heading>
            <Box>{intl.formatMessage({ id: 'intro.quickStart.done.text' })}</Box>
            <Flex mt={5} justifyContent={'center'}>
                <Button size={'lg'} rightIcon={<ChevronRightIcon />} onClick={onFinish}>
                    {intl.formatMessage({ id: 'intro.quickStart.done.finish' })}
                </Button>
            </Flex>
        </Box>
    );
};
