import { Heading, VStack, Text, useTheme } from "@chakra-ui/react";
import { ReactNode } from "react";


type ArticleProps = {
    text: string;
    title: string;
    titleColor?: string;
    textColor?: string;
    icon?: ReactNode;
    align?: 'start' | 'center' | 'end';
}

export const Article: React.FC<ArticleProps> = ({title, text, icon = null, titleColor, textColor, align}) => {
    const theme = useTheme();

    return (
        <VStack alignItems={'start'}>
            {icon}
            <Heading textAlign={align} width={'100%'} color={titleColor || theme.colors.primary[300]} size="md">
                {title}
            </Heading>
            <Text textAlign={align}  color={textColor} fontWeight={'500'}>{text}</Text>
        </VStack>
    );
};
