import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import './FeatureTabs.css';
import { FeatureLogAttendanceTab } from './Tabs/FeatureLogAttendanceTab';
import { FeatureAttendanceList } from './Tabs/FeatureAttendanceList';
import { FeatureAttendanceReport } from './Tabs/FeatureAttendanceReport';
import { useIntl } from 'react-intl';
import { mode } from '@chakra-ui/theme-tools'
import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);


const onBackgroundVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props // extract colorScheme from component props

  return {
    tab: {
      border: '1px solid',
      borderColor:mode(`white`, `gray.800`)(props),
      // use colorScheme to change background color with dark and light mode options
      bg: mode(`white`, `gray.700`)(props),
      borderTopRadius: 'xl',
      borderBottom: 'none',
      _selected: {
        backgroundColor: mode(`gray.200`, `gray.800`)(props),
        borderColor: 'inherit',
        color: mode(`${c}.600`, `${c}.300`)(props),
        borderBottom: 'none',
        mb: '-1px',
      },
    },
    root: {
        borderColor: mode(`gray.400`, `gray.600`)(props)
    },
    tablist: {
      borderBottom: '2x solid',
      borderColor: 'inherit',
    },
    tabpanel: {
      border: '1px solid',
      borderColor: 'inherit',
      borderBottomRadius: 'xl',
    },
    tabpanels: {
        borderColor: mode(`gray.400`, `gray.600`)(props),
        backgroundColor: mode(`gray.200`, `gray.800`)(props),
        borderBottomRadius: 'xl',
    }
  }
})

const variants = {
  onBackground: onBackgroundVariant,
}

export const tabsTheme = defineMultiStyleConfig({ variants })

export const FeatureTabs: React.FC = () => {
    const intl = useIntl();

    return (
        <Tabs align="center" isManual variant="enclosed" isFitted>
            <TabList mx={{ base: 0, xl: 10 }}>
                <Tab className="feature_tab_header">{intl.formatMessage({ id: 'about.tabs.attendance' })}</Tab>
                <Tab className="feature_tab_header">{intl.formatMessage({ id: 'about.tabs.attendanceList' })}</Tab>
                <Tab className="feature_tab_header">{intl.formatMessage({ id: 'about.tabs.attendanceReport' })}</Tab>
            </TabList>
            <TabPanels h={{ base: undefined, md: '90vh' }}>
                <TabPanel>
                    <FeatureLogAttendanceTab />
                </TabPanel>
                <TabPanel>
                    <FeatureAttendanceList />
                </TabPanel>
                <TabPanel>
                    <FeatureAttendanceReport />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};
