import {
    Box,
    Heading,
    SimpleGrid,
    Step,
    StepIcon,
    StepIndicator,
    Stepper,
    StepSeparator,
    StepStatus,
    Text,
    useSteps,
} from '@chakra-ui/react';
import { Fit, Layout, useRive, useStateMachineInput } from '@rive-app/react-canvas';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export const FeatureLogAttendanceTab: React.FC = () => {
    const intl = useIntl();
    const steps = [
        {
            title: intl.formatMessage({ id: 'about.attendanceDemo.openApp.title' }),
            description: intl.formatMessage({ id: 'about.attendanceDemo.openApp.description' }),
        },
        {
            title: intl.formatMessage({ id: 'about.attendanceDemo.selectAccount.title' }),
            description: intl.formatMessage({ id: 'about.attendanceDemo.selectAccount.description' }),
        },
        {
            title: intl.formatMessage({ id: 'about.attendanceDemo.takePhoto.title' }),
            description: intl.formatMessage({ id: 'about.attendanceDemo.takePhoto.description' }),
        },
        {
            title: intl.formatMessage({ id: 'about.attendanceDemo.done.title' }),
            description: intl.formatMessage({ id: 'about.attendanceDemo.done.description' }),
        },
    ];

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });
    
    const { rive, RiveComponent } = useRive({
        src: `${process.env.PUBLIC_URL}/animations/terminal_flow_v2.riv`,
        stateMachines: 'successFlow',
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
        }),
    });
    
    const stepInput = useStateMachineInput(rive, 'successFlow', 'step', 0);
    
    useEffect(() => {
        if (stepInput) {
            stepInput.value = activeStep - 1;
        }
    }, [activeStep, stepInput]);

    return (
        <Box maxW={'1300px'}>
            <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box p={4} textAlign={'left'} display={'flex'} alignContent={'end'} alignItems={'center'} flexDir={'column'}>
                    <Stepper
                        alignContent={'center'}
                        display={'flex'}
                        colorScheme="secondary"
                        index={activeStep}
                        orientation="vertical"
                        minH="300px"
                        gap="0"
                        height={'100%'}
                    >
                        {steps.map((step, index) => (
                            <Step role="group" key={index} onClick={() => setActiveStep(index + 1)} style={{ cursor: 'pointer' }}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon />}
                                        incomplete={<Text color={'white'}>{index + 1}</Text>}
                                        active={<Text color={'white'}>{index + 1}</Text>}
                                    />
                                </StepIndicator>

                                <Box>
                                    <Heading size={'md'} color={'whiteAlpha.900'} _groupHover={{ textDecoration: 'underline' }}>
                                        {step.title}
                                    </Heading>

                                    <Text maxW={500} fontSize={'md'} color={'whiteAlpha.700'}>
                                        {step.description}
                                    </Text>
                                </Box>

                                <StepSeparator />
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <Box>
                    <Box mx={{ base: 0, md: 10 }}>
                        <Stepper gap="0" colorScheme="secondary" size="md" index={activeStep}>
                            {steps.map((_step, index) => (
                                <Step key={index} onClick={() => setActiveStep(index + 1)}  style={{ cursor: 'pointer', gap: 0 }}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<Text color={'white'}>{index + 1}</Text>}
                                            active={<Text color={'white'}>{index + 1}</Text>}
                                        />
                                    </StepIndicator>
                                    <StepSeparator style={{ marginLeft: 0 }} />
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box height={'80vh'} p={{ base: 0, lg: 5}}>
                        <RiveComponent />
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    );
};
