import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import { ComponentProps, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { usePlatform } from '../../hooks/usePlatform';

type DesktopRecommendedDialogProps = Omit<Omit<ComponentProps<typeof AlertDialog>, 'leastDestructiveRef'>, 'children'> & {
    onContinue: () => void;
    isLoading?: boolean;
};

export const DesktopRecommendedDialog: React.FC<DesktopRecommendedDialogProps> = ({
    onContinue,
    onClose,
    isOpen,
    isLoading,
    ...restProps
}) => {
    const intl = useIntl();
    const cancelRef = useRef<HTMLButtonElement | null>(null);
    const platform = usePlatform();

    useEffect(() => {
        if (isOpen && platform === 'desktop') {
            onContinue();
        }
    }, [platform, isOpen, onContinue]);

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} {...restProps}>
                <AlertDialogOverlay>
                    <AlertDialogContent borderRadius={'2xl'}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {intl.formatMessage({ id: 'platform.desktopRecommended.title' })}
                        </AlertDialogHeader>

                        <AlertDialogBody>{intl.formatMessage({ id: 'platform.desktopRecommended.text' })}</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button isDisabled={isLoading} ref={cancelRef} onClick={onClose}>
                                {intl.formatMessage({ id: 'cancel' })}
                            </Button>
                            <Button isDisabled={isLoading} isLoading={isLoading} colorScheme="green" onClick={onContinue} ml={3}>
                                {intl.formatMessage({ id: 'continue' })}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
