import {
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useGetAttendanceStatementDetail } from '../../../../../query/resource-hooks/attendanceStatement';
import { StatementEditorTable } from './StatementEditorTable';
import { LocalAttendanceStatement } from '../../../../../types';
import { StatementSkeleton } from './StatementSkeleton';
import { MdFileDownload, MdFileUpload } from 'react-icons/md';
import { UploadedStatementTable } from '../../uploaded/components/UploadedStatementTable';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

type StatementDiffModalProps = {
    isOpen: boolean;
    onClose: VoidFunction;
    onUseLocalData: () => void;
    onUseRemoteData: () => void;
    localData: LocalAttendanceStatement;
    externalId: number;
};

export const StatementDiffModal: React.FC<StatementDiffModalProps> = ({
    isOpen,
    onClose,
    externalId,
    localData,
    onUseLocalData,
    onUseRemoteData,
}) => {
    const { t } = useSuIntl();
    const remoteData = useGetAttendanceStatementDetail(externalId, isOpen);

    function placeholderVoidFunction() {}
    return (
        <Modal onClose={onClose} isOpen={isOpen} scrollBehavior={'inside'} size={'full'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('editor.diff.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody bg={useColorModeValue('gray.300', 'gray.800')}>
                    <SimpleGrid minW={600} spacing={2} columns={2}>
                        <VStack>
                            <Heading size={'md'}>{t('editor.diff.uploadedVersion')}</Heading>
                            {remoteData.isLoading || !remoteData.data ? (
                                <StatementSkeleton w={'100%'} maxW={'900'} minW={'300'} />
                            ) : (
                                <UploadedStatementTable w={'100%'} maxW={'900'} minW={'300'} data={remoteData.data} />
                            )}
                        </VStack>
                        <VStack>
                            <Heading size={'md'}>{t('editor.diff.currentVersion')}</Heading>
                            <StatementEditorTable
                                data={localData!}
                                isEditing={false}
                                handleToggleItemSelect={placeholderVoidFunction}
                                isLoadingUpdate={false}
                                onCreateShift={placeholderVoidFunction}
                                onDeleteShift={placeholderVoidFunction}
                                onSelectShiftIds={placeholderVoidFunction}
                                onShiftUpdate={placeholderVoidFunction}
                                onSuspendedHoursUpdate={placeholderVoidFunction}
                                selectedShiftsIds={[]}
                                w={'100%'}
                                maxW={'900'}
                                minW={'300'}
                            />
                        </VStack>
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter justifyContent={'center'} gap={2} flexDir={{ base: 'column', md: 'row' }}>
                    <Button leftIcon={<MdFileDownload />} colorScheme={'green'} onClick={onUseRemoteData}>
                        {t('editor.diff.useUploaded')}
                    </Button>
                    <Button colorScheme={'red'} onClick={onClose}>
                        {t('editor.close')}
                    </Button>
                    <Button rightIcon={<MdFileUpload />} colorScheme={'green'} onClick={onUseLocalData}>
                        {t('editor.diff.useCurrent')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
