import React from 'react';
import { PairedShift, WorkDayReport } from '../../../types';
import { Box, VStack, Text, HStack, Divider, Card, Badge, useTheme, useColorModeValue, Tooltip, Collapse } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { FiMap } from 'react-icons/fi';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { TextValueField } from '../../common/TextValueField';
import { MdAccessAlarm, MdPause } from 'react-icons/md';

type ReportWorkDayCardProps = {
    workDay: WorkDayReport;
    onShiftClick?: (shift: PairedShift, index: number, workDay: WorkDayReport) => void;
    isSelected?: boolean; // Current day is selected
    selectedShiftIndex?: number; // Index of selected shift in day
};

export const ReportWorkDayCard: React.FC<ReportWorkDayCardProps> = ({
    workDay,
    onShiftClick,
    isSelected,
    selectedShiftIndex,
}) => {
    const intl = useIntl();
    const theme = useTheme();

    const selectedPrimary = useColorModeValue(theme.colors.primary[200], theme.colors.primary[700]);
    const selectedColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[700]);
    return (
        <HStack alignItems={'start'}>
            <Card backgroundColor={isSelected ? selectedPrimary : undefined} borderRadius="xl" boxShadow={'xl'}>
                <VStack textAlign="center" p="2" borderRadius="md">
                    <Text fontSize="xl" fontWeight="bold">
                        {intl.formatDate(workDay.date, { day: 'numeric' })}
                    </Text>
                    <Divider />
                    <Text fontSize="lg">{intl.formatDate(workDay.date, { month: 'short' })}.</Text>
                </VStack>
            </Card>

            <Card
                backgroundColor={isSelected ? selectedPrimary : undefined}
                borderRadius="xl"
                boxShadow={'xl'}
                minWidth={250}
            >
                <Box display={'flex'} justifyContent={'space-between'} m={1}>
                    <Badge textTransform={'capitalize'} borderRadius={'xl'}>
                        {intl.formatDate(workDay.date, { weekday: 'long' })}
                    </Badge>
                    <Tooltip label={intl.formatMessage({ id: 'attendanceReport.timeInDay' })}>
                        <Badge textTransform={'capitalize'} borderRadius={'xl'}>
                            {workDay.shifts.reduce((sum, currentShift) => {
                                return sum + (currentShift.hoursWorked || 0);
                            }, 0)}
                            h
                        </Badge>
                    </Tooltip>
                </Box>
                <VStack alignItems={'start'} p="4" borderColor="gray.200">
                    {workDay.shifts.map((shift, index) => (
                        <Card
                            backgroundColor={selectedShiftIndex === index && isSelected ? selectedColor : undefined}
                            key={JSON.stringify(shift)}
                            _hover={{ transform: 'scale(1.05)' }}
                            style={selectedShiftIndex === index && isSelected ? { transform: 'scale(1.05)' } : undefined}
                            transition="transform 0.2s"
                            cursor={'pointer'}
                            onClick={() => onShiftClick?.(shift, index, workDay)}
                            variant={'outline'}
                            width={'100%'}
                            p={2}
                            borderRadius={'xl'}
                            boxShadow={'sm'}
                        >
                            <HStack justifyContent={'space-between'}>
                                <ReportShiftCard
                                    shift={shift}
                                    showHoursInShift={workDay.shifts?.length > 1}
                                    isSelected={selectedShiftIndex === index && isSelected}
                                />

                                <ChevronRightIcon />
                            </HStack>
                        </Card>
                    ))}
                </VStack>
            </Card>
        </HStack>
    );
};

type ReportShiftCardProps = {
    shift: PairedShift;
    showHoursInShift: boolean;
    onShiftClick?: (shift: PairedShift) => void;
    isSelected?: boolean;
};

const ReportShiftCard: React.FC<ReportShiftCardProps> = ({ shift, showHoursInShift, isSelected }) => {
    const intl = useIntl();
    return (
        <VStack width={'100%'} alignItems={'start'} gap={0} display="flex">
            <TextValueField
                compactView
                label={'Pracovný čas'}
                icon={<MdAccessAlarm />}
                maxWidth="150px"
                value={`${shift.arrival ? intl.formatDate(shift.arrival.date, { hour: 'numeric', minute: 'numeric' }) : '-'} - ${shift.departure ? intl.formatDate(shift.departure.date, { hour: 'numeric', minute: 'numeric' }) : '-'}`}
            />
            <Collapse animateOpacity in={isSelected}>
                <TextValueField
                    smallVariant
                    label={intl.formatMessage({ id: 'zone' })}
                    icon={<FiMap />}
                    value={shift.zone?.name || '-'}
                    maxWidth="150px"
                />
                {!!shift.breakDuration && shift.breakDuration > 1 && (
                    <TextValueField
                        smallVariant
                        label={'Prestávky'}
                        icon={<MdPause />}
                        value={`${shift.breakDuration}h`}
                        maxWidth="150px"
                    />
                )}
                {showHoursInShift && (
                    <TextValueField
                        
                        label={intl.formatMessage({ id: 'attendanceReport.timeInShift' })}
                        icon={<MdAccessAlarm />}
                        value={`${shift.hoursInShift ?? 0}h`}
                        maxWidth="150px"
                    />
                )}
            </Collapse>
        </VStack>
    );
};
