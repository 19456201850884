import { AxiosError } from 'axios';
import { AppErrorCode } from '../../types/enums/AppErrorCode';
import { ServerErrorReason } from '../../types/enums/ServerErrorReason';

class ErrorHelper {
    public defaultIntlKey = 'somethingWentWrong';

    mapRequestErrorToIntlKey(error: any): string {
        if (error instanceof AxiosError) {
            const appErrorCode = this.mapRequestErrorToAppError(error);

            return this.mapAppErrorToIntlKey(appErrorCode);
        }

        return this.defaultIntlKey;
    }

    mapRequestErrorToAppError(error: AxiosError<any>): AppErrorCode {
        if (!error) {
            return AppErrorCode.SOMETHING_WENT_WRONG;
        }

        if (this.isUserNameTakenError(error)) {
            return AppErrorCode.USER_NAME_TAKEN;
        }

        const errorReason = error?.response?.data?.errors?.reason;

        switch (errorReason) {
            case ServerErrorReason.DUPLICATED_DOCUMENT_IDENTIFIER:
                return AppErrorCode.DUPLICATED_DOCUMENT_IDENTIFIER;
        }

        return AppErrorCode.SOMETHING_WENT_WRONG;
    }

    mapAppErrorToIntlKey(errorCode: AppErrorCode): string {
        switch (errorCode) {
            case AppErrorCode.USER_NAME_TAKEN:
                return 'userNameTaken';
            case AppErrorCode.DUPLICATED_DOCUMENT_IDENTIFIER:
                return 'error.documentIdentifierExists';
            case AppErrorCode.SOMETHING_WENT_WRONG:
            default:
                return 'somethingWentWrong';
        }
    }

    isUserNameTakenError(error: AxiosError<any>) {
        const errorPart = error?.response?.data?.errors?.[0];

        return errorPart && errorPart?.field === 'userName' && errorPart?.rule === 'unique';
    }
}

export default new ErrorHelper();
