import { HStack, Icon, Input, InputGroup, InputLeftAddon, PopoverTrigger, useColorModeValue, Text } from '@chakra-ui/react';
import moment from 'moment';
import { SingleDatepicker } from '../calendarPicker';
import { MdCalendarToday } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { ComponentProps } from 'react';

type DateTimeInputProps = Omit<Partial<ComponentProps<typeof SingleDatepicker>>,  'onChange'> & {
    value: Date | null;
    onChange: (date: Date | null) => void;
};

export const DateTimeInput: React.FC<DateTimeInputProps> = ({ value, onChange, ...pickerProps }) => {
    const borderColor = useColorModeValue('gray.300', 'gray.500');
    const backgroundColor = useColorModeValue('gray.200', 'gray.600');
    const intl = useIntl();

    function handleDateChange(date: Date | null) {
        if (date) {
            if (!value) {
                onChange(date);
                return;
            }

            const newDate = moment(value)
                .set({
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    date: date.getDate(),
                })
                .toDate();
            onChange(newDate);
        } else {
            onChange(null);
        }
    }

    function handleTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const time = event.target.value;

        const [hours, minutes] = time.split(':').map(Number);
        const newDate = moment(value).set({ hour: hours, minute: minutes }).toDate();
        onChange(newDate);
    }

    function getTimeFromDate() {
        if (!value) {
            return '';
        }

        return moment(value).format('HH:mm');
    }

    return (
        <HStack>
            <InputGroup fontWeight={'semibold'}>
                <SingleDatepicker  onDateChange={handleDateChange} usePortal date={value || undefined} {...pickerProps}>
                    {(date) => (
                        <PopoverTrigger>
                            <InputLeftAddon
                                cursor={'pointer'}
                                borderLeftRadius={'xl'}
                                bg={backgroundColor}
                                borderColor={borderColor}
                                borderWidth={1}
                                gap={2}
                            >
                                <Icon as={MdCalendarToday} boxSize={22} />
                                <Text fontSize={'md'} fontWeight={'500'}>
                                    {value ? intl.formatDate(date) : '-'}
                                </Text>
                            </InputLeftAddon>
                        </PopoverTrigger>
                    )}
                </SingleDatepicker>
                <Input
                    borderRightRadius={'xl'}
                    borderColor={borderColor}
                    borderLeftRadius={0}
                    bg={backgroundColor}
                    borderWidth={1}
                    type="time"
                    placeholder="čas"
                    fontWeight={'semibold'}
                    value={getTimeFromDate()}
                    onChange={handleTimeChange}
                />
            </InputGroup>
        </HStack>
    );
};
