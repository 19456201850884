import { AxiosError } from 'axios';
import { User } from '../../types';
import { Formik, Form } from 'formik';
import SchemaProvider from '../../utils/SchemaProvider';
import { useIntl } from 'react-intl';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    HStack,
    Switch,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { InferType } from 'yup';
import { useUpdateEmployee } from '../../query/resource-hooks/company';
import { ComponentProps, useRef } from 'react';
import ErrorHelper from '../../query/utils/ErrorHelper';
import ZoneSelectButton from '../ui/ZoneSelectButton';

export type TeamAccountSettingsFormProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    onSuccess?: (employee: User) => void;
    onError?: (error: AxiosError) => void;
    updatedUser: User;
};

export const TeamAccountSettingsForm: React.FC<TeamAccountSettingsFormProps> = ({ updatedUser, onSuccess, onError, isOpen, onClose }) => {
    const intl = useIntl();
    const updateEmployeeIdentity = useUpdateEmployee(handleSuccess, handleError);

    const toast = useToast();

    const firstField = useRef<HTMLInputElement | null>(null);

    const validationSchema = SchemaProvider.getTeamAccountUpdateSchema(intl);
    function handleSubmit(values: InferType<typeof validationSchema>) {
        updateEmployeeIdentity.mutate({
            data: {
                id: updatedUser.id,
                firstName: updatedUser.firstName,
                surname: updatedUser.surname,
                role: updatedUser.role,
                settings: {
                    defaultZoneId: values.defaultZoneId,
                    offlineAttendance: values.offlineAttendance,
                },
            },
        });
    }

    function handleSuccess(data: User) {
        toast({
            title: intl.formatMessage({ id: 'employeeUpdated' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onSuccess?.(data);
        handleClose();
    }

    function handleError(error: AxiosError) {
        const errorMessage = ErrorHelper.mapRequestErrorToIntlKey(error);

        toast({
            title: intl.formatMessage({ id: errorMessage }),
            status: 'error',
            duration: 8000,
            isClosable: true,
            position: 'top',
        });
        onError?.(error);
    }

    function handleClose() {
        updateEmployeeIdentity.reset();
        onClose();
    }

    return (
        <Drawer size={'md'} initialFocusRef={firstField} isOpen={isOpen} onClose={handleClose} placement="right">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">{intl.formatMessage({ id: 'contacts.update' })}</DrawerHeader>
                <DrawerBody>
                    <Formik
                        initialValues={{
                            offlineAttendance: updatedUser?.settings?.offlineAttendance ?? false,
                            defaultZoneId: updatedUser?.settings?.defaultZoneId ?? null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                            <Form>
                                {updatedUser && <Text fontSize={'sm'}>*{intl.formatMessage({ id: 'user.updateUserFormInfo' })}</Text>}
                                <VStack spacing={4}>
                                    <Box width={'100%'}>
                                        <FormLabel>{`${intl.formatMessage({ id: 'defaultZone' })} (${intl.formatMessage({ id: 'optional' }).toLocaleLowerCase()})`}</FormLabel>
                                        <ZoneSelectButton
                                            canDelete
                                            onDelete={() => setFieldValue('c', null)}
                                            onSelect={(zone) => {
                                                setFieldValue('defaultZoneId', zone.id);
                                            }}
                                            initialZone={updatedUser?.settings?.defaultZone}
                                        />
                                        {!!(errors.defaultZoneId && touched.defaultZoneId) ? (
                                            <Text color="red.500" fontSize="sm">
                                                {errors.defaultZoneId as string}
                                            </Text>
                                        ) : null}
                                    </Box>

                                    <FormControl justifyContent={'space-between'} display="flex" alignItems="center">
                                        <FormLabel htmlFor="email-alerts" mb="0">
                                            Povoliť dochádzku bez pripojenia na internet
                                        </FormLabel>
                                        <Switch isChecked={values.offlineAttendance} id="email-alerts" onChange={(e) => setFieldValue('offlineAttendance', e.target.checked)} />
                                    </FormControl>

                                    <HStack justifyContent={'center'} mt={2}>
                                        <Button
                                            type="submit"
                                            colorScheme="green"
                                            isLoading={updateEmployeeIdentity.isLoading}
                                            isDisabled={updateEmployeeIdentity.isLoading}
                                        >
                                            {intl.formatMessage({ id: updatedUser ? 'save' : 'create' })}
                                        </Button>
                                    </HStack>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
