import {
    Badge,
    Box,
    Button,
    Collapse,
    Heading,
    HStack,
    Icon,
    IconButton,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocalAttendanceStatementList } from '../../../../query/indexedDb/useLocalAttendanceStatementList';
import './Report.css';
import { useNavigate } from 'react-router-dom';
import { useGetAttendanceStatementsPagination } from '../../../../query/resource-hooks/attendanceStatement';
import { StatementTable } from './statementListComponents/StatementTable';
import { AttendanceStatementTableItem, UploadIcon } from './statementListComponents/StatementTableItem';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdFolder } from 'react-icons/md';
import { DocumentFilter } from '../../../../types/documents';
import { useSuToast } from '../../../../hooks/useSuToast';
import { useSuIntl } from '../../../../hooks/useSuIntl';
import { AttendanceStatementFilter } from '../AttendanceStatementFilter';
import { DocumentStatus } from '../../../../types/enums/DocumentStatus';

type AttendanceStatementsListProps = {
    filter?: DocumentFilter;
};

export const StoredAttendanceStatementsList: React.FC<AttendanceStatementsListProps> = ({ filter }) => {
    const navigate = useNavigate();
    const [localLimit, setLocalLimit] = useState(10);
    const [localDocumentsVisible, setLocalDocumentsVisible] = useState(true);
    const [uploadedDocumentsVisible, setUploadedDocumentsVisible] = useState(true);

    const [filteredStatus, setFilteredStatus] = useState<DocumentStatus | undefined>(undefined);

    const toast = useSuToast();
    const { t } = useSuIntl();

    const {
        data: localData,
        localCopiesMap,
        count: localDataCount,
        deleteReport,
        updateLocalStatus,
        updateCombinedStatus,
    } = useLocalAttendanceStatementList({ limit: localLimit, userId: filter?.userId, status: filter?.status || filteredStatus });

    const {
        data: remoteData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch: refetchRemote,
        isLoading: isLoadingRemoteData,
    } = useGetAttendanceStatementsPagination({ ...filter, status: filteredStatus });

    return (
        <Box>
            <Box>
                <AttendanceStatementFilter  onFilterStatusChange={setFilteredStatus} filteredStatus={filteredStatus} />
            </Box>
            <Stack direction={{ base: 'column', xl: 'row' }} spacing={1}>
                <Box width={{ base: '100%', xl: '50%' }}>
                    <HStack justify={'space-between'} mb={2}>
                        <HStack align={'center'}>
                            <UploadIcon status="notUploaded" />
                            <Heading size={'md'}> {t('doc.onThisComputer')}</Heading>
                            <Badge borderRadius={'md'} alignItems={'center'} display={'flex'}>
                                {localDataCount ?? 0}
                                <Icon ml={1} as={MdFolder} />
                            </Badge>
                        </HStack>
                        <IconButton
                            aria-label="hide/show local documents"
                            icon={
                                <ChevronDownIcon
                                    style={{
                                        transition: 'transform 0.3s ease',
                                        transform: localDocumentsVisible ? 'rotate(180deg)' : undefined,
                                    }}
                                    boxSize={7}
                                />
                            }
                            variant={'ghost'}
                            onClick={() => setLocalDocumentsVisible(!localDocumentsVisible)}
                        />
                    </HStack>
                    <Collapse in={localDocumentsVisible} animateOpacity>
                        <StatementTable
                            data={localData}
                            hasNextPage={localDataCount !== undefined && localLimit < localDataCount}
                            fetchNextPage={() => setLocalLimit(localLimit + 10)}
                            isFetchingNextPage={false}
                            isLoading={localData === undefined}
                            renderItem={(item) => (
                                <AttendanceStatementTableItem
                                    key={item.id}
                                    report={item}
                                    onChangeStatus={(status) =>
                                        toast.baseUpdate(async () => {
                                            await updateLocalStatus(item.id, status);
                                        })
                                    }
                                    onDelete={() => {
                                        toast.baseDelete(async () => {
                                            await deleteReport({ localId: item.id });
                                        });
                                    }}
                                    onClick={() => navigate(`/attendance/statement/loc/${item.id}`)}
                                    existsLocalAndUploadedVersion={false}
                                    localId={item.id}
                                />
                            )}
                        />
                    </Collapse>
                    {!localDocumentsVisible && (
                        <VStack justify={'center'}>
                            <Text fontWeight={'500'}>{t('doc.numberOfDocuments', { count: localDataCount ?? 0 })}</Text>
                            {(localDataCount ?? 0) && (
                                <Button variant={'link'} onClick={() => setLocalDocumentsVisible(true)}>
                                    {t('viewMore')}...
                                </Button>
                            )}
                        </VStack>
                    )}
                </Box>

                <Box
                    width={{ base: 'auto', xl: '2px' }}
                    mx={1}
                    bg={useColorModeValue('gray.200', 'gray.700')}
                    height={{ base: '2px', xl: 'auto' }}
                />

                <Box width={{ base: '100%', xl: '50%' }}>
                    <HStack justify={'space-between'} mb={2} mt={{ base: 4, md: 0 }}>
                        <HStack align={'center'}>
                            <UploadIcon status="uploaded" />

                            <Heading size={'md'}>{t('doc.uploaded')}</Heading>
                            <Badge borderRadius={'md'} alignItems={'center'} display={'flex'}>
                                {remoteData?.pages?.[0]?.meta?.total ?? 0}
                                <Icon ml={1} as={MdFolder} />
                            </Badge>
                        </HStack>
                        <IconButton
                            aria-label="hide/show local documents"
                            icon={
                                <ChevronDownIcon
                                    style={{
                                        transition: 'transform 0.3s ease',
                                        transform: uploadedDocumentsVisible ? 'rotate(180deg)' : undefined,
                                    }}
                                    boxSize={7}
                                />
                            }
                            variant={'ghost'}
                            onClick={() => setUploadedDocumentsVisible(!uploadedDocumentsVisible)}
                        />
                    </HStack>
                    <Collapse in={uploadedDocumentsVisible} animateOpacity>
                        <StatementTable
                            data={remoteData?.pages
                                ?.flatMap((page) => page.data)
                                ?.map((item) => ({
                                    createdAt: item.createdAt,
                                    id: item.id,
                                    name: item.data.data.name,
                                    dateFrom: item.data.data.dateFrom,
                                    dateTo: item.data.data.dateTo,
                                    totalHours: item.data.data.totalHours,
                                    status: item.status,
                                    lastChangeAt: item.updatedAt,
                                    lastUploadAt: item.updatedAt,
                                    suspendedHours: item.data.data.suspendedHours || 0,
                                    externalId: item.id,
                                }))}
                            isLoading={isLoadingRemoteData}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={!!hasNextPage}
                            isFetchingNextPage={isFetchingNextPage}
                            renderItem={(item) => {
                                const localMapItem = localCopiesMap?.find((localItem) => localItem.externalId === item.externalId);
                                return (
                                    <AttendanceStatementTableItem
                                        key={item.id}
                                        canArchive={item.status !== DocumentStatus.ARCHIVED}
                                        existsLocalAndUploadedVersion={!!localMapItem}
                                        localId={localMapItem?.id}
                                        report={item}
                                        onDelete={async () => {
                                            toast.baseDelete(async () => {
                                                await deleteReport({ localId: localMapItem?.id, externalId: item.externalId });
                                                await refetchRemote();
                                            });
                                        }}
                                        onChangeStatus={async (status) => {
                                            toast.baseUpdate(async () => {
                                                await updateCombinedStatus({
                                                    localId: localMapItem?.id,
                                                    externalId: item.externalId,
                                                    status,
                                                });
                                                await refetchRemote();
                                            });
                                        }}
                                        onClick={() =>
                                            localMapItem
                                                ? navigate(`/attendance/statement/loc/${localMapItem.id}`)
                                                : navigate(`/attendance/statement/upl/${item.externalId}`)
                                        }
                                    />
                                );
                            }}
                        />
                    </Collapse>
                    {!uploadedDocumentsVisible && (
                        <VStack justify={'center'}>
                            <Text fontWeight={'500'}>
                                {t('doc.numberOfDocuments', { count: remoteData?.pages?.[0]?.meta?.total ?? 0 })}
                            </Text>
                            {(remoteData?.pages?.[0]?.meta?.total ?? 0) && (
                                <Button variant={'link'} onClick={() => setUploadedDocumentsVisible(true)}>
                                    {t('viewMore')}...
                                </Button>
                            )}
                        </VStack>
                    )}
                </Box>
            </Stack>
        </Box>
    );
};
