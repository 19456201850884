import { Box, Divider, Heading, HStack, useTheme, Text, useBreakpointValue } from '@chakra-ui/react';

type SectionTitleProps = {
    title: string;
    subtitle?: string;
    dataAos?: string;
};
export const SectionTitle: React.FC<SectionTitleProps> = ({ title, dataAos, subtitle }) => {
    const theme = useTheme();

    const displayBaseNone = useBreakpointValue({ base: 'none', md: 'flex' });

    return (
        <HStack spacing={2} py={10} data-aos="zoom-in-up">
            <Divider borderColor={'gray.400'} display={displayBaseNone} />
            <Box data-aos="fade-up" width={'100%'} minWidth={{ base: undefined, md: 'fit-content' }}>
                <Heading color={theme.colors.primary[300]} textAlign={'center'}>
                    {title}
                </Heading>
                {subtitle && (
                    <Text size={'lg'} textAlign={'center'}>
                        {subtitle}
                    </Text>
                )}
            </Box>
            <Divider borderColor={'gray.400'} display={displayBaseNone} />
        </HStack>
    );
};
