import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

enum RootPageType {
    HOME = 'home',
    PROFILE = 'profile',
    COMPANY = 'company',
    ATTENDANCE = 'attendance',
    EMPLOYEES = 'employees',
    ZONES = 'zones',
}

type LocationMap = {
    location: string;
    link: string;
};

type BreadcrumbItemData = {
    labelKey: string;
    link: string;
};

export const BreadcrumbMapping: Record<RootPageType, { [key: string]: string }> = {
    [RootPageType.HOME]: {
        label: 'home.title',
    },
    [RootPageType.PROFILE]: {
        label: 'profile',
    },
    [RootPageType.COMPANY]: {
        label: 'company',
    },
    [RootPageType.ATTENDANCE]: {
        label: 'tab.Attendance',
        detail: 'tab.attendance.detail.title',
        history: 'title.history',
        statement: 'attendanceStatement.title.long.singular',
        loc: 'attendanceStatement.title.long.singular',
    },
    [RootPageType.EMPLOYEES]: {
        label: 'tab.Employees',
        detail: 'tab.employees.detail.title',
        attendance: 'tab.attendance.detail.title',
        history: 'title.history',
        devices: 'device.list.title',
        archive: 'archive.employees.title',
    },
    [RootPageType.ZONES]: {
        label: 'tab.Zones',
        detail: 'tab.zones.detail.title',
        attendance: 'tab.attendance.detail.title',
        history: 'title.history',
        archive: 'archive.zones.title',
    },
};

export const rootPages: string[] = Object.values(RootPageType);

const generateBreadcrumbItems = (location: string): BreadcrumbItemData[] => {
    const segments = location.split('/').filter(Boolean);
    const breadcrumbItems: LocationMap[] = [];
    const resourceMap = BreadcrumbMapping[segments?.[0] as RootPageType];

    if (!resourceMap) {
        return [];
    }

    segments.forEach((segment, index) => {
        if (index % 2 === 0) {
            const link = `/${segments.slice(0, index + 1).join('/')}`;
            breadcrumbItems.push({
                location: !Number.isNaN(+segment) || (!resourceMap[segment] && !rootPages.includes(segment)) ? segments[index - 1] : segment,
                link,
            });
        } else if (index === segments.length - 1) {
            const link = `/${segments.slice(0, index + 1).join('/')}`;

            breadcrumbItems.push({
                location: !Number.isNaN(+segment) ? segments[index - 1] : segment,
                link,
            });
        }
    });


    const uniqueBreadcrumbItems = breadcrumbItems.reduce<LocationMap[]>((acc, current) => {
        const existingIndex = acc.findIndex(item => item.location === current.location);
        if (existingIndex !== -1) {
            acc.splice(existingIndex, 1);
        }
        acc.push(current);
        return acc;
    }, []);

    
    if (uniqueBreadcrumbItems.length === 0 || !Object.values(RootPageType).includes(uniqueBreadcrumbItems[0].location as RootPageType)) {
        return [];
    }
  
    const partialResults: BreadcrumbItemData[] = [];

    for (let i = 0; i < uniqueBreadcrumbItems.length; i++) {
        if (i === 0) {
            partialResults.push({
                labelKey: resourceMap.label,
                link: uniqueBreadcrumbItems[i].link,
            });
        } else if (uniqueBreadcrumbItems[i].location in resourceMap) {
            partialResults.push({
                labelKey: resourceMap[uniqueBreadcrumbItems[i].location],
                link: uniqueBreadcrumbItems[i].link,
            });
        } else {
            return [];
        }
    }

    return partialResults;
};

/**
 * Displays a breadcrumb navigation based on the current location.
 */
export const Breadcrumbs: React.FC = () => {
    const location = useLocation();
    const intl = useIntl();
    const items = generateBreadcrumbItems(location.pathname);

    return (
        <Box>
            <Breadcrumb
                fontWeight={'600'}
                fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                separator={<ChevronRightIcon />}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
            >
                {items.map((item, index) => (
                    <BreadcrumbItem isCurrentPage={index === items.length - 1} key={index}>
                        <BreadcrumbLink as={Link} to={item.link} maxWidth={{ base: '100px', md: '200px', lg: '300px' }} isTruncated>
                            {intl.formatMessage({ id: item.labelKey })}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                ))}
            </Breadcrumb>
        </Box>
    );
};
