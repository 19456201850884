import { useState, useEffect } from 'react';

export const usePlatform = () => {
    const [platform, setPlatform] = useState<'android' | 'ios' | 'desktop'>('desktop');

    useEffect(() => {
        const userAgent = navigator.userAgent

        if (/android/i.test(userAgent)) {
            setPlatform('android');
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            setPlatform('ios');
        } else {
            setPlatform('desktop');
        }
    }, []);

    return platform;
};


export const useIsMobile = () => {
    const platform = usePlatform();

    return platform === 'android' || platform === 'ios';
}