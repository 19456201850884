import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { FaDesktop, FaMobileAlt } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

type StartTimeLineArticleProps = {
    text: string;
    title: string;
    buttonLinkTo?: string;
    onClick?: () => void;
    textColor?: string;
    mobile?: boolean;
    web?: boolean;
};

export const StartTimeLineArticle: React.FC<StartTimeLineArticleProps> = ({
    title,
    text,
    buttonLinkTo,
    textColor,
    mobile,
    web,
    onClick,
}) => {
    const intl = useIntl();
    return (
        <Box>
            <Heading size={'md'}>{title}</Heading>
            <HStack pt={1}>
                {mobile && (
                    <Tooltip label={intl.formatMessage({ id: 'about.accessible.mobile' })}>
                        <Box>
                            <Icon as={FaMobileAlt} />
                        </Box>
                    </Tooltip>
                )}
                {web && (
                    <Tooltip label={intl.formatMessage({ id: 'about.accessible.web' })}>
                        <Box>
                            <Icon as={FaDesktop} />
                        </Box>
                    </Tooltip>
                )}
            </HStack>
            <Text pt={1} color={textColor}>
                {text}
            </Text>
            <Button
                p={2}
                float={'right'}
                {...(buttonLinkTo && {
                    as: Link,
                    to: buttonLinkTo,
                })}
                {...(onClick && {
                    onClick,
                })}
                variant={'link'}
                colorScheme="primaryAbout"
                rightIcon={<ChevronRightIcon />}
            >
                {intl.formatMessage({ id: 'about.findOutMore' })}
            </Button>
        </Box>
    );
};
