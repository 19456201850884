import {
    InputGroup,
    InputLeftAddon,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useColorModeValue,
    Box,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';

type SuNumberInputProps = ComponentProps<typeof NumberInput> & {
    title: string;
};
export const SuNumberInput: React.FC<SuNumberInputProps> = ({ title, ...inputProps }) => {
    const borderColor = useColorModeValue('gray.300', 'gray.500');
    const backgroundColor = useColorModeValue('gray.200', 'gray.600');

    return (
        <InputGroup fontWeight={'semibold'}>
            <InputLeftAddon
                borderLeftRadius={'xl'}
                bg={backgroundColor}
                borderColor={borderColor}
                borderWidth={1}
                children={title}
                maxWidth="220px"
                whiteSpace="normal"
                minH={'fit-content'}
            />
            <NumberInput h={'auto'} allowMouseWheel step={5} defaultValue={30} min={0} max={1440} {...inputProps}>
                <Box h={'100%'}>

                <NumberInputField
                    fontWeight={'semibold'}
                    borderRightRadius={'xl'}
                    borderLeftRadius={0}
                    borderColor={borderColor}
                    bg={backgroundColor}
                    borderWidth={1}
                    h={'100%'}
                    textAlign={'center'}
                    />
                    </Box>
                <NumberInputStepper>
                    <NumberIncrementStepper borderColor={borderColor} />
                    <NumberDecrementStepper borderColor={borderColor} />
                </NumberInputStepper>
            </NumberInput>
        </InputGroup>
    );
};
