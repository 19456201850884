import { Badge } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentStatus } from '../../types/enums/DocumentStatus';

export const DocumentStatusBadge: React.FC<{ status: DocumentStatus }> = ({ status }) => {
    const intl = useIntl();

    return (
        <Badge colorScheme={status === DocumentStatus.DRAFT ? 'primary' : 'green'} borderRadius={'md'}>
            {intl.formatMessage({ id: `doc.status.${status}` })}
        </Badge>
    );
};
