import { Stack } from '@chakra-ui/react';
import { useLocalAttendanceStatement } from '../../../../query/combined/useLocalAttendanceStatement';
import { useState } from 'react';
import BulkActionBar from './statementEditorCopoments/BulkActionBar';
import { EditorSideMenu } from './statementEditorCopoments/EditorSideMenu';
import { StatementEditorTable } from './statementEditorCopoments/StatementEditorTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoredPairedShift } from '../../../../types';
import { useSuToast } from '../../../../hooks/useSuToast';
import { StatementEditorSkeleton } from './statementEditorCopoments/StatementSkeleton';

type LocalAttendanceReportEditorProps = {
    reportId: number;
};

export const StoredAttendanceReportEditor: React.FC<LocalAttendanceReportEditorProps> = ({ reportId }) => {
    const {
        data,
        updateShift,
        isUpdating,
        createNewShift,
        deleteStoredShift,
        updateSuspendedHours,
        isUploading,
        uploadNewAttendanceStatement,
        deleteAttendanceStatement,
        deleteLocalChanges,
        isDeleting,
        updateIdentifier,
        updateStatus,
    } = useLocalAttendanceStatement(reportId);

    const [selectedShiftsIds, setSelectedShiftsIds] = useState<string[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(location.state?.from === 'uploaded');
    const toast = useSuToast();

    const handleSelectItem = (shiftId: string) => {
        setSelectedShiftsIds((prevSelectedItems) => {
            const isSelected = prevSelectedItems.includes(shiftId);

            if (isSelected) {
                return prevSelectedItems.filter((storedShiftId) => !(storedShiftId === shiftId));
            } else {
                return [...prevSelectedItems, shiftId];
            }
        });
    };

    async function handleDeleteShift(shiftId: string, reportId?: string) {
        if (selectedShiftsIds.includes(shiftId)) {
            setSelectedShiftsIds(selectedShiftsIds.filter((id) => id !== shiftId));
        }

        await deleteStoredShift(shiftId, reportId);
    }

    async function handleDeleteStatement() {
        toast.baseDelete(async () => {
            await deleteAttendanceStatement();
            navigate('/attendance?tab=1', { replace: true });
        }, 300);
    }

    async function handleDeleteLocalChanges() {
        toast.baseUpdate(() => deleteLocalChanges(), 300);

        const externalId = data?.externalId;
        if (externalId) {
            navigate(`/attendance/statement/upl/${externalId}`, { replace: true });
        } else {
            navigate('/attendance?tab=1');
        }
    }

    async function handleUploadData() {
        toast.baseUpdate(() => uploadNewAttendanceStatement());
    }

    async function handleBulRound(updatedShifts: Promise<StoredPairedShift[]>) {
        const shifts = await updatedShifts;
        for (const shift of shifts) {
            await updateShift(shift);
        }
    }

    async function handleBulkDelete() {
        for (const shift of selectedShiftsIds) {
            await deleteStoredShift(shift);
            setSelectedShiftsIds([]);
        }
    }

    async function handleUpdateIdentifier(newIdentifier: string) {
        toast.baseUpdate(() => updateIdentifier(newIdentifier), 300);
    }

    if (!data) {
        return <StatementEditorSkeleton />;
    }

    return (
        <>
            <Stack px={2} direction={{ base: 'column', md: 'row' }} align={'start'} justify={'space-around'} maxW={1700} mx={'auto'}>
                <StatementEditorTable
                    data={data}
                    isEditing={isEditing}
                    handleToggleItemSelect={handleSelectItem}
                    isLoadingUpdate={isUpdating}
                    onCreateShift={createNewShift}
                    onDeleteShift={handleDeleteShift}
                    onSelectShiftIds={setSelectedShiftsIds}
                    onShiftUpdate={(shift: StoredPairedShift, reportId?: string) => {
                        toast.baseUpdate(() => updateShift(shift, reportId), 300);
                        updateShift(shift, reportId);
                    }}
                    onSuspendedHoursUpdate={updateSuspendedHours}
                    selectedShiftsIds={selectedShiftsIds}
                    onIdentifierUpdate={handleUpdateIdentifier}
                    onStatusUpdate={(newStatus) => toast.baseUpdate(() => updateStatus(newStatus), 300)}
                />

                <EditorSideMenu
                    data={data}
                    isEditing={isEditing}
                    onToggleEdit={() => setIsEditing(!isEditing)}
                    isUploading={isUploading}
                    onUpload={handleUploadData}
                    onDelete={handleDeleteStatement}
                    onDeleteLocalChanges={() => {
                        handleDeleteLocalChanges();
                    }}
                    isDeleting={isDeleting}
                />
            </Stack>
            <BulkActionBar
                onUnselectId={(id) => handleSelectItem(id)}
                rawData={data}
                selectedItems={selectedShiftsIds}
                onDelete={async () => toast.baseDelete(() => handleBulkDelete(), 300)}
                onRound={async (updatedShifts) => toast.baseUpdate(() => handleBulRound(updatedShifts), 300)}
                onDismiss={() => setSelectedShiftsIds([])}
            />
        </>
    );
};
