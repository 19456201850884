import moment from 'moment';
import { RoundType } from '../types';
import { roundNumber } from './formatUtils';

export const convertToUserTime = (isoDate: string): string => {
    return moment(isoDate).format('HH:mm');
};

export const roundToNearestHalfHour = (isoDate: string, mode: RoundType = RoundType.NORMAL): string => {
    const time = moment(isoDate);
    const minutesInt = time.minutes();

    time.seconds(0).milliseconds(0);

    if (minutesInt === 0) {
        return time.toISOString();
    }

    switch (mode) {
        case RoundType.NORMAL:
            if (minutesInt < 15) {
                time.minutes(0);
            } else if (minutesInt < 45) {
                time.minutes(30);
            } else {
                time.add(1, 'hour').minutes(0);
            }
            break;

        case RoundType.UP:
            if (minutesInt < 30) {
                time.minutes(30);
            } else {
                time.add(1, 'hour').minutes(0);
            }
            break;
        case RoundType.DOWN:
            if (minutesInt < 30) {
                time.minutes(0);
            } else {
                time.minutes(30);
            }
            break;
    }



    return time.toISOString();
  
};

export const getDuration = (start: string, end: string, unit: 'minutes' | 'hours' = 'minutes'): number => {
    const startDate = moment(start);
    const endDate = moment(end);

    let duration: number;
    if (unit === 'minutes') {
        duration = moment.duration(endDate.diff(startDate)).asMinutes();
    } else {
        duration = moment.duration(endDate.diff(startDate)).asHours();
    }

    return roundNumber(duration);
};
