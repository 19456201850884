import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, HStack, IconButton, Slide, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { BulkRoundTimeAction } from './EditActions';
import { LocalAttendanceStatement, StoredPairedShift } from '../../../../../types';
import { DeleteAlertDialog } from '../../../../ui/DeleteAlertDialog';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

interface BulkActionBarProps {
    selectedItems: any[];
    onDelete: () => void;
    rawData?: LocalAttendanceStatement;
    onRound: (updatedShifts: Promise<StoredPairedShift[]>) => void;
    onDismiss?: () => void;
    onUnselectId: (id: string) => void;
}

const BulkActionBar: React.FC<BulkActionBarProps> = ({ selectedItems, onDelete, rawData, onRound, onDismiss, onUnselectId }) => {
    const bulkRoundDisclosure = useDisclosure();
    const deleteDisclosure = useDisclosure();
    const { t } = useSuIntl();

    const filterShiftsForUpdate = (): StoredPairedShift[] => {
        return rawData?.reports.flatMap((report) => report.shifts.filter((shift) => selectedItems.includes(shift.id)))!;
    };
    const handleRound = (updatedShifts: Promise<StoredPairedShift[]>) => {
        onRound(updatedShifts);
        bulkRoundDisclosure.onClose();
    };

    return (
        <>
            <Slide direction="bottom" in={selectedItems.length > 0} style={{ zIndex: 10 }}>
                <Flex fontWeight={'600'} justify={'center'} marginX={'auto'} width={'fit-content'}>
                    <HStack
                        border={'1px solid'}
                        borderColor={useColorModeValue('gray.300', 'gray.500')}
                        boxShadow={'md'}
                        borderRadius={'xl'}
                        p={2}
                        bg={useColorModeValue('white', 'gray.700')}
                        my={4}
                        width={'fit-content'}
                    >
                        <Box p={2} border="1px dashed" borderColor={'gray.400'} borderRadius={'xl'}>
                            <Text>
                                {t('editor.selected')}: {selectedItems.length}
                            </Text>
                        </Box>
                        <Divider borderColor={'gray.300'} orientation="vertical" />
                        <Button colorScheme="gray" onClick={bulkRoundDisclosure.onOpen}>
                            {t('editor.roundTime')}
                        </Button>
                        <Button colorScheme="red" onClick={deleteDisclosure.onOpen}>
                            {t('delete')}
                        </Button>
                        <IconButton onClick={onDismiss} aria-label="close" variant={'ghost'} size={'sm'} icon={<CloseIcon></CloseIcon>} />
                    </HStack>
                </Flex>
            </Slide>

            <BulkRoundTimeAction
                onUnSelectId={onUnselectId}
                isOpen={bulkRoundDisclosure.isOpen}
                onClose={bulkRoundDisclosure.onClose}
                dataForUpdate={filterShiftsForUpdate()}
                onSubmit={handleRound}
            />

            <DeleteAlertDialog
                isOpen={deleteDisclosure.isOpen}
                onClose={deleteDisclosure.onClose}
                onConfirm={() => {
                    onDelete();
                    deleteDisclosure.onClose();
                }}
            />
        </>
    );
};

export default BulkActionBar;
