import { Box, Card, CardBody, Heading, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Role } from '../../../types';

const RoleCard: React.FC<{ role: Role }> = ({ role }) => {
    const intl = useIntl();
    return (
        <Card height={'100%'} borderRadius={'2xl'}>
            <Heading m={5} size="md">
                {intl.formatMessage({ id: role })}
            </Heading>

            <CardBody>
                <Text fontSize={'sm'}> {intl.formatMessage({ id: `intro.role.text.${role}` })}</Text>
            </CardBody>
        </Card>
    );
};

export const IntroRoles: React.FC = () => {
    const intl = useIntl();
    return (
        <Box pl={5}>
            <Heading>{intl.formatMessage({ id: 'intro.role.title' })}</Heading>
            <Text>{intl.formatMessage({ id: 'intro.role.intro' })}</Text>
            <Wrap mt={5} spacing={3}>
                <WrapItem maxW={300}>
                    <RoleCard role={Role.SUPER_OWNER} />
                </WrapItem>
                <WrapItem maxW={300}>
                    <RoleCard role={Role.OWNER} />
                </WrapItem>
                <WrapItem maxW={300}>
                    <RoleCard role={Role.ADMIN} />
                </WrapItem>
                <WrapItem maxW={300}>
                    <RoleCard role={Role.EMPLOYEE} />
                </WrapItem>
                <WrapItem maxW={300}>
                    <RoleCard role={Role.TEAM_ACCOUNT} />
                </WrapItem>
            </Wrap>
        </Box>
    );
};
