import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Card, HStack, IconButton, VStack, Text, Spinner, Flex, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { InfiniteData } from 'react-query';
import { BasePagination } from '../../types';
import './HorizontalList.css';
type HorizontalListProps<T> = {
    data?: InfiniteData<BasePagination<T>>;
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
    renderItem: (item: T, index: number) => JSX.Element;
    isLoading?: boolean;
};

export function HorizontalList<T>({ data, fetchNextPage, hasNextPage, isFetchingNextPage, renderItem, isLoading }: HorizontalListProps<T>) {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showScrollButtons, setShowScrollButtons] = useState(true);
    const items = data?.pages.flatMap((page) => page.data);
    const intl = useIntl();
    const sentinelRef = useRef<HTMLDivElement>(null);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            const { scrollLeft } = scrollContainerRef.current;
            const scrollAmount = direction === 'left' ? -200 : 200;
            scrollContainerRef.current.scrollTo({
                left: scrollLeft + scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const checkForOverflow = () => {
            if (scrollContainerRef.current) {
                const { scrollWidth, clientWidth } = scrollContainerRef.current;
                setShowScrollButtons(scrollWidth > clientWidth );
            }
        };

        checkForOverflow();
        window.addEventListener('resize', checkForOverflow);

        return () => {
            window.removeEventListener('resize', checkForOverflow);
        };
    }, [hasNextPage, items]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting && hasNextPage && !isFetchingNextPage && !isLoading) {
                    fetchNextPage();
                }
            },
            {
                root: scrollContainerRef.current,
                rootMargin: '0px',
                threshold: 1.0,
            }
        );

        const sentinelNode = sentinelRef.current;
        if (sentinelNode) {
            observer.observe(sentinelNode);
        }

        return () => {
            if (sentinelNode) {
                observer.unobserve(sentinelNode);
            }
        };
    }, [hasNextPage, isFetchingNextPage, fetchNextPage, isLoading]);

    return (
        <Box className="scroll-container" p={4} position="relative">
            {showScrollButtons && (
                <IconButton
                    className="scroll-button left"
                    icon={<ChevronLeftIcon  boxSize={7} />}
                    aria-label="Scroll left"
                    onClick={() => scroll('left')}
           
                    size={'sm'}
                />
            )}

            <HStack px={5} className="scroll-content" ref={scrollContainerRef} spacing={4} bg={useColorModeValue('gray.200', 'gray.800')} borderRadius={'xl'}>
                {items?.map((item, index) => renderItem(item, index))}

                {!items?.length && (
                    <Flex justify="center" align="center" w="100%">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <Card minW="200px" p={3} borderRadius="lg" boxShadow="md">
                                <VStack align="center" spacing={2}>
                                    <Text fontWeight="bold" isTruncated maxW="180px">
                                        {intl.formatMessage({ id: 'noData' })}
                                    </Text>
                                </VStack>
                            </Card>
                        )}
                    </Flex>
                )}

                <div ref={sentinelRef} style={{ minWidth: '1px' }}></div>
            </HStack>
            {showScrollButtons && (
                <IconButton
                    className="scroll-button right"
                    icon={<ChevronRightIcon  boxSize={7} />}
                    aria-label="Scroll right"
                    onClick={() => scroll('right')}
                    right={0}
                    isLoading={isFetchingNextPage}
                    size={'sm'}
                />
            )}
        </Box>
    );
}
