import { LocalAttendanceStatement, StoredPairedShift, StoredWorkDayReport, User } from '../types';
import { AttendanceStatement } from '../types/documents';
import { v4 as uuid } from 'uuid';
import { StatementStoredPairedShift, StatementWorkDayReport } from '../types/documents/DataTypes/AttendanceStatementData';
import { roundNumber } from './formatUtils';

export const transformUploadedStatementToStored = (
    uploadedStatement: AttendanceStatement,
    loggedUser: User
): Omit<LocalAttendanceStatement, 'id'> => {
    return {
        userId: uploadedStatement.userId!,
        status: uploadedStatement.status,
        suspendedHours: uploadedStatement.data.data.suspendedHours || 0,
        lastUploadAt: uploadedStatement.updatedAt,
        lastChangeAt: uploadedStatement.updatedAt,
        companyId: loggedUser.companyId!,
        createdAt: uploadedStatement.createdAt,
        dateFrom: uploadedStatement.data.data.dateFrom,
        dateTo: uploadedStatement.data.data.dateTo,
        externalId: uploadedStatement.id,
        name: uploadedStatement.data.data.name,
        totalHours: getTotalHoursFromUploadedStatement(uploadedStatement),
        identifier: uploadedStatement.identifier,
        reports: uploadedStatement.data.data.reports.map((report) => {
            return remapUploadedDayReportToStored(report);
        }),
    };
};

export const remapUploadedShiftToStored = (shift: StatementStoredPairedShift): StoredPairedShift => {
    return {
        arrival: shift.arrival || null,
        departure: shift.departure || null,
        hoursInShift: shift.hoursInShift,
        hoursWorked: shift.hoursWorked || undefined,
        breakDuration: shift.breakDuration || undefined,
        id: uuid(),
    };
};

export function remapUploadedDayReportToStored(report: StatementWorkDayReport): StoredWorkDayReport {
    return {
        date: report.date,
        id: uuid(),
        shifts: report.shifts.map((shift) => {
            return remapUploadedShiftToStored(shift);
        }),
    };
}

/**
 * hours in work - breaks - suspended hours
 */
export function getTotalHoursFromUploadedStatement(document: AttendanceStatement): number {
    return roundNumber(
        getHoursInWorkFromUploadedStatement(document) -
            getBreaksDurationForUploadedStatement(document) -
            (document?.data?.data.suspendedHours || 0)
    );
}

/**
 * sum of hoursInShift for all shifts in all reports
 */
export function getHoursInWorkFromUploadedStatement(document: AttendanceStatement): number {
    return roundNumber(
        document?.data?.data.reports
            .map((report) => report.shifts.map((shift) => shift.hoursInShift).reduce((acc, curr) => (acc || 0) + (curr || 0), 0))
            .reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0
    );
}

/**
 * sum of breakDuration for all shifts in all reports
 */
export function getBreaksDurationForUploadedStatement(document: AttendanceStatement): number {
    return roundNumber(
        document?.data?.data.reports
            .map((report) => report.shifts.map((shift) => shift.breakDuration).reduce((acc, curr) => (acc || 0) + (curr || 0), 0))
            .reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0
    );
}

/**
 * hours in work - breaks - suspended hours
 */
export function getTotalHoursFromStoredStatement(document: LocalAttendanceStatement): number {
    return roundNumber(
        getHoursInWorkFromStoredStatement(document) - getBreaksDurationStoredStatement(document) - (document.suspendedHours || 0)
    );
}

/**
 * sum of hoursInShift for all shifts in all reports
 */
export function getHoursInWorkFromStoredStatement(document: LocalAttendanceStatement): number {
    return roundNumber(
        document.reports
            .map((report) => report.shifts.map((shift) => shift.hoursInShift).reduce((acc, curr) => (acc || 0) + (curr || 0), 0))
            .reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0
    );
}

/**
 * sum of breakDuration for all shifts in all reports
 */
export function getBreaksDurationStoredStatement(document: LocalAttendanceStatement): number {
    return roundNumber(
        document.reports
            .map((report) => report.shifts.map((shift) => shift.breakDuration).reduce((acc, curr) => (acc || 0) + (curr || 0), 0))
            .reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0
    );
}

export function getDocumentUploadStatus({
    localId,
    externalId,
    lastChangeAt,
    lastUploadAt,
}: {
    localId?: number;
    externalId?: number;
    lastChangeAt?: string | null;
    lastUploadAt?: string | null;
}): 'uploaded' | 'changed' | 'notUploaded' {
    if (!externalId && localId) {
        return 'notUploaded';
    }

    if (externalId && !localId) {
        return 'uploaded';
    }

    if (externalId && localId) {
        return 'changed';
    }

    return 'changed';
}
