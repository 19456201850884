import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { InstallAppAction } from '../../../common/InstallAppAction';

type InstallAppModalProps = Omit<ComponentProps<typeof Modal>, 'children'>;

export const InstallAppModal: React.FC<InstallAppModalProps> = ({ isOpen, onClose }) => {
    const intl = useIntl();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius={'2xl'}>
                <ModalHeader>{intl.formatMessage({ id: 'about.timeline.downloadApp.title' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <InstallAppAction />
                </ModalBody>
                
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
};
