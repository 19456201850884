import {
    Avatar,
    Box,
    useTheme,
    ListItem,
    VStack,
    Text,
    HStack,
    Card,
    Center,
    useDisclosure,
    Button,
    IconButton,
    Tooltip,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react';
import { Role, User } from '../../types';
import { useIntl } from 'react-intl';
import { useGetEmployeesPagination } from '../../query/resource-hooks/company';
import InfiniteList from '../../components/lists/InfiniteList';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../components/ui/SearchInput';
import { useState } from 'react';
import { ChevronRightIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { EmployeeForm } from '../../components/employee/EmployeeForm';
import { FiRefreshCcw } from 'react-icons/fi';
import { useAuthStore } from '../../store/auth/authStore';
import UserPolicy from '../../policies/UserPolicy';
import { FaArchive } from 'react-icons/fa';
import EmployeesListSkeleton from './EmployeesListSkeleton';
import { UserRoleFilter } from '../../components/employee/UserRoleFIlter';

const EmployeeListItem: React.FC<{ user: User; onClick?: () => void }> = ({ user, onClick }) => {
    const intl = useIntl();
    const theme = useTheme();
    return (
        <>
            <ListItem m={1} role="group" cursor="pointer" borderRadius="lg" onClick={onClick}>
                <Card
                    p={2}
                    borderRadius={'lg'}
                    _hover={{
                        bg: useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]),
                        boxShadow: 'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    }}
                >
                    <HStack justifyContent="space-between" width="100%">
                        <HStack>
                            <Avatar boxSize={10} name={user.surname || user.firstName} />
                            <VStack align={'start'} spacing={0}>
                                <Text fontWeight={'600'}>{`${user?.surname || ''} ${user.firstName}`}</Text>
                                <Text fontSize={'small'}>{user.role ? intl.formatMessage({ id: user.role }) : '-'}</Text>
                            </VStack>
                        </HStack>
                        <ChevronRightIcon boxSize={5} mr={2} display={{ base: 'none', md: 'block' }} />
                    </HStack>
                </Card>
            </ListItem>
        </>
    );
};

const EmployeesList: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const [search, setSearch] = useState('');
    const newEmployeeDisclosure = useDisclosure();
    const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
    const employeesQuery = useGetEmployeesPagination({ search: search, roles: selectedRoles });

    return (
        <Box display="flex" justifyContent="center">
            <Box textAlign="center" maxW={1080} width={'100%'}>
                <Center>
                    <Card my={2} mx={{ base: 2, md: 0 }} width={'100%'} maxWidth={'lg'} borderRadius={'full'} boxShadow={'md'}>
                        <Box>
                            <SearchInput onSearch={setSearch} />
                        </Box>
                    </Card>
                </Center>

                <HStack justifyContent={'space-between'} mx={{ base: 2, md: 4 }} align={'center'}>
                    <Stack direction={{ base: 'row', md: 'row' }} align={'center'} flex={1} spacing={4}>
                        <Tooltip label={intl.formatMessage({ id: 'createUser' })}>
                            <Button
                                ml={2}
                                onClick={newEmployeeDisclosure.onOpen}
                                borderRadius={'full'}
                                leftIcon={<PlusSquareIcon />}
                                isDisabled={!UserPolicy.canCreateEmployee(loggedUser!)}
                                boxShadow={'md'}
                            >
                                {intl.formatMessage({ id: 'tab.Add' })}
                            </Button>
                        </Tooltip>

                        <UserRoleFilter
                            display={{ base: 'none', md: 'flex' }}
                            justify={'center'}
                            flex={1}
                            selectedRoles={selectedRoles}
                            setSelectedRoles={setSelectedRoles}
                        />
                    </Stack>

                    <Box>
                        <Tooltip label={intl.formatMessage({ id: 'archive.employees.title' })}>
                            <Button ml={2} onClick={() => navigate('archive')} variant={'ghost'} leftIcon={<FaArchive />}>
                                {intl.formatMessage({ id: 'doc.archive.title' })}
                            </Button>
                        </Tooltip>
                        <Tooltip label={intl.formatMessage({ id: 'refresh' })}>
                            <IconButton
                                icon={<FiRefreshCcw />}
                                aria-label="refresh"
                                m={2}
                                variant={'text'}
                                onClick={() => employeesQuery.refetch()}
                            />
                        </Tooltip>
                    </Box>
                </HStack>
                <UserRoleFilter
                    display={{ base: 'flex', md: 'none' }}
                    direction={'row'}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                    m={4}
                />

                <InfiniteList<User>
                    data={employeesQuery.data}
                    fetchNextPage={employeesQuery.fetchNextPage}
                    hasNextPage={employeesQuery.hasNextPage}
                    isFetchingNextPage={employeesQuery.isFetchingNextPage}
                    isLoading={employeesQuery.isLoading || employeesQuery.isRefetching}
                    renderItem={(user, index) => (
                        <EmployeeListItem
                            onClick={() => {
                                navigate(`detail/${user.id}`);
                            }}
                            key={index}
                            user={user}
                        />
                    )}
                    loadingComponent={<EmployeesListSkeleton />}
                />
            </Box>

            <EmployeeForm isOpen={newEmployeeDisclosure.isOpen} onClose={newEmployeeDisclosure.onClose} />
        </Box>
    );
};

export default EmployeesList;
