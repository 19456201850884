import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { User } from '../../../types';

export const IntroWelcomeSlide: React.FC<{ onSkip: VoidFunction; onNext: VoidFunction; authUser: User }> = ({
    onNext,
    onSkip,
    authUser,
}) => {
    const intl = useIntl();
    return (
        <VStack gap={0}>
            <Image width={'100px'} src={`${process.env.PUBLIC_URL}/logo_simple.png`} alt="Welcome" />
            <Text fontWeight={'bold'} fontSize={'larger'}>
                {intl.formatMessage({ id: 'intro.welcome.heading' })}
            </Text>
            <Text fontWeight={'600'}>
                {intl.formatMessage(
                    { id: 'intro.welcome.loggedAs' },
                    { name: authUser.fullName, role: intl.formatMessage({ id: authUser.role }) }
                )}
            </Text>
            <Text marginTop={10}>{intl.formatMessage({ id: 'intro.welcome.text' })}</Text>
            <HStack gap={10} marginTop={10}>
                <Button onClick={onSkip} leftIcon={<MdCheck />} variant={'outline'} colorScheme="green">
                    {intl.formatMessage({ id: 'intro.welcome.skip' })}
                </Button>
                <Button onClick={onNext} rightIcon={<ChevronRightIcon />}>
                    {intl.formatMessage({ id: 'intro.welcome.next' })}
                </Button>
            </HStack>
        </VStack>
    );
};
