import { Box, Stack } from '@chakra-ui/react';
import { User } from '../../../types';
import { useGetAttendanceReport } from '../../../query/resource-hooks/company';
import { UserAttendanceReportVisualizer } from './UserAttendanceReportVisualizer';
import { useAuthStore } from '../../../store/auth/authStore';
import { UserAttendanceReportFilter } from './UserAttendanceReportFilter';
import { useState } from 'react';
import { adjustDateToUserTimezone } from '../../calendarPicker';
import { AdditionalReportSettingsData } from './SimpleReportModal';
import moment from 'moment';
import { BreakCreatorData } from './BreakTimePeriod';
type UserAttendanceReportTabProps = {
    user: User;
    initialDates?: Date[];
    initialBreakCreatorData?: BreakCreatorData;
};

export const UserAttendanceReportTab: React.FC<UserAttendanceReportTabProps> = ({ user, initialBreakCreatorData, initialDates }) => {
    const loggedUser = useAuthStore((s) => s.user);

    const getInitDates = (): Date[] => {
        return initialDates || [moment().startOf('week').toDate(), moment().endOf('week').toDate()];
    };

    const [selectedDates, setSelectedDates] = useState<Date[]>(getInitDates());
    const [additionalSettings, setAdditionalSettings] = useState<AdditionalReportSettingsData | undefined>({
        breakCreatorData: initialBreakCreatorData || { breakDurationInMinutes: 60, maxShiftWithoutBreakInHours: 7 },
    });

    const { refetch, isLoading, isError, data } = useGetAttendanceReport(
        !!selectedDates[0] && !!selectedDates[1] && !!user
            ? {
                  dateFrom: adjustDateToUserTimezone(selectedDates[0]).toISOString(),
                  dateTo: new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString(),
                  userIds: [user.id],
                  breakDurationInMinutes: additionalSettings?.breakCreatorData.breakDurationInMinutes,
                  breaks: additionalSettings?.breakCreatorData.breaks,
                  maxShiftWithoutBreakInHours: additionalSettings?.breakCreatorData.maxShiftWithoutBreakInHours,
              }
            : undefined
    );

    if (!user || isError) {
        return null;
    }

    return (
        <Stack direction={{ 'base': 'column', 'xl': 'row' }} align={'start'} justify={'space-between'}>
            <Box width={{ 'base': '100%', 'xl': 'fit-content' }} order={{ 'base': 2, '2xl': 1 }} pt={4} flex={1}>
                <UserAttendanceReportVisualizer
                    onDataDelete={refetch}
                    onDataUpdate={refetch}
                    data={data}
                    isLoading={isLoading}
                    loggedUser={loggedUser!}
                    height={'fit-content'}
                    user={user}
                />
            </Box>
            <Box order={{ 'base': 1, 'xl': 2 }}>
                <UserAttendanceReportFilter
                    user={user}
                    position={'sticky'}
                    top={'22px'}
                    onFilterChange={(dates, additionalSettings) => {
                        setSelectedDates(dates);
                        setAdditionalSettings(additionalSettings);
                    }}
                    selectedDates={selectedDates}
                    data={data?.[0]}
                    initialBreakCreatorData={{ breakDurationInMinutes: 60, maxShiftWithoutBreakInHours: 7 }}
                />
            </Box>
        </Stack>
    );
};
