import React from 'react';
import { Text, VStack, Card, Icon } from '@chakra-ui/react';
import { Zone, ZonesFilter } from '../../types';
import { useNavigate } from 'react-router-dom';
import { HorizontalList } from '../lists/HorizontalList';
import { useGetZonesPagination } from '../../query/resource-hooks/company';
import { FiMap } from 'react-icons/fi';

interface ZonesHorizontalListProps {
    filter?: ZonesFilter;
}

export const ZonesHorizontalList: React.FC<ZonesHorizontalListProps> = ({ filter }) => {
    const terminals = useGetZonesPagination(filter ?? {});
    const navigate = useNavigate();

    return (
        <HorizontalList<Zone>
            fetchNextPage={() => {
                terminals.fetchNextPage();
            }}
            hasNextPage={terminals.hasNextPage}
            isFetchingNextPage={terminals.isFetchingNextPage}
            renderItem={(user, _index) => (
                <Card
                    _hover={{ transform: 'scale(1.05)' }}
                    cursor={'pointer'}
                    transition="transform 0.2s"
                    minW="200px"
                    p={3}
                    key={user.id}
                    borderRadius="lg"
                    boxShadow="md"
                    onClick={() => navigate(`/zones/detail/${user.id}`)}
                >
                    <VStack key={user.id} align="center" spacing={2}>   
                        <Icon 
                            as={FiMap}
                            fontSize={36}
                            color={'primary.500'}
                        />
                        <Text fontWeight="bold" isTruncated maxW="180px">
                            {user.name}
                        </Text>
                    </VStack>
                </Card>
            )}
            data={terminals.data}
            isLoading={terminals.isLoading}
        />
    );
};
