import React from 'react';
import { Avatar, Text, VStack, Card } from '@chakra-ui/react';
import { EmployeesFilter, User } from '../../types';
import { useNavigate } from 'react-router-dom';
import { HorizontalList } from '../lists/HorizontalList';
import { useGetEmployeesPagination } from '../../query/resource-hooks/company';

interface EmployeesHorizontalListProps {
    filter?: EmployeesFilter;
}

export const EmployeesHorizontalList: React.FC<EmployeesHorizontalListProps> = ({ filter }) => {
    const terminals = useGetEmployeesPagination(filter ?? {});
    const navigate = useNavigate();

    return (
        <HorizontalList<User>
            fetchNextPage={() => {
                terminals.fetchNextPage();
            }}
            hasNextPage={terminals.hasNextPage}
            isFetchingNextPage={terminals.isFetchingNextPage}
            renderItem={(user, _index) => (
                <Card
                    _hover={{ transform: 'scale(1.05)' }}
                    cursor={'pointer'}
                    transition="transform 0.2s"
                    minW="200px"
                    p={3}
                    key={user.id}
                    borderRadius="lg"
                    boxShadow="md"
                    onClick={() => navigate(`/employees/detail/${user.id}`)}
                >
                    <VStack key={user.id} align="center" spacing={2}>
                        <Avatar name={user.fullName} />
                        <Text fontWeight="bold" isTruncated maxW="150px">
                            {user.fullName}
                        </Text>
                    </VStack>
                </Card>
            )}
            data={terminals.data}
            isLoading={terminals.isLoading}
        />
    );
};
