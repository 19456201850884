import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../indexedDb/db';
import { useAuthStore } from '../../store/auth/authStore';
import { LocalAttendanceStatement, StoredPairedShift } from '../../types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { getDuration } from '../../utils/timeUtils';
import { useUpdateAttendanceStatement, useUploadAttendanceStatement } from '../resource-hooks/attendanceStatement';
import { AttendanceStatement, DocumentType } from '../../types/documents';
import {
    AttendanceStatementJsonData,
    StatementStoredPairedShift,
    StatementWorkDayReport,
} from '../../types/documents/DataTypes/AttendanceStatementData';
import { useNavigate } from 'react-router-dom';
import { useDeleteAttendanceStatementCombined } from './useDeleteAttendanceStatementCombined';
import { roundNumber } from '../../utils/formatUtils';
import { DocumentStatus } from '../../types/enums/DocumentStatus';

export const useLocalAttendanceStatement = (reportId: number) => {
    const loggedUser = useAuthStore((s) => s.user);
    const useUploadAttendance = useUploadAttendanceStatement();
    const useUpdateUploadedStatement = useUpdateAttendanceStatement();
    const useDeleteCombined = useDeleteAttendanceStatementCombined();

    const navigate = useNavigate();

    const [isUpdating, setIsUpdating] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const data = useLiveQuery(
        () => db.attendanceStatements.where(['companyId', 'id']).equals([loggedUser?.companyId!, reportId]).first(),
        [reportId, loggedUser?.companyId]
    );

    const updateShift = async (updatedShift: StoredPairedShift, dayReportId?: string) => {
        setIsUpdating(true);

        try {
            if (!data) {
                throw new Error('Attendance statement not found');
            }

            const reportIndex = findReportIndexForShift({ shiftId: updatedShift.id, reportId: dayReportId });

            if (reportIndex === -1) {
                throw new Error('Report not found');
            }

            const originalReport = data.reports[reportIndex];
            // Remove old shift from the report
            originalReport.shifts = originalReport.shifts.filter((shift) => shift.id !== updatedShift.id);

            await createNewShift(updatedShift);
        } catch (error) {
            console.error('Error updating shift', error);
            setIsUpdating(false);
            throw error;
        }

        setIsUpdating(false);
    };

    const createNewShift = async (newShift: StoredPairedShift) => {
        setIsUpdating(true);
        try {
            if (!data) {
                throw new Error('Attendance statement not found');
            }
            const date = moment(newShift.arrival!.date).startOf('day');
            const newHoursInShift = getDuration(newShift.arrival!.date, newShift.departure!.date, 'hours');

            newShift.hoursInShift = newHoursInShift < 0 ? 0 : newHoursInShift;
            newShift.hoursWorked = roundNumber(newHoursInShift - (newShift.breakDuration || 0));

            let resultDayReport = data.reports.find((report) => moment(report.date).isSame(date, 'day'));

            if (!resultDayReport) {
                resultDayReport = {
                    date: date.toISOString(),
                    shifts: [],
                    id: uuidv4(),
                };
            }

            resultDayReport.shifts.push(newShift);
            data.reports = [...data.reports.filter((report) => report.id !== resultDayReport!.id), resultDayReport].sort((a, b) =>
                a.date > b.date ? 1 : -1
            );
            data.totalHours = recalculateTotalHours(data);
            await updateStoredData(data.id, data);
        } catch (error) {
            console.error('Error creating new shift', error);
            setIsUpdating(false);
            throw error;
        }
        setIsUpdating(false);
    };

    const deleteStoredShift = async (shiftId: string, dayReportId?: string) => {
        setIsUpdating(true);
        try {
            if (!data) {
                throw new Error('Attendance statement not found');
            }

            const reportIndex = findReportIndexForShift({ shiftId, reportId: dayReportId });

            const report = data.reports[reportIndex];

            report.shifts = report.shifts.filter((shift) => shift.id !== shiftId);

            if (report.shifts.length === 0) {
                data.reports = data.reports.filter((report) => report.id !== dayReportId);
            }

            data.totalHours = recalculateTotalHours(data);
            await updateStoredData(data.id, data);
        } catch (error) {
            console.error('Error deleting shift', error);
            setIsUpdating(false);
            throw error;
        }
        setIsUpdating(false);
    };

    const updateSuspendedHours = async (suspendedHours: number) => {
        setIsUpdating(true);
        try {
            if (!data) {
                throw new Error('Attendance statement not found');
            }

            data.suspendedHours = suspendedHours;
            data.totalHours = recalculateTotalHours(data);
            await updateStoredData(data.id, data);
        } catch (error) {
            console.error('Error updating suspended hours', error);
            setIsUpdating(false);
            throw error;
        }
        setIsUpdating(false);
    };

    const updateIdentifier = async (newIdentifier: string) => {
        setIsUpdating(true);
        try {
            if (!data) {
                throw new Error('Attendance statement not found');
            }

            data.identifier = newIdentifier;
            await updateStoredData(data.id, data);
        } catch (error) {
            console.error('Error updating identifier', error);
            setIsUpdating(false);
            throw error;
        }
        setIsUpdating(false);
    }

    const updateStatus = async (newStatus: DocumentStatus) => {
        setIsUpdating(true);
        try {
            if (!data) {
                throw new Error('Attendance statement not found');
            }

            data.status = newStatus;
            await updateStoredData(data.id, data);
        } catch (error) {
            console.error('Error updating status', error);
            setIsUpdating(false);
            throw error;
        }
        setIsUpdating(false);
    }

    function recalculateTotalHours(data: LocalAttendanceStatement) {
        let totalHours = 0;

        data.reports.forEach((report) => {
            report.shifts.forEach((shift) => {
                if (shift.hoursWorked) {
                    totalHours += shift.hoursWorked!;
                }
            });
        });

        return totalHours - (data.suspendedHours || 0);
    }

    function findReportIndexForShift(filter: { shiftId?: string; reportId?: string }) {
        if (!data) {
            return -1;
        }

        return data.reports.findIndex((report) => {
            if (filter.reportId) {
                return report.id === filter.reportId;
            }

            return report.shifts.some((shift) => shift.id === filter.shiftId);
        });
    }

    async function uploadNewAttendanceStatement() {
        if (!data) {
            return;
        }

        try {
            setIsUploading(true);
            let result: AttendanceStatement
            if (data.externalId) {
                result = await useUpdateUploadedStatement.mutateAsync({
                    id: data.externalId,
                    data: {
                        data: remapLocalDataToUploadedStatementData(),
                        status: data.status,
                        identifier: data.identifier,
                    },
                });
            } else {
                result = await useUploadAttendance.mutateAsync({
                    createdAt: data.createdAt,
                    identifier: data.identifier,
                    status: data.status,
                    type: DocumentType.ATTENDANCE_STATEMENT,
                    userId: data.userId,
                    data: remapLocalDataToUploadedStatementData(),
                });
            }

            await db.attendanceStatements.where(['companyId', 'id']).equals([loggedUser?.companyId!, reportId]).delete();
            console.log('Deleted local attendance statement');

            setIsUploading(false);
            navigate(`/attendance/statement/upl/${result.id}`, { replace: true });
        } catch (error) {
            console.error('Error uploading attendance statement', error);
            setIsUploading(false);
            throw error;
        }
    }

    async function deleteLocalChanges() {
        if (!data) {
            return;
        }
        try {
            setIsUpdating(true);
            await useDeleteCombined.deleteLocalStatementOnly(data.id);
            setIsUpdating(false);
        } catch (error) {
            console.error('Error deleting local attendance statement', error);
            setIsUpdating(false);
            throw error;
        }
    }

    async function deleteAttendanceStatement() {
        if (!data) {
            return;
        }

        try {
            setIsDeleting(true);
            await useDeleteCombined.deleteCombinedStatement({
                localId: data.id,
                externalId: data.externalId!,
            })
        } catch (error) {
            console.error('Error deleting attendance statement', error);
            throw error;
        }
    } 

    async function updateStoredData(id: number, updatedData: LocalAttendanceStatement) {
        if (data?.reports) {
            // Remove empty reports to be sure
            data.reports = data?.reports?.filter((report) => report.shifts.length > 0);
        }

        updatedData.lastChangeAt = new Date().toISOString();

        await db.attendanceStatements.update(id, updatedData);
    }

    function remapLocalShiftToStored(shift: StoredPairedShift): StatementStoredPairedShift {
        return {
            arrival: shift.arrival,
            departure: shift.departure,
            hoursInShift: shift.hoursInShift ?? 0,
            hoursWorked: shift.hoursWorked,
            breakDuration: shift.breakDuration,
        };
    }

    function remapLocalDataToUploadedStatementData(): AttendanceStatementJsonData {
        if (!data) {
            throw new Error('No data to work with');
        }

        return {
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
            name: data.name,
            suspendedHours: data.suspendedHours,
            reports: data.reports.map((report) => {
                return {
                    date: report.date,
                    shifts: report.shifts.map((shift) => remapLocalShiftToStored(shift)),
                } as StatementWorkDayReport;
            }),
            totalHours: data.totalHours,
        };
    }

    return {
        data,
        createNewShift,
        updateShift,
        isUpdating,
        deleteStoredShift,
        updateSuspendedHours,
        uploadNewAttendanceStatement,
        isDeleting,
        deleteAttendanceStatement,
        deleteLocalChanges,
        isUploading,
        updateIdentifier,
        updateStatus,
    };
};
