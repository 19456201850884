import { PlusSquareIcon } from '@chakra-ui/icons';
import { HStack, VStack, Button, Box, Tooltip, useDisclosure, ResponsiveValue, Skeleton, Text, Icon } from '@chakra-ui/react';
import UserPolicy from '../../../policies/UserPolicy';
import { PairedShiftVisualizer } from './PairedShiftVisualizer';
import { ReportWorkDayCard } from './ReportWorkDayCard';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { AttendanceReport, PairedShift, User } from '../../../types';
import { UpdateAttendanceModal } from '../UpdateAttendanceModal';
import { MdInfoOutline } from 'react-icons/md';

type UserAttendanceReportVisualizerProps = {
    loggedUser: User;
    data?: AttendanceReport[];
    user?: User;
    isLoading: boolean;
    onDataUpdate?: () => void;
    onDataDelete?: () => void;
    height?: string | number | ResponsiveValue<number | string>;
};

export const UserAttendanceReportVisualizer: React.FC<UserAttendanceReportVisualizerProps> = ({
    loggedUser,
    data,
    user,
    isLoading,
    onDataDelete,
    onDataUpdate,
    height,
}) => {
    const intl = useIntl();
    const [selectedShift, setSelectedShift] = useState<PairedShift | null>(null);
    const [selectedShiftIndex, setSelectedShiftIndex] = useState<number | null>(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState<number | null>(null);
    const addAttendanceDisclosure = useDisclosure();

    function handleDataUpdate() {
        setSelectedShift(null);
        setSelectedShiftIndex(null);
        setSelectedDayIndex(null);

        onDataUpdate?.();
    }

    function handleDataDelete() {
        setSelectedShift(null);
        setSelectedShiftIndex(null);
        setSelectedDayIndex(null);

        onDataDelete?.();
    }

    useEffect(() => {
        setSelectedShift(null);
        setSelectedShiftIndex(null);
        setSelectedDayIndex(null);
    }, [data]);

    if (isLoading) {
        return (
            <HStack align={'start'}>
                <VStack>
                    <Skeleton borderRadius={'xl'} height={200} width={200} />
                    <Skeleton borderRadius={'xl'} height={200} width={200} />
                </VStack>
                <VStack>
                    <Skeleton borderRadius={'xl'} height={10} w={300} />
                    <Skeleton borderRadius={'xl'} height={10} w={300} />
                    <Skeleton borderRadius={'xl'} height={10} w={300} />
                </VStack>
            </HStack>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <>
            <HStack width={'100%'} alignItems={'start'} spacing={4} height={height}>
                <VStack pl={2} spacing={2} mx={4} flexShrink={0} maxHeight="100%" overflowY="auto" className="days_container" pb={4}>
                    <Tooltip label={intl.formatMessage({ id: 'attendance.manual.label' })}>
                        <Button
                            onClick={addAttendanceDisclosure.onOpen}
                            size={'sm'}
                            alignSelf={'end'}
                            className="days_content"
                            borderRadius={'full'}
                            leftIcon={<PlusSquareIcon />}
                            isDisabled={!UserPolicy.canCreateManualAttendance(loggedUser!)}
                            boxShadow={'md'}
                            py={4}
                        >
                            {intl.formatMessage({ id: 'tab.Add' })}
                        </Button>
                    </Tooltip>
                    {data?.[0]?.reports.map((report, dayIndex) => {
                        return (
                            <Box key={report.date} className="days_content">
                                <ReportWorkDayCard
                                    isSelected={selectedDayIndex === dayIndex}
                                    workDay={report}
                                    selectedShiftIndex={selectedDayIndex === dayIndex ? selectedShiftIndex! : undefined}
                                    onShiftClick={(shift, index, _workDay) => {
                                        setSelectedDayIndex(dayIndex);
                                        setSelectedShift(shift);
                                        setSelectedShiftIndex(index);
                                    }}
                                />
                            </Box>
                        );
                    })}
                </VStack>

                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'start'}
                    position="sticky"
                    maxHeight="100%"
                    overflowY="auto"
                    overflowX="auto"
                    top="22px"
                >
                    {selectedShift && (
                        <PairedShiftVisualizer onDataUpdate={handleDataUpdate} onDataDelete={handleDataDelete} shift={selectedShift} />
                    )}
                </Box>
            </HStack>
            {data?.[0]?.reports?.length === 0 && (
                <HStack width={'100%'} flex={1} justifyContent={'center'} alignItems={'center'}>
                    <VStack>
                        <Icon as={MdInfoOutline} color={'gray.500'} boxSize={8} />
                        <Text fontSize={'lg'} fontWeight={'600'}>
                            {intl.formatMessage({ id: 'editor.noDataForFilter' })}
                        </Text>
                    </VStack>
                </HStack>
            )}

            <UpdateAttendanceModal
                isOpen={addAttendanceDisclosure.isOpen}
                onClose={addAttendanceDisclosure.onClose}
                preselectedUser={user}
                onSuccess={handleDataUpdate}
                preselectedDate={selectedShift?.arrival?.date ?? selectedShift?.departure?.date ?? undefined}
            />
        </>
    );
};
