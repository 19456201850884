import { Badge, Button, Card, Center, HStack, Icon, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { LocalAttendanceStatement } from '../../../../../types';
import { MdCompareArrows, MdEdit, MdFileDownload, MdFileUpload, MdInfoOutline } from 'react-icons/md';
import { TextValueField } from '../../../../common/TextValueField';
import PopoverHelper from '../../../../common/PopoverHelper';
import { CheckIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { UploadIcon } from '../statementListComponents/StatementTableItem';
import { getDocumentUploadStatus } from '../../../../../utils/DocumentUtils';
import { StatementDiffModal } from './StatementDiffModal';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

type EditorSideMenuProps = {
    data: LocalAttendanceStatement;
    isEditing: boolean;
    onToggleEdit: () => void;
    onUpload: () => void;
    isUploading: boolean;
    onDelete: () => void;
    isDeleting: boolean;
    onDeleteLocalChanges: () => void;
};

export const EditorSideMenu: React.FC<EditorSideMenuProps> = ({
    data,
    isEditing,
    onToggleEdit,
    isUploading,
    onUpload,
    onDeleteLocalChanges,
    onDelete,
    isDeleting,
}) => {
    const { t, formatDate } = useSuIntl();

    const uploadStatus = getDocumentUploadStatus({ externalId: data.externalId, localId: data.id });
    const diffDisclosure = useDisclosure();

    return (
        <>
            <Card p={4} boxShadow={'md'} borderRadius={'xl'} minW={270}>
                <HStack justify={'space-between'} align={'center'} mb={4}>
                    <Text fontSize={'lg'} fontWeight={'500'}>
                        {t('editor.options')}
                    </Text>
                    <UploadIcon size={25} status={uploadStatus} />
                </HStack>
                <HStack mb={6} align={'center'} justify={'space-between'}>
                    <TextValueField smallVariant label={t('field.createdAt')} value={formatDate(data.createdAt)} icon={<EditIcon />} />
                    <TextValueField smallVariant label={t('field.updatedAt')} value={formatDate(data.lastChangeAt)} icon={<MdEdit />} />
                </HStack>
                {uploadStatus === 'changed' && (
                    <HStack justify={'center'} pb={4}>
                        <Badge borderRadius={'md'}>{t('doc.uploadStatus.changed.title')}</Badge>
                    </HStack>
                )}

                <VStack>
                    <Button
                        variant={isEditing ? 'outline' : 'solid'}
                        w={'100%'}
                        onClick={onToggleEdit}
                        leftIcon={isEditing ? <CheckIcon /> : <MdEdit />}
                        colorScheme={isEditing ? 'primary' : 'green'}
                    >
                        {t(isEditing ? 'editor.done' : 'edit')}
                    </Button>
                    <HStack justify={'space-between'} w={'100%'}>
                        <Button onClick={onUpload} isLoading={isUploading} w={'100%'} leftIcon={<MdFileUpload />} colorScheme="green">
                            {t(data.externalId ? 'doc.uploadChanges.title' : 'doc.upload.title')}
                        </Button>
                        <PopoverHelper
                            triggerComponent={
                                <Center>
                                    <Icon color={'gray.500'} as={MdInfoOutline} boxSize={'6'} mx={'auto'} my={'auto'} />
                                </Center>
                            }
                        >
                            <VStack p={4} align={'start'}>
                                <Text fontSize={'sm'}> {t(data.externalId ? 'doc.uploadChanges.text' : 'doc.upload.text')}</Text>
                            </VStack>
                        </PopoverHelper>
                    </HStack>
                    {uploadStatus === 'changed' && (
                        <>
                            <HStack justify={'space-between'} w={'100%'}>
                                <Button leftIcon={<MdCompareArrows />} onClick={diffDisclosure.onOpen} colorScheme="yellow" width={'100%'}>
                                    {t('doc.compareChanges')}
                                </Button>
                                <PopoverHelper
                                    triggerComponent={
                                        <Center>
                                            <Icon color={'gray.500'} as={MdInfoOutline} boxSize={'6'} mx={'auto'} my={'auto'} />
                                        </Center>
                                    }
                                >
                                    <VStack p={4} align={'start'}>
                                        <Text fontSize={'sm'}>{t('doc.compareChanges.text')}</Text>
                                    </VStack>
                                </PopoverHelper>
                            </HStack>
                            <Button leftIcon={<MdFileDownload />} onClick={onDeleteLocalChanges} colorScheme="yellow" width={'100%'}>
                                {t('doc.discardChanges')}
                            </Button>
                        </>
                    )}
                </VStack>

                <Button
                    mt={4}
                    onClick={onDelete}
                    isLoading={isDeleting}
                    variant={'ghost'}
                    w={'100%'}
                    leftIcon={<DeleteIcon />}
                    colorScheme="red"
                >
                    {t('delete')}
                </Button>
            </Card>
            {uploadStatus === 'changed' && !!data.externalId && (
                <StatementDiffModal
                    isOpen={diffDisclosure.isOpen}
                    onClose={diffDisclosure.onClose}
                    localData={data}
                    externalId={data.externalId!}
                    onUseLocalData={() => {
                        onUpload();
                        diffDisclosure.onClose();
                    }}
                    onUseRemoteData={() => {
                        onDeleteLocalChanges();
                        diffDisclosure.onClose();
                    }}
                />
            )}
        </>
    );
};
