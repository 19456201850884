import { Button, Card, HStack, Text, VStack } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { TextValueField } from '../../../../common/TextValueField';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { AttendanceStatement } from '../../../../../types/documents';
import { UploadIcon } from '../../local/statementListComponents/StatementTableItem';
import { DocumentStatus } from '../../../../../types/enums/DocumentStatus';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

type EditorSideMenuProps = {
    data: AttendanceStatement;
    onEdit: () => void;
    onDelete: () => void;
    isLoadingUpdate: boolean;
};

export const UploadedStatementSideMenu: React.FC<EditorSideMenuProps> = ({ data, onEdit, onDelete, isLoadingUpdate }) => {
    const { t, formatDate } = useSuIntl();
    return (
        <Card boxShadow={'md'} p={4} borderRadius={'xl'} minW={270}>
            <HStack justify={'space-between'} align={'center'} mb={4}>
                <Text fontSize={'lg'} fontWeight={'500'}>
                    {t('editor.options')}
                </Text>
                <UploadIcon size={25} status="uploaded" />
            </HStack>
            <HStack mb={6} align={'center'} justify={'space-between'}>
                <TextValueField smallVariant label={t('field.createdAt')} value={formatDate(data.createdAt)} icon={<EditIcon />} />
                <TextValueField smallVariant label={t('field.updatedAt')} value={formatDate(data.updatedAt)} icon={<MdEdit />} />
            </HStack>

            <VStack>
                <Button
                    isLoading={isLoadingUpdate}
                    variant={'solid'}
                    w={'100%'}
                    onClick={onEdit}
                    leftIcon={<MdEdit />}
                    colorScheme={'green'}
                    isDisabled={data?.status === DocumentStatus.ARCHIVED}
                >
                    {t('edit')}
                </Button>

                <Button isDisabled={isLoadingUpdate} onClick={onDelete} variant={'ghost'} w={'100%'} leftIcon={<DeleteIcon />} colorScheme="red">
                    {t('delete')}
                </Button>
            </VStack>
        </Card>
    );
};
