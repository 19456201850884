import { AxiosError } from 'axios';
import { User } from '../../types';
import { Formik, Form } from 'formik';
import SchemaProvider from '../../utils/SchemaProvider';
import { useIntl } from 'react-intl';
import FormikTextInput from '../formik/FormikTextInput';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { InferType } from 'yup';
import { useUpdateEmployeeIdentity } from '../../query/resource-hooks/company';
import { ComponentProps, useRef } from 'react';
import ErrorHelper from '../../query/utils/ErrorHelper';

export type EmployeeBusinessIdentityFormProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    onSuccess?: (employee: User) => void;
    onError?: (error: AxiosError) => void;
    updatedUser?: User;
};

export const EmployeeBusinessIdentityForm: React.FC<EmployeeBusinessIdentityFormProps> = ({
    updatedUser,
    onSuccess,
    onError,
    isOpen,
    onClose,
}) => {
    const intl = useIntl();
    const updateEmployeeIdentity = useUpdateEmployeeIdentity(handleSuccess, handleError);

    const toast = useToast();

    const firstField = useRef<HTMLInputElement | null>(null);

    const validationSchema = SchemaProvider.getEmployeeBusinessIdentitySchema(intl);
    function handleSubmit(values: InferType<typeof validationSchema>) {
        updateEmployeeIdentity.mutate({
            userId: updatedUser!.id,
            data: {
                name: updatedUser!.userName,
                phone: values.phone ?? null,
                email: values.email ?? null,
            }
        })
    }

    function handleSuccess(data: User) {
        toast({
            title: intl.formatMessage({ id: 'employeeUpdated' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onSuccess?.(data);
        handleClose();
    }

    function handleError(error: AxiosError) {
        const errorMessage = ErrorHelper.mapRequestErrorToIntlKey(error);

        toast({
            title: intl.formatMessage({ id: errorMessage }),
            status: 'error',
            duration: 8000,
            isClosable: true,
            position: 'top',
        });
        onError?.(error);
    }

    function handleClose() {
        updateEmployeeIdentity.reset();
        onClose();
    }

    return (
        <Drawer size={'md'} initialFocusRef={firstField} isOpen={isOpen} onClose={handleClose} placement="right">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                    {intl.formatMessage({ id: 'contacts.update' })}
                </DrawerHeader>
                <DrawerBody>
                    <Formik
                        initialValues={{
                            phone: updatedUser?.businessIdentity?.phone ?? '',
                            email: updatedUser?.businessIdentity?.email ?? '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleChange, handleBlur, values, errors, touched }) => (
                            <Form>
                                {updatedUser && <Text fontSize={'sm'}>*{intl.formatMessage({ id: 'user.updateUserFormInfo' })}</Text>}
                                <VStack>
                                    <FormikTextInput
                                        inputRef={firstField}
                                        type="tel"
                                        name="phone"
                                        fieldName="phone"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'contacts.phone' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />

                                    <FormikTextInput
                                        inputRef={firstField}
                                        type="email"
                                        name="email"
                                        fieldName="email"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'contacts.email' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />

                                    <HStack justifyContent={'center'} mt={2}>
                                        <Button
                                            type="submit"
                                            colorScheme="green"
                                            isLoading={updateEmployeeIdentity.isLoading}
                                            isDisabled={updateEmployeeIdentity.isLoading}
                                        >
                                            {intl.formatMessage({ id: updatedUser ? 'save' : 'create' })}
                                        </Button>
                                    </HStack>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
