// db.ts
import Dexie, { type EntityTable } from 'dexie';
import { LocalAttendanceStatement } from '../../types';

const db = new Dexie('AttendanceDb') as Dexie & {
    attendanceStatements: EntityTable<LocalAttendanceStatement, 'id'>;
};

// Schema declaration:
db.version(1).stores({
    attendanceStatements:
        '++id, [companyId+id], companyId, externalId, userId, name, dateFrom, dateTo, totalHours, status, lastUploadAt, lastChangeAt, createdAt, identifier', 
});

export { db };
