import { Box, Card, Divider, HStack, Skeleton, Stack, useColorModeValue } from '@chakra-ui/react';
import { ComponentProps } from 'react';
import React from 'react';

type StatementSkeletonProps = ComponentProps<typeof Box>;

export const StatementSkeleton: React.FC<StatementSkeletonProps> = (boxProps) => {
    const backgroundColor = useColorModeValue('white', 'gray.700');
    return (
        <Box
            boxShadow={'lg'}
            w={{ base: '100%', md: 400, lg: 700, xl: 960 }}
            p={4}
            backgroundColor={backgroundColor}
            borderRadius={'xl'}
            {...boxProps}
        >
            <HStack justify={'space-between'}>
                <Skeleton  borderRadius={'md'} height={8} width={'40%'} />
                <Skeleton  borderRadius={'md'} height={8} width={20} />
            </HStack>
            <Skeleton  borderRadius={'md'} mt={4} height={6} width={'50%'} />
            <Skeleton  borderRadius={'md'} mt={2} height={6} width={'50%'} />
            <Skeleton  borderRadius={'md'} mt={2} height={6} width={'50%'} />
            <Divider my={4} />
            <Skeleton mt={2} height={6} width={'100%'} />
            <Skeleton mt={2} height={6} width={'100%'} />
            <Skeleton mt={2} height={6} width={'100%'} />
            <Skeleton mt={2} height={6} width={'100%'} />
            <Divider my={4} />
            <Skeleton  borderRadius={'md'} mt={2} height={6} width={'70%'} />
            <Skeleton  borderRadius={'md'} mt={2} height={6} width={'70%'} />
            <Skeleton  borderRadius={'md'} mt={2} height={6} width={'70%'} />
            <HStack p={8} justify={'center'}>
                <Skeleton borderRadius={'xl'} height={14} width={'100%'} />
            </HStack>
        </Box>
    );
};


export const StatementEditorSkeleton: React.FC<StatementSkeletonProps> = () => {
    return (
        <Stack justify={'space-around'} maxW={1700} mx={'auto'} p={4} direction={{ base: 'column', md: 'row' }} spacing={4}>
            <StatementSkeleton />
            <Card height={'fit-content'} p={4} borderRadius={'xl'} minW={270}>
                <Skeleton height={6} width={'100%'} />
                <Skeleton mt={2} height={6} width={'100%'} />
                <Skeleton mt={10} height={6} width={'100%'} />
            </Card>
        </Stack>
    )
};