import React from 'react';
import SecondPageHeader from '../../../components/ui/SecondPageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingCard } from '../../../components/ui/LoadingCard';
import { StoredAttendanceReportEditor } from '../../../components/attendance/reports/local/StoredAttendanceStatementEditor';
import { useSuIntl } from '../../../hooks/useSuIntl';

export const StoredAttendanceStatementReportScreen: React.FC = () => {
    const { t } = useSuIntl();
    const params = useParams<{ statementId: string }>();
    const navigate = useNavigate();

    if (!params.statementId) {
        return (
            <>
                <SecondPageHeader onBack={() => navigate(-1)} title={t('attendanceStatement.title.long.singular')} />
                <LoadingCard />
            </>
        );
    }

    return (
        <>
            <SecondPageHeader onBack={() => navigate(-1)} title={t('attendanceStatement.title.long.singular')} />
            <StoredAttendanceReportEditor reportId={+params.statementId} />
        </>
    );
};
