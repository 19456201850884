import { useToast, UseToastOptions } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import ErrorHelper from '../query/utils/ErrorHelper';

export const useSuToast = () => {
    const toast = useToast();
    const intl = useIntl();

    function success(title: string, additionalProps?: Omit<UseToastOptions, 'title' | 'status'>) {
        return toast({
            title,
            status: 'success',
            position: 'bottom-right',
            isClosable: true,
            ...additionalProps,
        });
    }

    function error(title: string, additionalProps?: Omit<UseToastOptions, 'title' | 'status'>) {
        return toast({
            title,
            status: 'error',
            position: 'bottom-right',
            isClosable: true,
            ...additionalProps,
        });
    }

    function info(title: string, additionalProps?: Omit<UseToastOptions, 'title' | 'status'>) {
        return toast({
            title,
            status: 'info',
            position: 'bottom-right',
            isClosable: true,
            ...additionalProps,
        });
    }

    function loading(title: string, additionalProps?: Omit<UseToastOptions, 'title' | 'status'>) {
        return toast({
            title,
            status: 'loading',
            position: 'bottom-right',
            isClosable: false,
            ...additionalProps,
        });
    }

    /**
     *
     * @param handler callback function that returns a promise
     * @param delay deley in milliseconds to wait before executing the handler - use in case of local async calculation - smoothens the UI
     */
    function baseUpdatePromise(handler: () => Promise<any>, delay = 0): void {
        const handlerWithDelay = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(handler());
                }, delay);
            });
        };

        return toast.promise(handlerWithDelay(), {
            success: () => ({
                title: intl.formatMessage({ id: 'data.updated' }),
                status: 'success',
                position: 'bottom-right',
                isClosable: true,
            }),
            error: (error) => {
                return {
                    title: intl.formatMessage({ id: ErrorHelper.mapRequestErrorToIntlKey(error) }),
                    status: 'error',
                    position: 'bottom-right',
                    isClosable: true,
                };
            },
            loading: {
                title: intl.formatMessage({ id: 'editor.updateInProgress' }),
                colorScheme: 'primary',
                position: 'bottom-right',
            },
        });
    }

    /**
     *
     * @param handler callback function that returns a promise
     * @param delay deley in milliseconds to wait before executing the handler - use in case of local async calculation - smoothens the UI
     */
    function baseDeletePromise(handler: () => Promise<any>, delay = 0): void {
        const handlerWithDelay = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(handler());
                }, delay);
            });
        };

        return toast.promise(handlerWithDelay(), {
            success: () => ({
                title: intl.formatMessage({ id: 'data.deleted' }),
                status: 'success',
                position: 'bottom-right',
                isClosable: true,
            }),
            error: (error) => {
                return {
                    title: intl.formatMessage({ id: ErrorHelper.mapRequestErrorToIntlKey(error) }),
                    status: 'error',
                    position: 'bottom-right',
                    isClosable: true,
                };
            },
            loading: {
                title: intl.formatMessage({ id: 'editor.deleteInProgress' }),
                colorScheme: 'primary',
                position: 'bottom-right',

            },
        });
    }

    return {
        success,
        error,
        info,
        loading,
        close: toast.close,
        closeAll: toast.closeAll,
        baseUpdate: baseUpdatePromise,
        baseDelete: baseDeletePromise,
    };
};
