import { Box, Stack, Text, useColorModeValue, Image } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

export const References: React.FC = () => {
    const intl = useIntl();
    return (
        <Stack
            px={8}
            spacing={{ base: 8, md: 10 }}
            align={'center'}
            direction={'column'}
        >
            <Text textColor="gray.400" fontSize={{ base: 'xl' }} textAlign={'center'} maxW={'3xl'}>
                { intl.formatMessage({id: 'about.references.erigom'})}
            </Text>
            <Box textAlign={'center'}>
                <Image maxH={'150'} src={`${process.env.PUBLIC_URL}/images/references/erigom.png`} />

                <Text textColor="gray.400" fontWeight={600}>Erigom SK</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.400', 'gray.400')}>
                { intl.formatMessage({id: 'about.references.constructionCompany'})}
                </Text>
            </Box>
        </Stack>
    );
};
