import {
    Box,
    Button,
    Modal,
    useToast,
    VStack,
    Text,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerCloseButton,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { ComponentProps, useRef } from 'react';
import { AttendanceLogin, AttendanceLoginDetail, LoginDirection, User } from '../../types';
import { Form, Formik } from 'formik';
import SchemaProvider from '../../utils/SchemaProvider';
import { InferType } from 'yup';
import { useIntl } from 'react-intl';
import FormikTextInput from '../formik/FormikTextInput';
import { LoginDirectionButton } from '../ui/LogindirectionButton';
import { CheckIcon } from '@chakra-ui/icons';
import { useCreateManualAttendance, useUpdateAttendance } from '../../query/resource-hooks/company';
import { AxiosError } from 'axios';
import EmployeeSelectButton from '../ui/EmployeeSelectButton';
import ZoneSelectButton from '../ui/ZoneSelectButton';
import { DateTimeInput } from '../common/DateTimeInput';

type SimpleReportModalProps = Omit<ComponentProps<typeof Modal>, 'children'> & {
    updatedRecord?: AttendanceLoginDetail;
    onSuccess?: (login: AttendanceLogin) => void;
    onError?: (error: AxiosError) => void;
    preselectedUser?: User;
    preselectedDate?: string;
};

export const UpdateAttendanceModal: React.FC<SimpleReportModalProps> = ({
    isOpen,
    onClose,
    updatedRecord,
    onSuccess,
    onError,
    preselectedUser,
    preselectedDate,
}) => {
    const intl = useIntl();
    const toast = useToast();
    const updateAttendance = useUpdateAttendance(onQuerySuccess, onQueryError);
    const createAttendance = useCreateManualAttendance(onQuerySuccess, onQueryError);
    const drawerContentRef = useRef(null);
    const possibleSchemas = SchemaProvider.getManualAttendanceLogSchema(intl);
    const usedSchema = updatedRecord ? possibleSchemas.updateRecord : possibleSchemas.newRecord;

    function onQuerySuccess(login: AttendanceLogin) {
        toast({
            title: intl.formatMessage({ id: 'data.updated' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onSuccess?.(login);
        handleClose();
    }

    function onQueryError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onError?.(error);
        handleClose();
    }

    async function handleSubmit(values: InferType<typeof usedSchema>) {
        if (updatedRecord) {
            await updateAttendance.mutate({
                id: updatedRecord.id,
                data: {
                    createdAt: values.createdAt ? new Date(values.createdAt).toISOString() : undefined,
                    direction: values.direction !== updatedRecord.direction ? (values.direction as LoginDirection) : undefined,
                    zoneId: values.zoneId !== updatedRecord.zoneId ? values.zoneId : undefined,
                    updateNote: (values as InferType<typeof possibleSchemas.updateRecord>).updateNote,
                },
            });
        } else {
            const newRecordValues = values as InferType<typeof possibleSchemas.newRecord>;
            await createAttendance.mutate({
                userId: newRecordValues.userId,
                createdAt: new Date(newRecordValues.createdAt).toISOString(),
                direction: newRecordValues.direction as LoginDirection,
                zoneId: newRecordValues.zoneId,
                note: newRecordValues.note,
            });
            console.log('create');
        }
    }

    function handleClose() {
        updateAttendance.reset();
        createAttendance.reset();
        onClose();
    }

    return (
        <Drawer size={'md'} isOpen={isOpen} onClose={handleClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader borderBottomWidth="1px">
                    {updatedRecord ? intl.formatMessage({ id: 'edit' }) : intl.formatMessage({ id: 'attendance.manual.label' })}
                </DrawerHeader>
                <DrawerCloseButton />
                <DrawerBody ref={drawerContentRef}>
                    <Formik
                        validationSchema={usedSchema}
                        initialValues={{
                            userId: updatedRecord?.userId ?? preselectedUser?.id ?? undefined,
                            createdAt: updatedRecord?.createdAt || preselectedDate || new Date().toISOString(),
                            direction: updatedRecord?.direction ?? LoginDirection.IN,
                            zoneId: updatedRecord?.zoneId ?? undefined,
                            note: updatedRecord?.note,
                            updateNote: '',
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                            <Form>
                                <VStack spacing={{ base: 2 }}>
                                    <LoginDirectionButton
                                        loginDirection={values.direction}
                                        onChange={(loginDirection) => setFieldValue('direction', loginDirection)}
                                    />

                                    <FormControl>
                                        <FormLabel fontSize={'sm'}>{intl.formatMessage({ id: 'selectDate' })}</FormLabel>
                                        <DateTimeInput
                                            onChange={(date) => setFieldValue('createdAt', date)}
                                            value={new Date(values.createdAt)}
                                            portalRef={drawerContentRef}
                                        />
                                    </FormControl>
                                    {!updatedRecord && (
                                        <>
                                            <Box width={'100%'}>
                                                <EmployeeSelectButton
                                                    initialUser={preselectedUser}
                                                    onSelect={(user) => {
                                                        setFieldValue('userId', user.id);
                                                    }}
                                                />
                                                {!!(errors.userId && touched.userId) ? (
                                                    <Text color="red.500" fontSize="sm">
                                                        {errors.userId as string}
                                                    </Text>
                                                ) : null}
                                            </Box>
                                        </>
                                    )}
                                    <Box width={'100%'}>
                                        <ZoneSelectButton
                                            onSelect={(zone) => {
                                                setFieldValue('zoneId', zone.id);
                                            }}
                                            canDelete
                                            initialZone={updatedRecord?.zone}
                                            onDelete={() => {
                                                setFieldValue('zoneId', null);
                                            }}
                                        />
                                        {!!(errors.zoneId && touched.zoneId) ? (
                                            <Text color="red.500" fontSize="sm">
                                                {errors.zoneId as string}
                                            </Text>
                                        ) : null}
                                    </Box>

                                    <FormikTextInput
                                        errors={errors}
                                        touched={touched}
                                        name={updatedRecord ? 'updateNote' : 'note'}
                                        fieldName={updatedRecord ? 'updateNote' : 'note'}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'note' })}
                                        values={values}
                                    />

                                    <Button
                                        isLoading={updateAttendance.isLoading}
                                        colorScheme="green"
                                        rightIcon={<CheckIcon />}
                                        type="submit"
                                    >
                                        {intl.formatMessage({ id: 'form.submit' })}
                                    </Button>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
