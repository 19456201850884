import {
    Button,
    Card,
    CardBody,
    Collapse,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { ComponentProps, useState } from 'react';
import { User } from '../../../types';
import { adjustDateToUserTimezone, RangeDatepicker } from '../../calendarPicker';
import { useIntl } from 'react-intl';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { BreakCreator, BreakCreatorData } from './BreakTimePeriod';
import { useSearchParams } from 'react-router-dom';
import EmployeeSelectButton from '../../ui/EmployeeSelectButton';
import moment from 'moment';

export type AdditionalReportSettingsData = {
    breakCreatorData: BreakCreatorData;
};

type SimpleReportModalProps = Omit<ComponentProps<typeof Modal>, 'children'> & {
    user?: User;
    onSubmit?: (dateFrom: string, dateTo: string, userId: number, additionalSettings?: AdditionalReportSettingsData) => void;
};

export const SimpleAttendanceReportModal: React.FC<SimpleReportModalProps> = ({ isOpen, onClose, user, onSubmit }) => {
    const getInitDates = (): Date[] => {
        // Take dates from query params if they are present
        if (search.has('dateFrom') && search.has('dateTo')) {
            const currentData = decodeSimpleReportModalQueryToObject(search);
            return [new Date(currentData.dateFrom), new Date(currentData.dateTo)];
        }
        const start = moment().subtract(1, 'month').startOf('month').toDate();
        const end = moment().subtract(1, 'month').endOf('month').toDate();

        return [start, end];
    };

    function getInitialBreakData(): BreakCreatorData {
        if (search.has('dateFrom') && search.has('dateTo')) {
            const currentData = decodeSimpleReportModalQueryToObject(search);
            return currentData.additionalSettings?.breakCreatorData ?? {};
        }

        return {
            breakDurationInMinutes: 60,
            maxShiftWithoutBreakInHours: 7,
        };
    }
    const [selectedUser, setSelectedUser] = useState<User | undefined>(user);
    const intl = useIntl();
    const [search] = useSearchParams();
    const { isOpen: additionalSettingsVisible, onToggle: onToggleAdditionalSettings } = useDisclosure({
        defaultIsOpen: true,
    });
    const [breakCreatorData, setBreakCreatorData] = useState<BreakCreatorData>(getInitialBreakData());

    const [selectedDates, setSelectedDates] = useState<Date[]>(getInitDates());

    function handleSubmit() {
        if (selectedDates.length < 2 || !selectedUser) {
            return;
        }

        onSubmit?.(
            adjustDateToUserTimezone(selectedDates[0]).toISOString(),
            new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString(),
            selectedUser?.id!,
            {
                breakCreatorData,
            }
        );
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
            <ModalOverlay />
            <ModalContent borderRadius={'2xl'}>
                <ModalHeader>
                    {intl.formatMessage(
                        { id: user ? 'attendanceReport.forUser' : 'attendanceReport.title' },
                        { name: selectedUser?.fullName ?? '' }
                    )}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack>
                        <FormLabel> {intl.formatMessage({ id: 'period' })}</FormLabel>
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={(dates) => {
                                setSelectedDates(dates);
                            }}
                        />
                        {!user && isOpen && (
                            <EmployeeSelectButton
                                onSelect={(user) => {
                                    setSelectedUser(user);
                                }}
                            />
                        )}
                        <Button
                            variant={'ghost'}
                            marginTop={4}
                            borderRadius={'xl'}
                            rightIcon={
                                <ChevronDownIcon
                                    style={{
                                        transition: 'transform 0.3s ease',
                                        transform: additionalSettingsVisible ? 'rotate(180deg)' : undefined,
                                    }}
                                    boxSize={7}
                                />
                            }
                            onClick={onToggleAdditionalSettings}
                        >
                            {intl.formatMessage({ id: 'editor.additionalSettings' })}
                        </Button>
                        <Collapse in={additionalSettingsVisible} animateOpacity>
                            <Card variant={'filled'} borderRadius={'xl'} backgroundColor={useColorModeValue('gray.100', 'gray.800')}>
                                <CardBody>
                                    <BreakCreator
                                        onChange={(data) => {
                                            setBreakCreatorData(data);
                                        }}
                                        initialData={getInitialBreakData()}
                                    />
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!selectedUser} colorScheme="green" onClick={handleSubmit}>
                        {intl.formatMessage({ id: 'form.next' })}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export function simpleReportModalDataToQueryString(
    dateFrom: string,
    dateTo: string,
    userId: number,
    additionalSettings?: AdditionalReportSettingsData
): string {
    const params = new URLSearchParams();
    if (additionalSettings?.breakCreatorData) {
        if (additionalSettings.breakCreatorData.breaks?.length) {
            additionalSettings.breakCreatorData.breaks.forEach((item, index) => {
                params.append(`breaks[${index}][start]`, item.start);
                params.append(`breaks[${index}][end]`, item.end);
            });
        }

        if (additionalSettings.breakCreatorData.breakDurationInMinutes) {
            params.append('breakDurationInMinutes', additionalSettings.breakCreatorData.breakDurationInMinutes.toString());
            if (additionalSettings.breakCreatorData.maxShiftWithoutBreakInHours) {
                params.append('maxShiftWithoutBreakInHours', additionalSettings.breakCreatorData.maxShiftWithoutBreakInHours.toString());
            }
        }
    }

    params.append('dateFrom', dateFrom);
    params.append('dateTo', dateTo);
    params.append('userId', userId.toString());
    return params.toString();
}

export function decodeSimpleReportModalQueryToObject(searchParams: URLSearchParams): {
    dateFrom: string;
    dateTo: string;
    additionalSettings?: AdditionalReportSettingsData;
    userId: number;
} {
    const result: {
        dateFrom: string;
        dateTo: string;
        additionalSettings?: AdditionalReportSettingsData;
        userId: number;
    } = {
        dateFrom: searchParams.get('dateFrom')!,
        dateTo: searchParams.get('dateTo')!,
        additionalSettings: {
            breakCreatorData: {},
        },
        userId: parseInt(searchParams.get('userId')!),
    };

    const breaks: { start: string; end: string }[] = [];
    let index = 0;

    while (searchParams.has(`breaks[${index}][start]`) && searchParams.has(`breaks[${index}][end]`)) {
        const start = searchParams.get(`breaks[${index}][start]`);
        const end = searchParams.get(`breaks[${index}][end]`);
        if (start && end) {
            breaks.push({ start, end });
        }
        index++;
    }

    if (breaks.length) {
        result.additionalSettings!.breakCreatorData = { breaks };
    } else if (searchParams.has('breakDurationInMinutes')) {
        result.additionalSettings!.breakCreatorData = { breakDurationInMinutes: parseInt(searchParams.get('breakDurationInMinutes')!) };
        if (searchParams.has('maxShiftWithoutBreakInHours')) {
            result.additionalSettings!.breakCreatorData.maxShiftWithoutBreakInHours = parseInt(
                searchParams.get('maxShiftWithoutBreakInHours')!
            );
        }
    }

    return result;
}
