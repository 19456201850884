import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/react';

interface PopoverHelperProps {
    triggerComponent: React.ReactNode;
    children: React.ReactNode;
}

const PopoverHelper: React.FC<PopoverHelperProps> = ({ triggerComponent, children }) => {
    return (
        <Popover trigger="hover">
            <PopoverTrigger>{triggerComponent}</PopoverTrigger>
            <PopoverContent borderRadius={'xl'} boxShadow={'md'}>{children}</PopoverContent>
        </Popover>
    );
};

export default PopoverHelper;
