import { Box, Card, CardBody, SimpleGrid, Step, StepIndicator, Stepper, StepSeparator, StepStatus, StepTitle } from '@chakra-ui/react';
import { useGetAttendanceById } from '../../../query/resource-hooks/company';
import { AttendanceLoginDetail, PairedShift } from '../../../types';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { LoadingCard } from '../../ui/LoadingCard';
import { TextValueField } from '../../common/TextValueField';
import { MdAlarm, MdCalendarToday, MdPause, MdTimelapse } from 'react-icons/md';
import { PairedShiftRecordCard } from './PairedShiftRecordCard';
import { ShiftsBreakVisualizer } from './BreakVisualizer';

type PairedShiftVisualizerProps = {
    shift: PairedShift;
    onDataUpdate?: () => void;
    onDataDelete?: () => void;
};

export const PairedShiftVisualizer: React.FC<PairedShiftVisualizerProps> = ({ shift, onDataUpdate, onDataDelete }) => {
    const intl = useIntl();
    const arrivalQuery = useGetAttendanceById(shift.arrival?.id);
    const departureQuery = useGetAttendanceById(shift.departure?.id);

    if (arrivalQuery.isLoading || departureQuery.isLoading) {
        return <LoadingCard />;
    }

    return (
        <Box pr={2} width={'100%'} justifyContent={'center'}>
            <Card m={3} borderRadius={'2xl'} boxShadow={'md'} maxW={700}>
                <CardBody>
                    <SimpleGrid columns={2}>
                        <Box>
                            <TextValueField
                                smallVariant
                                label={intl.formatMessage({ id: 'field.date' })}
                                icon={<MdCalendarToday />}
                                value={intl.formatDate(shift.arrival?.date || shift.departure?.date)}
                            />

                            <TextValueField
                                smallVariant
                                wrapperStyle={{ marginTop: 4 }}
                                label={intl.formatMessage({ id: 'attendance.workTime' })}
                                icon={<MdAlarm />}
                                value={`${shift.arrival ? intl.formatDate(shift.arrival.date, { hour: 'numeric', minute: 'numeric' }) : '-'} - ${shift.departure ? intl.formatDate(shift.departure.date, { hour: 'numeric', minute: 'numeric' }) : '-'}`}
                            />
                        </Box>
                        <Box>
                            <TextValueField
                                label={intl.formatMessage({ id: 'attendanceReport.timeInShift' })}
                                value={`${shift.hoursWorked ?? 0} h`}
                                smallVariant
                                icon={<MdTimelapse />}
                            />
                            <TextValueField
                                label={intl.formatMessage({ id: 'attendance.breakDuration' })}
                                value={`${shift.breakDuration ?? 0} h`}
                                smallVariant
                                icon={<MdPause />}
                            />
                        </Box>
                    </SimpleGrid>
                </CardBody>
            </Card>
            <Stepper index={10} orientation="vertical" gap="0" width={'100%'}>
                <Step key={'STEP_BASE_INFO'} style={{ width: '100%', maxWidth: 700 }}>
                    <StepIndicator>
                        <StepStatus complete={<MdCalendarToday />} incomplete={<MdCalendarToday />} active={<FiLogIn />} />
                    </StepIndicator>
                    <Box pb={4} width={'100%'}>
                        <TextValueField label="Dátum" value={intl.formatDate(shift.arrival?.date || shift.departure?.date)} compactView />
                    </Box>

                    <StepSeparator />
                </Step>

                <Step key={'ARRIVAL'} style={{ width: '100%', maxWidth: 700 }}>
                    <StepIndicator>
                        <StepStatus complete={<FiLogIn />} incomplete={<FiLogIn />} active={<FiLogIn />} />
                    </StepIndicator>
                    <Box pb={4} width={'100%'}>
                        {arrivalQuery.data ? (
                            <PairedShiftRecordCard
                                attendance={arrivalQuery.data as AttendanceLoginDetail}
                                onUpdateSuccess={onDataUpdate}
                                onDeleteSuccess={onDataDelete}
                            />
                        ) : (
                            <Box mb={10}>
                                <StepTitle>{intl.formatMessage({ id: 'attendance.unknownArrival' })}</StepTitle>
                            </Box>
                        )}
                    </Box>

                    <StepSeparator />
                </Step>

                <ShiftsBreakVisualizer key={'BREAKS'} data={shift} />

                <Step key={'DEPARTURE'} style={{ width: '100%', maxWidth: 700 }}>
                    <StepIndicator>
                        <StepStatus complete={<FiLogOut />} incomplete={<FiLogOut />} active={<FiLogOut />} />
                    </StepIndicator>
                    <Box pb={4} width={'100%'}>
                        {departureQuery.data ? (
                            <PairedShiftRecordCard
                                attendance={departureQuery.data as AttendanceLoginDetail}
                                onUpdateSuccess={onDataUpdate}
                                onDeleteSuccess={onDataDelete}
                            />
                        ) : (
                            <Box>
                               <StepTitle>{intl.formatMessage({ id: 'attendance.unknownDeparture' })}</StepTitle>
                            </Box>
                        )}
                    </Box>

                    <StepSeparator />
                </Step>
            </Stepper>
        </Box>
    );
};
