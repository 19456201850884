import { Box, Card, Flex, Heading, HStack, SimpleGrid, Stat, StatHelpText, StatNumber } from '@chakra-ui/react';
import { useGetAttendancePagination, useGetCompanyDetail, useGetCompanyStatistics } from '../../query/resource-hooks/company';
import CompanyCard from '../../components/company/CompanyCard';
import AttendanceChart from '../../components/attendance/AttendanceChart';
import { useIntl } from 'react-intl';
import AttendanceTable from '../attendance/AttendanceTable';
import { AttendanceTableItem } from '../../components/lists/AttendanceTableItem';
import { Link, useNavigate } from 'react-router-dom';
import { Role } from '../../types';
import { EmployeesHorizontalList } from '../../components/employee/EmployeesHorizontalList';
import { FilterChip } from '../../components/attendance/AttendanceListFilter';
import { useState } from 'react';
import { SimpleSubscriptionCard } from '../../components/company/SimpleSubscriptionCard';
import { ZonesHorizontalList } from '../../components/zones/ZonesHorizontalList';
enum StatisticsPeriod {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    ALL = 'all',
}

const HomeScreen: React.FC = () => {
    const intl = useIntl();

    const [selectedPeriod, setSelectedPeriod] = useState<StatisticsPeriod>(StatisticsPeriod.WEEK);
    const [statisticsPeriod, setStatisticsPeriod] = useState<{ dateFrom?: Date; dateTo?: Date }>(getPeriod(StatisticsPeriod.WEEK));

    const company = useGetCompanyDetail();
    const statistics = useGetCompanyStatistics(
        statisticsPeriod.dateFrom && statisticsPeriod.dateTo
            ? { dateFrom: statisticsPeriod.dateFrom?.toISOString(), dateTo: statisticsPeriod?.dateTo?.toISOString() }
            : {}
    );
    const attendanceQuery = useGetAttendancePagination({ perPage: 5 });
    const navigate = useNavigate();

    function getPeriod(period: StatisticsPeriod): { dateFrom?: Date; dateTo?: Date } {
        let dateFrom = new Date();
        let dateTo = new Date();
        switch (period) {
            case StatisticsPeriod.DAY:
                dateFrom.setHours(0, 0, 0, 0);
                dateTo.setHours(23, 59, 59, 999);
                break;
            case StatisticsPeriod.WEEK:
                dateFrom.setHours(0, 0, 0, 0);
                dateFrom.setDate(dateFrom.getDate() - dateFrom.getDay());
                dateTo.setHours(23, 59, 59, 999);
                dateTo.setDate(dateFrom.getDate() + 6);
                break;
            case StatisticsPeriod.MONTH:
                dateFrom.setDate(1);
                dateFrom.setHours(0, 0, 0, 0);
                dateTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0);
                dateTo.setHours(23, 59, 59, 999);
                break;
            case StatisticsPeriod.ALL:
                return { dateFrom: undefined, dateTo: undefined };
        }

        return { dateFrom, dateTo };
    }
    return (
        <Box display={'flex'} flexDir={'column'} py={4} px={4} maxW={2000} mx={'auto'}>
            <Heading mb={5}>Vitajte späť</Heading>
            <SimpleGrid gap={4} columns={{ base: 1, lg: 5 }}>
                <Box gridColumn={{ base: 'span 1', lg: 'span 2' }}>
                    <CompanyCard onLogoUpdate={() => company.refetch()} company={company.data!} />
                    <Box mt={4}>
                        <SimpleSubscriptionCard isLoading={false} companyDetail={company.data} />
                    </Box>
                </Box>
                <Box gridColumn={{ base: 'span 1', lg: 'span 3' }}>
                    <Box h={'50%'} p={2}>
                        <Heading size={'md'} mt={4} as={Link} to={'/employees'}>
                            {intl.formatMessage({ id: 'plural.TEAM_ACCOUNT' })}
                        </Heading>
                        <EmployeesHorizontalList filter={{ perPage: 5, roles: [Role.TEAM_ACCOUNT] }} />
                    </Box>
                    <Box h={'50%'} p={2}>
                        <Heading alignContent={'center'} size={'md'} mt={4} as={Link} to={'/zones'}>
                            {intl.formatMessage({ id: 'zones' })}
                        </Heading>

                        <ZonesHorizontalList filter={{ perPage: 5 }} />
                    </Box>
                </Box>
            </SimpleGrid>

            <Box gridColumn={{ base: 'span 1', xl: 'span 2' }} mt={8}>
                <Heading my={4} size={'md'}>
                    {intl.formatMessage(
                        { id: selectedPeriod === StatisticsPeriod.ALL ? 'home.allStatistics' : 'home.statisticsFrom' },
                        { date: intl.formatDate(statisticsPeriod.dateFrom, { day: 'numeric', month: 'long' }) }
                    )}
                </Heading>
                <HStack spacing={2} my={2}>
                    <FilterChip
                        label={intl.formatMessage({ id: 'timefilter.today' })}
                        onSelect={() => {
                            setStatisticsPeriod(getPeriod(StatisticsPeriod.DAY));
                            setSelectedPeriod(StatisticsPeriod.DAY);
                        }}
                        selected={selectedPeriod === StatisticsPeriod.DAY}
                        onClear={() => {}}
                        size="lg"
                    />
                    <FilterChip
                        label={intl.formatMessage({ id: 'timefilter.week' })}
                        onSelect={() => {
                            setStatisticsPeriod(getPeriod(StatisticsPeriod.WEEK));
                            setSelectedPeriod(StatisticsPeriod.WEEK);
                        }}
                        selected={selectedPeriod === StatisticsPeriod.WEEK}
                        onClear={() => {}}
                        size="lg"
                    />
                    <FilterChip
                        label={intl.formatMessage({ id: 'timefilter.month' })}
                        onSelect={() => {
                            setStatisticsPeriod(getPeriod(StatisticsPeriod.MONTH));
                            setSelectedPeriod(StatisticsPeriod.MONTH);
                        }}
                        selected={selectedPeriod === StatisticsPeriod.MONTH}
                        onClear={() => {}}
                        size="lg"
                    />
                    <FilterChip
                        label={intl.formatMessage({ id: 'timefilter.all' })}
                        onSelect={() => {
                            setStatisticsPeriod(getPeriod(StatisticsPeriod.ALL));
                            setSelectedPeriod(StatisticsPeriod.ALL);
                        }}
                        selected={selectedPeriod === StatisticsPeriod.ALL}
                        onClear={() => {}}
                        size="lg"
                    />
                </HStack>
                <Card height={430} boxShadow={'md'} borderRadius={'2xl'}>
                    <Flex p={5} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Heading size={'md'}>{intl.formatMessage({ id: 'attendance' })}</Heading>
                        <Box>
                            <Stat>
                                <StatNumber>{statistics.data?.loginsCount}</StatNumber>
                                {statisticsPeriod.dateFrom ? (
                                    <StatHelpText>
                                        {`${intl.formatDate(statisticsPeriod.dateFrom, { day: 'numeric', month: 'long' })} - ${intl.formatDate(
                                            statisticsPeriod.dateTo,
                                            { day: 'numeric', month: 'long' }
                                        )}`}
                                    </StatHelpText>
                                ) : (
                                    <StatHelpText>{intl.formatMessage({ id: 'timefilter.all' })}</StatHelpText>
                                )}
                            </Stat>
                        </Box>
                    </Flex>
                    <AttendanceChart statistics={statistics.data!} />
                </Card>
            </Box>

            <Heading mt={10} mb={2} size={'md'}>
                {intl.formatMessage({ id: 'home.lastAttendance' })}
            </Heading>
            <Box>
                <AttendanceTable
                    data={attendanceQuery.data}
                    fetchNextPage={() => {}}
                    hasNextPage={false}
                    isFetchingNextPage={attendanceQuery.isFetchingNextPage}
                    isLoading={attendanceQuery.isLoading || attendanceQuery.isRefetching}
                    renderItem={(item, showPreview) => {
                        return <AttendanceTableItem onClick={() => showPreview()} item={item} key={item.id} />;
                    }}
                    handleNavigateToDetail={() => navigate('/attendance/detail/116')}
                />
            </Box>
        </Box>
    );
};

export default HomeScreen;
