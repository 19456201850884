import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const solid = defineStyle({
    // textColor: 'surface.500',
    borderRadius: 'xl'
});

const outline = defineStyle({
    borderRadius: 'xl',
});

const ghost = defineStyle({
    borderRadius: 'xl',
});

export const customButtonTheme = defineStyleConfig({
    variants: { solid, outline, ghost },
});
