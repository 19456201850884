import { MinusIcon } from '@chakra-ui/icons';
import { useToast, useColorModeValue, HStack, InputGroup, InputLeftAddon, Input, IconButton } from '@chakra-ui/react';

type BreakTimePeriodInputProps = {
    onRemove?: (key: string) => void;
    index: string;
    initialValue?: { start: string | undefined; end: string | undefined };
    canRemove?: boolean;

    startTime: string;
    endTime: string;
    onStartTimeChange: (value: string) => void;
    onEndTimeChange: (value: string) => void;
};

export const TimePeriodInput: React.FC<BreakTimePeriodInputProps> = ({
    index,
    onRemove,
    startTime,
    endTime,
    canRemove = true,
    onStartTimeChange,
    onEndTimeChange,
}) => {
    const toast = useToast();
    const currentDate = new Date().toISOString().split('T')[0];

    const borderColor = useColorModeValue('gray.300', 'gray.500');
    const backgroundColor = useColorModeValue('gray.200', 'gray.600');

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEndTime = e.target.value;
        if (startTime && newEndTime && newEndTime < startTime) {
            toast({ title: 'Koniec musí byť po začiatku.', status: 'error', position: 'top' });
        } else {
            onEndTimeChange(new Date(`${currentDate}T${newEndTime}`).toISOString());
        }
    };

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onStartTimeChange(e.target.value);
        const newStartTime = e.target.value;
        if (newStartTime && endTime && newStartTime > endTime) {
            toast({ title: 'Koniec musí byť po začiatku.', status: 'error', position: 'top' });
        } else {
            console.log('new vvale', { currentDate, startTime });
            onStartTimeChange(new Date(`${currentDate}T${newStartTime}`).toISOString());
        }
    };

    return (
        <HStack maxW={'100%'} width={'100%'}>
            <InputGroup fontWeight={'semibold'}>
                <InputLeftAddon borderLeftRadius={'xl'} bg={backgroundColor} borderColor={borderColor} borderWidth={1} children="Od" />
                <Input
                    borderRightRadius={'xl'}
                    borderColor={borderColor}
                    bg={backgroundColor}
                    borderWidth={1}
                    type="time"
                    placeholder="od"
                    fontWeight={'semibold'}
                    value={startTime}
                    onChange={handleStartTimeChange}
                />
            </InputGroup>
            <InputGroup fontWeight={'semibold'}>
                <InputLeftAddon borderLeftRadius={'xl'} bg={backgroundColor} borderColor={borderColor} borderWidth={1} children="Do" />
                <Input
                    borderRightRadius={'xl'}
                    borderColor={borderColor}
                    bg={backgroundColor}
                    borderWidth={1}
                    min={startTime}
                    type="time"
                    placeholder="od"
                    fontWeight={'semibold'}
                    value={endTime}
                    onChange={handleEndTimeChange}
                />
            </InputGroup>
            {!!canRemove && !!onRemove && (
                <IconButton
                    colorScheme="red"
                    onClick={() => onRemove(index)}
                    aria-label="remove pause"
                    variant={'ghost'}
                    icon={<MinusIcon />}
                    size={'sm'}
                />
            )}
        </HStack>
    );
};
