import { Card, CardBody, CardHeader, Heading, Text, Image, Box, Button, Link } from '@chakra-ui/react';
import { usePlatform } from '../../hooks/usePlatform';
import { useIntl } from 'react-intl';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const InstallAppAction: React.FC = () => {
    const platform = usePlatform();
    const intl = useIntl();

    if (platform === 'desktop') {
        return (
            <Card borderRadius={'2xl'} boxShadow={'md'}>
                <CardHeader pb={0}>
                    <Heading size="md">{intl.formatMessage({ id: 'intro.apps.mobileApp.download' })}</Heading>
                    <Text fontSize={'sm'}>{intl.formatMessage({ id: 'intro.apps.mobileApp.scanQr' })}</Text>
                </CardHeader>
                <CardBody justifyContent={'center'} alignItems={'center'} display={'flex'}>
                    <Image h={250} aspectRatio={1} src={`${process.env.PUBLIC_URL}/images/appstore_qr.png`} alt="Mobile app" />
                </CardBody>
            </Card>
        );
    }

    return (
        <Box>
            <Button
                mt={5}
                as={Link}
                target="_blank"
                href={
                    platform === 'android'
                        ? 'https://play.google.com/store/apps/details?id=com.supervizer'
                        : 'https://apps.apple.com/sk/app/supervizer/id6479220108'
                }
                rightIcon={<ExternalLinkIcon />}
            >
                {intl.formatMessage({ id: 'about.timeline.downloadApp.title' })}
            </Button>
        </Box>
    );
};
