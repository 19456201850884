import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import CompanyService from '../services/CompanyService';
import { AttendanceStatement, AttendanceStatementSourceData, AttendanceStatementUpdateData, DocumentFilter } from '../../types/documents';
import { AttendanceStatementPagination } from '../../types';

const useUploadAttendanceStatement = (onSuccess?: (data: AttendanceStatement) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<AttendanceStatement, AxiosError, AttendanceStatementSourceData>({
        mutationFn: CompanyService.uploadAttendanceStatement,
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError: (error: any) => {
            onError && onError(error);
        },
    });
};

const useGetAttendanceStatementsPagination = (filter: DocumentFilter) => {
    return useInfiniteQuery<AttendanceStatementPagination, AxiosError<any>>({
        queryKey: ['attendanceStatements', filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getUploadedStatements(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
        
    });
};

const useGetAttendanceStatementDetail = (id: number, enabled = true) => {
    return useQuery<AttendanceStatement, AxiosError<any>>({
        queryKey: ['attendanceStatement', id],
        queryFn: () => CompanyService.getUploadedStatementDetail(id),
        enabled: enabled,
    });
};

const useArchiveAttendanceStatement = (onSuccess?: (data: AttendanceStatement) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<AttendanceStatement, AxiosError, { id: number }>({
        mutationFn: ({ id }) => CompanyService.archiveAttendanceStatement(id),
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError: (error) => {
            onError && onError(error);
        },
    });
};


const useUpdateAttendanceStatement = (onSuccess?: (data: AttendanceStatement) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<AttendanceStatement, AxiosError, { id: number; data: AttendanceStatementUpdateData }>({
        mutationFn: ({ id, data }) => CompanyService.updateAttendanceStatement(id, data),
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError: (error) => {
            onError && onError(error);
        },
    });
};

const useDeleteAttendanceStatement = (onSuccess?: () => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<void, AxiosError, number>({
        mutationFn: (id) => CompanyService.deleteAttendanceStatement(id),
        onSuccess: () => {
            onSuccess && onSuccess();
        },
        onError: (error) => {
            onError && onError(error);
        },
    });
};



export {
    useUploadAttendanceStatement,
    useGetAttendanceStatementsPagination,
    useGetAttendanceStatementDetail,
    useDeleteAttendanceStatement,
    useUpdateAttendanceStatement,
    useArchiveAttendanceStatement,
};
