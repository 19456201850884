import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
    Tr,
    useColorModeValue,
    Td,
    VStack,
    HStack,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    MenuItem,
    Text,
    Icon,
    Tooltip,
    Center,
    useDisclosure,
    Box,
} from '@chakra-ui/react';
import { MdAlarm, MdCloudOff, MdMoreVert, MdCloudDone, MdCloudUpload } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { DocumentStatus } from '../../../../../types/enums/DocumentStatus';
import { roundNumber } from '../../../../../utils/formatUtils';
import { TimeRangeVisualizer } from '../../../../common/DateRangeVisualizer';
import { DocumentStatusBadge } from '../../../../documents/DocumentStatus';
import { AttendanceStatementListItemData } from '../../../../../query/indexedDb/useLocalAttendanceStatementList';
import { getDocumentUploadStatus } from '../../../../../utils/DocumentUtils';
import { DeleteAlertDialog } from '../../../../ui/DeleteAlertDialog';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

type StoredAttendanceStatementTableItemProps = {
    report: AttendanceStatementListItemData;
    onDelete?: () => void;
    onChangeStatus?: (status: DocumentStatus) => void;
    onClick?: () => void;
    existsLocalAndUploadedVersion: boolean;
    localId?: number;
    canArchive?: boolean;
};

export const AttendanceStatementTableItem: React.FC<StoredAttendanceStatementTableItemProps> = ({
    report,
    onDelete,
    onChangeStatus,
    onClick,
    existsLocalAndUploadedVersion,
    localId,
    canArchive,
}) => {
    const { t, formatDate } = useSuIntl();
    const deleteDisclosure = useDisclosure();

    const showLocalVersionWarning = existsLocalAndUploadedVersion && localId !== undefined;
    const warningBackgroundColor = useColorModeValue('yellow.300', 'yellow.800');
    const warningTextColor = useColorModeValue('yellow.800', 'yellow.400');
    return (
        <>
            <Tr
                _hover={{
                    bg: useColorModeValue('gray.300', 'gray.600'),
                }}
                cursor={'pointer'}
                bg={useColorModeValue('white', 'gray.700')}
                onClick={onClick}
            >
                <Td
                    border="none" // Remove any default borders
                    borderLeftRadius={'xl'}
                >
                    <VStack alignItems="start" spacing={2}>
                        <Text fontSize={'md'} fontWeight={'bold'}>
                            {report.name}
                        </Text>

                        <Tooltip label={t('field.createdAt')}>
                            <HStack>
                                <Icon as={EditIcon} />
                                <Text>{formatDate(report.createdAt)}</Text>
                            </HStack>
                        </Tooltip>
                    </VStack>
                </Td>

                {showLocalVersionWarning && (
                    <Td>
                        <Box m={'auto'} w={'fit-content'} borderRadius={'md'} bg={warningBackgroundColor} textAlign="center" p={1}>
                            {t('doc.uploadStatus.changed.title')
                                .split(' ')
                                .map((word, index) => (
                                    <Text
                                        color={warningTextColor}
                                        fontWeight={'500'}
                                        key={index}
                                        fontSize={'small'}
                                        noOfLines={1}
                                        textTransform="uppercase"
                                    >
                                        {word}
                                    </Text>
                                ))}
                        </Box>
                    </Td>
                )}

                <Td colSpan={showLocalVersionWarning ? 1 : 2} borderRightRadius={'xl'} pr={0}>
                    <HStack justifyContent={'right'}>
                        <VStack align={'end'}>
                            <HStack gap={8}>
                                <Tooltip label={t('attendance.timeWorked')}>
                                    <HStack>
                                        <Icon as={MdAlarm} />
                                        <Text fontWeight={'500'}>{roundNumber(report.totalHours, 2)}h</Text>
                                    </HStack>
                                </Tooltip>
                                <UploadIcon
                                    status={
                                        showLocalVersionWarning
                                            ? 'changed'
                                            : getDocumentUploadStatus({ localId: localId, externalId: report.externalId })
                                    }
                                />
                                <DocumentStatusBadge status={report.status} />
                            </HStack>
                            <TimeRangeVisualizer dateFrom={formatDate(report.dateFrom)} dateTo={formatDate(report.dateTo)} />
                        </VStack>
                        <Menu>
                            <MenuButton
                                colorScheme="gray"
                                onClick={(e) => e.stopPropagation()}
                                as={IconButton}
                                aria-label="actions"
                                icon={<MdMoreVert size={25} />}
                                variant={'ghost'}
                                size={'lg'}
                            />
                            <MenuList>
                                <MenuItem isDisabled>Export</MenuItem>
                                {report.status !== DocumentStatus.ARCHIVED && (
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onChangeStatus?.(
                                                report.status === DocumentStatus.DRAFT ? DocumentStatus.DONE : DocumentStatus.DRAFT
                                            );
                                        }}
                                    >
                                        {t(
                                            `doc.status.markAs.${report.status === DocumentStatus.DRAFT ? DocumentStatus.DONE : DocumentStatus.DRAFT}`
                                        )}
                                    </MenuItem>
                                )}
                                {canArchive && (
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onChangeStatus?.(DocumentStatus.ARCHIVED);
                                        }}
                                    >
                                        {t(`editor.moveToArchive`)}
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteDisclosure.onOpen();
                                    }}
                                    color={'red'}
                                    icon={<DeleteIcon />}
                                >
                                    {t('delete')}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Td>
            </Tr>
            <DeleteAlertDialog
                isOpen={deleteDisclosure.isOpen}
                onClose={deleteDisclosure.onClose}
                onConfirm={() => {
                    onDelete?.();
                    deleteDisclosure.onClose();
                }}
            />
        </>
    );
};

export const UploadIcon: React.FC<{ size?: number; status: 'uploaded' | 'changed' | 'notUploaded' }> = ({ size = 19, status }) => {
    let iconComponent: JSX.Element;
    const intl = useIntl();

    switch (status) {
        case 'uploaded':
            iconComponent = <Icon as={MdCloudDone} color={'green'} boxSize={size} />;
            break;
        case 'changed':
            iconComponent = <Icon as={MdCloudUpload} color={'primary.500'} boxSize={size} />;
            break;
        case 'notUploaded':
            iconComponent = <Icon as={MdCloudOff} color={'red'} boxSize={size} />;
            break;
    }

    return (
        <Tooltip label={intl.formatMessage({ id: `doc.uploadStatus.${status}.title` })}>
            <Center>{iconComponent}</Center>
        </Tooltip>
    );
};
