import React, { ReactNode } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    useTheme,
    useColorMode,
    Button,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import { FiHome, FiMenu, FiChevronDown, FiCalendar, FiUsers, FiMap } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaMoon, FaSun } from 'react-icons/fa';
import './Navbar.css';
import { useLogout } from '../../query/resource-hooks/auth';
import { useAuthStore } from '../../store/auth/authStore';
import { useIntl } from 'react-intl';
import { User } from '../../types';
import { MdInfo, MdLanguage } from 'react-icons/md';
import { useSettingsStore } from '../../store/ui/SettingsStore';
import LanguageSelectModal from '../ui/LanguageSelectModal';
import NotificationList from '../ui/NotificationsList';
import { BsQuestion } from 'react-icons/bs';
import { ChevronLeftIcon, QuestionIcon } from '@chakra-ui/icons';
import { Breadcrumbs } from './Breadcrumbs';
import { IntroModal } from '../intro/IntroModal';

interface LinkItemProps {
    name: string;
    icon: IconType;
    href?: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'home.title', icon: FiHome, href: '/home' },
    { name: 'tab.attendance', icon: FiCalendar, href: '/attendance' },
    { name: 'tab.Employees', icon: FiUsers, href: '/employees' },
    { name: 'tab.Zones', icon: FiMap, href: '/zones' },
];

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
    const user = useAuthStore((state) => state.user)!;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCollapsed, setCollapsed] = React.useState(false);
    return (
        <Box>
            <Box width="full" zIndex={'sticky'}>
                <Header onCollapseChange={setCollapsed} user={user} onOpen={onOpen} />
            </Box>

            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent companyName={user?.company?.name} onClose={onClose} />
                </DrawerContent>
            </Drawer>

            <Flex>
                <Box>
                    <SidebarContent isCollapsed={isCollapsed} companyName={user?.company?.name} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
                </Box>
                <Box
                    bg={useColorModeValue('gray.100', 'gray.900')}
                    height="calc(100vh - 84px)"
                    overflowY="auto"
                    flex="1"
                    borderRadius="22"
                    mr={{ base: 0, md: 2 }}
                >
                    <Box px={2}>
                        <NotificationList />
                    </Box>
                    {children}
                    <IntroModal authUser={user} />
                </Box>
            </Flex>
        </Box>
    );
}

const MenuColorSwitcher = () => {
    const { toggleColorMode } = useColorMode();
    const intl = useIntl();
    const text = useColorModeValue(intl.formatMessage({ id: 'colorMode.dark' }), intl.formatMessage({ id: 'colorMode.light' }));
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);

    return (
        <MenuItem onClick={toggleColorMode} icon={<SwitchIcon />} aria-label={`Switch to ${text} mode`}>
            {text}
        </MenuItem>
    );
};
interface SidebarProps extends BoxProps {
    onClose: () => void;
    companyName?: string;
    isCollapsed?: boolean;
}

const SidebarContent = ({ onClose, companyName, isCollapsed, ...rest }: SidebarProps) => {
    const intl = useIntl();
    return (
        <Box transition="width 0.3s ease" w={{ base: 'full', md: isCollapsed ? '100px' : '240px' }} h="full" {...rest}>
            <Flex direction="column" h="full" justifyContent="space-between">
                <Box pt={{ base: 0, md: 4 }}>
                    <Flex display={{ base: 'flex', md: 'none' }} h="20" alignItems="center" mx="8" justifyContent="space-between">
                        <Link to={'/'}>
                            <Image src={`${process.env.PUBLIC_URL}/logo_simple.png`} alt="logo" height={'50px'} />
                        </Link>
                        <Flex flexDir={'column'}>
                            <Text pl={1} fontSize="xl" fontWeight="bold">
                                Supervizer
                            </Text>
                            <Text noOfLines={1} pl={1} fontSize="md" color="gray.400">
                                {companyName}
                            </Text>
                        </Flex>
                        <CloseButton onClick={onClose} />
                    </Flex>

                    {LinkItems.map((link) => (
                        <NavItem label={intl.formatMessage({ id: link.name })} isCollapsed={isCollapsed} key={link.name} icon={link.icon} onClick={onClose} href={link.href}>
                            {intl.formatMessage({ id: link.name })}
                        </NavItem>
                    ))}
                </Box>
                <Box>
                    <Button
                        as={Link}
                        to={'/docs/home'}
                        py={4}
                        rightIcon={<Icon as={BsQuestion} boxSize={6} />}
                        width={'full'}
                        variant={'ghost'}
                    >
                        {intl.formatMessage({ id: 'docs.help' })}
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    href?: string;
    children: ReactNode;
    isCollapsed?: boolean;
    label?: string;
}
const NavItem = ({ icon, children, href, isCollapsed, label , ...rest }: NavItemProps) => {
    const theme = useTheme();
    const selectedColor = useColorModeValue(theme.colors.gray[300], theme.colors.gray[700]);
    const hoverColor = useColorModeValue(theme.colors.primary[500], theme.colors.primary[300]);
    return (
        <NavLink to={href || '#'} style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
                <Tooltip label={isCollapsed ? label : ''}>

                <Flex
                    align="center"
                    justify={isCollapsed ? 'center' : 'flex-start'}
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    bg={isActive ? selectedColor : undefined}
                    boxShadow={isActive ? 'lg' : undefined}
                    _hover={{
                        bg: hoverColor,
                        color: 'white',
                    }}
                    h={'56px'}
                    {...rest}
                    >
                    {icon && (
                        <Icon
                        mr={isCollapsed ? 0 : 4}
                        fontSize="18"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                        />
                    )}
                    {
                        isCollapsed ? null : (children)
                    }
                </Flex>
                    </Tooltip>
            )}
        </NavLink>
    );
};

interface HeaderProps extends FlexProps {
    onOpen: () => void;
    user: User;
    onCollapseChange: (isCollapsed: boolean) => void
}

const Header = ({ onOpen, user, onCollapseChange ,...rest }: HeaderProps) => {
    const navigation = useNavigate();
    const logoutMutation = useLogout(() => navigation('/login'));
    const settingsStore = useSettingsStore();
    const intl = useIntl();

    const languageDisclosure = useDisclosure();

    const language = useSettingsStore((state) => state.language);

    const [collapsedSidebar, setCollapsedSidebar] = React.useState(false);

    function handleLogout() {
        logoutMutation.mutate();
    }

    function handleCollapse(value: boolean) {
        setCollapsedSidebar(value);
        onCollapseChange(value);
    }

    return (
        <Flex
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            justifyContent={{ base: 'space-between', md: 'space-between' }}
            {...rest}
        >
            <LanguageSelectModal isOpen={languageDisclosure.isOpen} onClose={languageDisclosure.onClose} />

            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <HStack justifyContent={{ base: 'center', md: 'start' }} overflowX="auto">
               
                    <Box overflowX={'hidden'} transition="width 0.3s ease" width={collapsedSidebar ? '100px' : '240px'} display={{ base: 'none', md: 'block' }}>
                        <Flex
                            h="20"
                            alignItems="center"
                            mr="4"
                            pl={'4'}
                            mt={2}
                            bg={useColorModeValue('gray.100', 'gray.900')}
                            borderRadius={22}
                            justify={'space-between'}
                        >
                            <HStack>
                                <Link to={'/'}>
                                    <Image src={`${process.env.PUBLIC_URL}/logo_simple.png`} alt="logo" height={'50px'} />
                                </Link>
                                {!collapsedSidebar && (
                                    <Flex flexDir={'column'} pl={2}>
                                        <Text fontSize="xl" fontWeight="bold">
                                            Supervizer 
                                        </Text>
                                        <Tooltip label={user?.company?.name ?? ''} aria-label="Company name">
                                            <Text noOfLines={1} fontSize="md" color="gray.500">
                                                {user?.company?.name ?? ''}
                                            </Text>
                                        </Tooltip>
                                    </Flex>
                                )}
                            </HStack>

                            <IconButton
                                size={'sm'}
                                mr={-3}
                                aria-label="collapse menu"
                                colorScheme='gray'
                                icon={
                                    <ChevronLeftIcon
                                        style={{
                                            transition: 'transform 0.3s ease',
                                            transform: collapsedSidebar ? 'rotate(180deg)' : undefined,
                                        }}

                                        boxSize={7}
                                    />
                                }
                                onClick={() => handleCollapse(!collapsedSidebar)}
                            />
                        </Flex>
                    </Box>
                
                <Box paddingX={{ base: 2, md: 0 }} overflowX="auto">
                    <Breadcrumbs />
                </Box>
            </HStack>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar size={'sm'} name={user.surname || user.firstName} />
                                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                                    <Text fontSize="sm">{`${user.firstName} ${user.surname || ''}`}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {intl.formatMessage({ id: user.role })}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            borderRadius={'xl'}
                            zIndex={5}
                            bg={useColorModeValue('white', 'gray.700')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}
                        >
                            <MenuItem onClick={() => navigation('/profile')}>{intl.formatMessage({ id: 'profile' })}</MenuItem>
                            <MenuItem onClick={() => navigation('/company')}>{intl.formatMessage({ id: 'company' })}</MenuItem>
                            <MenuDivider />
                            <MenuColorSwitcher />
                            <MenuItem onClick={languageDisclosure.onOpen} icon={<MdLanguage />} command={language || ''}>
                                {intl.formatMessage({ id: 'language' })}
                            </MenuItem>
                            <MenuItem icon={<QuestionIcon />} onClick={() => navigation('/docs')}>
                                {intl.formatMessage({ id: 'docs.title' })}
                            </MenuItem>
                            <MenuItem icon={<MdInfo />} onClick={() => settingsStore.setIntroSeen(false)}>
                                Sprievodca
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => handleLogout()}>{intl.formatMessage({ id: 'logOut' })}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
