import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    Text,
    useDisclosure,
    useTheme,
    VStack,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { AboutLayout } from '../../components/about/AboutHeader';
import './About.css';
import { Link } from 'react-router-dom';
import { FiSun, FiZap, FiLock, FiMap, FiArchive, FiCast, FiKey, FiUsers, FiSend, FiEye } from 'react-icons/fi';
import { Article } from '../../components/about/Article';
import AboutFooter from '../../components/about/Footer';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { StartTimeline } from '../../components/about/StartTimeline/StartTimeLine';
import { FeatureTabs } from '../../components/about/FeatureTabs/FeatureTabs';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { PricingTable } from '../../components/about/Pricing/PricingTable';
import { References } from '../../components/about/References/References';
import { SectionTitle } from '../../components/about/SectionTitle';
import { ContactModal } from '../../components/about/contact/ContactModal';
import AnimatedIntro from '../../components/about/hero/AnimatedIntro';
import { BsChevronRight } from 'react-icons/bs';

const AboutPage: React.FC = () => {
    const intl = useIntl();
    const theme = useTheme();

    // Custom event for AOS scroll animation start - to be same on all browsers
    useEffect(() => {
        const timer = setTimeout(() => {
            document.dispatchEvent(new Event('appLoaded'));
        }, 300);

        return () => clearTimeout(timer);
    }, []);

    const contactModalDisclosure = useDisclosure();

    return (
        <AboutLayout>
            {/* HERO */}
            <Box justifyContent={'center'} display={'flex'} p={{ base: 0, md: 10 }} position="relative" overflow={'hidden'}>
                <SimpleGrid className="container" mb={4} columns={{ base: 1, md: 2 }}>
                    <Flex
                        // order={{ base: 2, md: 0 }}
                        flexDir={'column'}
                        px={{ base: 10, md: 0 }}
                        pb={{ base: 5, md: 10, lg: 20 }}
                        align={{ base: 'center', md: 'start' }}
                        justifyContent={'center'}
                        data-aos="fade-left"
                        mt={{ base: 10, md: 0 }}
                    >
                        <Heading my={{ base: 2, md: 5 }} size={'2xl'} color="primary.300">
                            {intl.formatMessage({ id: 'about.title' })}
                        </Heading>

                        <Text fontSize={'lg'} fontWeight={600} color={'gray.400'}>
                            {intl.formatMessage({ id: 'about.subtitle' })}
                        </Text>
                        <Flex gap={{ base: 2, md: 5 }} mt={3} flexDir={{ base: 'row', md: 'row' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.supervizer">
                                <Image
                                    maxH={{ base: 10, md: 50 }}
                                    src={`${process.env.PUBLIC_URL}/images/google-play-icon.svg`}
                                    alt="Google Play Store"
                                />
                            </a>
                            <a href="https://apps.apple.com/sk/app/supervizer/id6479220108">
                                <Image
                                    maxH={{ base: 10, md: 50 }}
                                    src={`${process.env.PUBLIC_URL}/images/apple-store-icon.svg`}
                                    alt="Apple app store"
                                />
                            </a>
                        </Flex>
                        <Button
                            textTransform={'uppercase'}
                            variant={'outline'}
                            rightIcon={<BsChevronRight />}
                            mt={3}
                            px={10}
                            as={Link}
                            to={'/register'}
                            colorScheme="primaryAbout"
                        >
                            {intl.formatMessage({ id: 'about.startButton' })}
                        </Button>
                    </Flex>
                    <Center data-aos="zoom-in" justifyContent={{ base: 'center', md: 'center' }}>
                        <Image
                            maxWidth={{ base: 200, md: 320, lg: 420 }}
                            src={`${process.env.PUBLIC_URL}/images/bannerMockup.webp`}
                            alt={'banner image'}
                        />
                    </Center>
                </SimpleGrid>
            </Box>

            <AnimatedIntro />

            <Box marginTop={20}>
                <Box className="container">
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right">
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiZap} />}
                                text={intl.formatMessage({ id: 'about.easy.text' })}
                                title={intl.formatMessage({ id: 'about.easy.subtitle' })}
                                textColor="gray.400"
                            />
                        </Box>

                        <Box data-aos="zoom-in-left">
                            <Image
                                className="feature__image feature__image__right"
                                src={`${process.env.PUBLIC_URL}/images/simple_use.svg`}
                            />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right" order={{ md: 0, base: 2 }}>
                            <Image className="feature__image" src={`${process.env.PUBLIC_URL}/images/accessible.svg`} />
                        </Box>
                        <Box data-aos="zoom-in-left">
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiSun} />}
                                text={intl.formatMessage({ id: 'about.accessible.text' })}
                                title={intl.formatMessage({ id: 'about.accessible.subtitle' })}
                                textColor="gray.400"
                            />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right">
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiLock} />}
                                text={intl.formatMessage({ id: 'about.face.text' })}
                                title={intl.formatMessage({ id: 'about.face.subtitle' })}
                                textColor="gray.400"
                            />
                        </Box>
                        <Box data-aos="zoom-in-left">
                            <Image className="feature__image__right" src={`${process.env.PUBLIC_URL}/images/face_recognition.svg`} />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right" order={{ md: 0, base: 2 }}>
                            <Image className="feature__image" src={`${process.env.PUBLIC_URL}/images/zone_map.svg`} />
                        </Box>
                        <Box data-aos="zoom-in-left">
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiMap} />}
                                text={intl.formatMessage({ id: 'about.position.text' })}
                                title={intl.formatMessage({ id: 'about.position.subtitle' })}
                                textColor="gray.400"
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
            </Box>

            <SectionTitle title={intl.formatMessage({ id: 'about.whatYouFind' })} />

            <Box minH={'100vh'}>
                <Box>
                    <Box width={'100%'}>
                        <FeatureTabs />
                    </Box>
                </Box>
            </Box>

            <SectionTitle title={intl.formatMessage({ id: 'about.howItWorks' })} />

            <HStack px={2} justify={'center'} data-aos="zoom-in-up">
                <Box textAlign={'center'} boxShadow={'md'} maxW={'900px'} backgroundColor={'elevation.500'} p={2} borderRadius={'xl'}>
                    <Text fontWeight={600} color={'gray.400'}>
                        {intl.formatMessage({ id: 'about.timeline.header.whatItIs' })}
                    </Text>
                    <Divider py={1} />
                    <Text color={'gray.400'}>{intl.formatMessage({ id: 'about.timeline.header.webApp' })}</Text>
                </Box>
            </HStack>

            <Box className="container">
                <StartTimeline />
            </Box>

            <HStack justify={'center'}>
                <Box mb={4} maxW={'900px'} boxShadow={'md'} background={'elevation.500'} borderRadius={'xl'} p={2}>
                    <SimpleGrid columns={{ base: 1, md: 2 }}>
                        <Box>
                            <Heading color={'gray.400'} size={'md'}>
                                {intl.formatMessage({ id: 'docs.needHelp.title' })}
                            </Heading>
                            <Text color={'gray.500'} fontSize={'sm'} fontWeight={600}>
                                {intl.formatMessage({ id: 'docs.needHelp.text' })}
                            </Text>
                        </Box>
                        <Center justifyContent={'center'}>
                            <Button
                                colorScheme="primaryAbout"
                                variant={'outline'}
                                rightIcon={<ChevronRightIcon />}
                                to={'/docs/home'}
                                textTransform={'uppercase'}
                                borderRadius={'full'}
                                height={'40px'}
                                px={6}
                                as={Link}
                            >
                                {intl.formatMessage({ id: 'about.findOutMore' })}
                            </Button>
                        </Center>
                    </SimpleGrid>
                </Box>
            </HStack>

            <Box className="block--skewed-left">
                <Box className="container" px={5} pt={8}>
                    <Heading data-aos="zoom-in-up" color={'white'} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.features.title' })}
                    </Heading>
                    <SimpleGrid gap={10} pt={5} columns={{ base: 1, md: 2 }}>
                        <Box
                            position="relative"
                            overflow="hidden"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            order={{ md: 0, base: 2 }}
                            data-aos="zoom-in"
                        >
                            <Image
                                maxW={'700px'}
                                src={`${process.env.PUBLIC_URL}/images/introClip.png`}
                                objectFit="cover"
                                objectPosition="center"
                                justifySelf="center"
                            />
                        </Box>
                        <VStack gap={6} data-aos="zoom-in-left">
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiArchive} />}
                                text={intl.formatMessage({ id: 'about.informationWithYou.text' })}
                                title={intl.formatMessage({ id: 'about.informationWithYou.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiCast} />}
                                text={intl.formatMessage({ id: 'about.functionsWithYou.text' })}
                                title={intl.formatMessage({ id: 'about.functionsWithYou.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiKey} />}
                                text={intl.formatMessage({ id: 'about.accessRights.text' })}
                                title={intl.formatMessage({ id: 'about.accessRights.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                        </VStack>
                    </SimpleGrid>
                    <SimpleGrid pt={5} gap={10} columns={{ base: 1, md: 2 }}>
                        <VStack data-aos="zoom-in-right" gap={6}>
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiUsers} />}
                                text={intl.formatMessage({ id: 'about.teamAccount.text' })}
                                title={intl.formatMessage({ id: 'about.teamAccount.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiSend} />}
                                text={intl.formatMessage({ id: 'about.shareInformation.text' })}
                                title={intl.formatMessage({ id: 'about.shareInformation.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[300]} boxSize={8} as={FiEye} />}
                                text={intl.formatMessage({ id: 'about.overview.text' })}
                                title={intl.formatMessage({ id: 'about.overview.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                        </VStack>
                        <Box
                            data-aos="zoom-in"
                            position="relative"
                            overflow="hidden"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            order={{ md: 0, base: 2 }}
                        >
                            <Image
                                maxW={'700px'}
                                src={`${process.env.PUBLIC_URL}/images/introClip.png`}
                                objectFit="cover"
                                objectPosition="center"
                                justifySelf="center"
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
            </Box>

            <HStack spacing={2} my={10} maxWidth={'100%'} data-aos="zoom-in-up">
                <Divider borderColor={'gray.400'} />
                <Box data-aos="fade-up" width={'100%'} minWidth={'fit-content'}>
                    <Heading color={theme.colors.primary[300]} textAlign={'center'}>
                        {intl.formatMessage({ id: 'pricing.title' })}
                    </Heading>
                    <Text fontSize={'lg'} fontWeight={'bold'} textAlign={'center'} textColor="gray.400">
                        {intl.formatMessage({ id: 'pricing.testPeriod.title' })}
                    </Text>
                </Box>
                <Divider borderColor={'gray.400'} />
            </HStack>
            <Box className="container" data-aos="zoom-in-up" textAlign={'center'}>
                <Text px={2} fontWeight={'600'} textColor="gray.400">
                    {intl.formatMessage({ id: 'pricing.testPeriod.text' })}
                </Text>
            </Box>
            <Box className="container" maxWidth={'700px'} mb={12} data-aos="zoom-in-up">
                <PricingTable />
            </Box>

            <HStack spacing={2} my={10} maxWidth={'100%'} data-aos="zoom-in-up">
                <Divider borderColor={'gray.400'} />
                <Box data-aos="fade-up" width={'100%'} minWidth={'fit-content'}>
                    <Heading color={theme.colors.primary[300]} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.references.title' })}
                    </Heading>
                    <Text textColor="gray.400" fontSize={'lg'} fontWeight={'bold'} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.references.subtitle' })}
                    </Text>
                </Box>
                <Divider borderColor={'gray.400'} />
            </HStack>
            <Box className="container" mb={12} data-aos="zoom-in-up">
                <References />
            </Box>

            <Box className="container">
                <Box mx={5} className="callout-signup" p={5} bg={theme.colors.primary[300]} borderRadius={'md'}>
                    <SimpleGrid columns={{ base: 1, md: 2 }}>
                        <Flex
                            pb={{ base: 3, md: 0 }}
                            flexDir={'column'}
                            display={'flex'}
                            align={{ base: 'center', md: 'start' }}
                            justifyContent={'center'}
                        >
                            <Heading size={'lg'} color={'surface.500'}>
                                {intl.formatMessage({ id: 'about.readyToStart.title' })}
                            </Heading>
                            <Text align={{ base: 'center', md: 'left' }} size={'lg'} fontWeight={600} color={'elevation.500'}>
                                {intl.formatMessage({ id: 'about.readyToStart.text' })}
                            </Text>
                        </Flex>
                        <Center justifyContent={'center'}>
                            <VStack>
                                <Button
                                    to={'/register'}
                                    textTransform={'uppercase'}
                                    borderRadius={'full'}
                                    height={'50px'}
                                    px={10}
                                    textColor={'primary.300'}
                                    backgroundColor={'elevation.500'}
                                    as={Link}
                                >
                                    {intl.formatMessage({ id: 'login.register' })}
                                </Button>
                                <Button
                                    onClick={contactModalDisclosure.onOpen}
                                    variant={'ghost'}
                                    colorScheme="whiteAlpha"
                                    textColor={'gray.800'}
                                >
                                    {intl.formatMessage({ id: 'contactUs.title' })}
                                </Button>
                            </VStack>
                        </Center>
                    </SimpleGrid>
                </Box>
            </Box>

            <AboutFooter />
            <ContactModal isOpen={contactModalDisclosure.isOpen} onClose={contactModalDisclosure.onClose} />
        </AboutLayout>
    );
};

export default AboutPage;
