import React from 'react';
import { Company } from '../../types';
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    HStack,
    Tag,
    TagLabel,
    TagRightIcon,
    Tooltip,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { getSubscriptionBadgeIcon, getSubscriptionColorScheme, getSubscriptionStatusText } from '../../screens/company/CompanyDetailScreen';
import { useNavigate } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';

type SimpleSubscriptionCardProps = {
    companyDetail?: Company;
    isLoading: boolean;
};

export const SimpleSubscriptionCard: React.FC<SimpleSubscriptionCardProps> = ({ companyDetail, isLoading }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    return (
        <Card
            onClick={() => navigate('/company')}
            borderRadius={'2xl'}
            boxShadow={'md'}
            _hover={{ transform: 'scale(1.02)' }}
            transition="transform 0.2s"
            cursor={'pointer'}
        >
            <CardHeader>
                <Flex justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                    <Heading size={'md'}>{intl.formatMessage({ id: 'billing.subscription' })}</Heading>
                    <Tooltip label={intl.formatMessage({ id: 'pricing.numberOfUsers' })}>
                        <Tag>
                            <TagLabel>{companyDetail?.meta.numberOfUsers ?? '-'}</TagLabel>
                            <TagRightIcon as={FiUsers} />
                        </Tag>
                    </Tooltip>
                </Flex>
            </CardHeader>
            <CardBody>
                <HStack justify={'space-between'}>
                    <Heading size={'sm'}>{intl.formatMessage({ id: 'billing.status' })}</Heading>
                    <Badge fontSize="0.8em" borderRadius={'md'} colorScheme={getSubscriptionColorScheme(companyDetail?.billingAccount)}>
                        {getSubscriptionBadgeIcon(companyDetail?.billingAccount)}
                        {getSubscriptionStatusText(intl, companyDetail?.billingAccount)}
                    </Badge>
                </HStack>
            </CardBody>
        </Card>
    );
};
