import { HStack } from '@chakra-ui/react';
import React, { ComponentProps } from 'react';
import { useAuthStore } from '../../store/auth/authStore';
import UserPolicy from '../../policies/UserPolicy';
import { Role } from '../../types';
import { FilterChip } from '../attendance/AttendanceListFilter';
import { useIntl } from 'react-intl';

type UserRoleFilterProps = ComponentProps<typeof HStack> & {
    selectedRoles: Role[];
    setSelectedRoles: (roles: Role[]) => void;
};

export const UserRoleFilter: React.FC<UserRoleFilterProps> = ({ selectedRoles, setSelectedRoles, ...restProps }) => {
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const roles = UserPolicy.assignableRoles(loggedUser!);

    return (
        <HStack spacing={4} {...restProps}>
            {roles.reverse().map((role) => (
                <FilterChip
                    key={role}
                    label={intl.formatMessage({ id: `plural.${role}` })}
                    selected={selectedRoles.includes(role)}
                    onSelect={() => setSelectedRoles([...selectedRoles, role])}
                    onClear={() => setSelectedRoles(selectedRoles.filter((r) => r !== role))}
                />
            ))}
        </HStack>
    );
};
