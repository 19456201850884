import React, { useEffect } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { InstallAppModal } from '../../components/about/StartTimeline/modals/InstallAppModal';

const RedirectToAppStore: React.FC = () => {
    const [manualRedirectOpen, setManualRedirectOpen] = React.useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.supervizer';
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = 'https://apps.apple.com/sk/app/supervizer/id6479220108';
        } else {
            console.warn('Unknown device, opening modal');
            setManualRedirectOpen(true);
        }
    }, []);

    return (
        <Box textAlign="center" mt={10}>    
            <Text mt={4}>Redirecting to the appropriate app store...</Text>
            <InstallAppModal isOpen={manualRedirectOpen} onClose={() => setManualRedirectOpen(false)} />
        </Box>
    );
};

export default RedirectToAppStore;
