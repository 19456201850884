import { Box, Center, Flex, Icon, Heading } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useGetUserProfileDetail } from '../../query/resource-hooks/company';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import {
    decodeSimpleReportModalQueryToObject,
} from '../../components/attendance/reports/SimpleReportModal';
import { useMemo } from 'react';
import { LoadingCard } from '../../components/ui/LoadingCard';
import './EmployeeAttendanceReport.css';
import { FiUser } from 'react-icons/fi';
import { UserAttendanceReportTab } from '../../components/attendance/reports/UserAttendanceReportTab';

const NewReportForStatementScreen: React.FC = () => {
    const [search] = useSearchParams();
    const memoizedSearchParams = useMemo(() => {
        return decodeSimpleReportModalQueryToObject(search);
    }, [search]);

    const navigate = useNavigate();
    const intl = useIntl();
    const user = useGetUserProfileDetail(memoizedSearchParams.userId);

    if (user.isLoading) {
        return (
            <Box>
                <SecondPageHeader title={intl.formatMessage({ id: 'attendanceReport.title' })} onBack={() => navigate(-1)} />
                <Center borderRadius={'2xl'}>
                    <LoadingCard />
                </Center>
            </Box>
        );
    }

    return (
        <Box height="100%">
            <SecondPageHeader title={intl.formatMessage({ id: 'attendanceReport.title' })} onBack={() => navigate(-1)} />

            <Flex px={6} py={2} alignItems={'center'}>
                <Icon as={FiUser} mr={2} />
                <Heading size={'md'}>{user?.data?.fullName}</Heading>
            </Flex>

            <UserAttendanceReportTab
                user={user.data!}
                initialBreakCreatorData={memoizedSearchParams.additionalSettings?.breakCreatorData}
                initialDates={[new Date(memoizedSearchParams.dateFrom), new Date(memoizedSearchParams.dateTo)]}
            />
        </Box>
    );
};

export default NewReportForStatementScreen;
