import React, { ReactNode } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    HStack,
    Box,
    useColorModeValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';
import { IntroWelcomeSlide } from './slides/IntroWelcomeSlide';
import { IntroStepper } from './components/IntroStepper';
import { IntroAppOverview } from './slides/IntroAppOverview';
import { IntroAttendance } from './slides/IntroAttendance';
import { IntroRoles } from './slides/IntroRoles';
import { IntroQuickStart } from './slides/IntroQuickStart';
import { IntroDone } from './slides/IntroDone';
import { useSettingsStore } from '../../store/ui/SettingsStore';
import { User } from '../../types';

export const IntroModal: React.FC<{ authUser: User }> = ({ authUser }) => {
    const settingsStore = useSettingsStore();

    const intl = useIntl();
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const slides: { component: ReactNode; title: string; description?: string }[] = [
        {
            component: (
                <IntroWelcomeSlide
                    authUser={authUser}
                    onNext={handleGoToNext}
                    onSkip={handleDismiss}
                />
            ),
            title: intl.formatMessage({ id: 'intro.welcome.title' }),
        },
        {
            component: <IntroAppOverview />,
            title: intl.formatMessage({ id: 'intro.apps.title' }),
            description: intl.formatMessage({ id: 'intro.apps.subtitle' }),
        },
        {
            component: <IntroAttendance />,
            title: intl.formatMessage({ id: 'intro.attendance.title' }),
            description: intl.formatMessage({ id: 'intro.attendance.subtitle' }),
        },
        {
            component: <IntroRoles />,
            title: intl.formatMessage({ id: 'intro.role.title' }),
            description: intl.formatMessage({ id: 'intro.role.subtitle' }),
        },
        {
            component: <IntroQuickStart />,
            title: intl.formatMessage({ id: 'intro.quickStart.title' }),
            description: intl.formatMessage({ id: 'intro.quickStart.subtitle' }),
        },
        {
            component: <IntroDone onFinish={handleDismiss} />,
            title: intl.formatMessage({ id: 'intro.quickStart.done.title' }),
            description: intl.formatMessage({ id: 'intro.quickStart.done.subtitle' }),
        },
    ];

    function handleGoToNext() {
        if (currentSlide === slides.length - 1) {
            return;
        }

        setCurrentSlide((prev) => prev + 1);
    }

    function handleGoToPrevious() {
        if (currentSlide === 0) {
            return;
        }

        setCurrentSlide((prev) => prev - 1);
    }

    function handleDismiss() {
        settingsStore.setIntroSeen(true);
        setCurrentSlide(0);
    }

    return (
        <>
            <Modal size={'full'} isCentered isOpen={!settingsStore.introSeen} onClose={handleDismiss}>
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
                <ModalContent borderRadius={'3xl'}>
                    <ModalHeader>{intl.formatMessage({ id: 'intro.title' })}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <HStack alignItems={'start'} dir="row" minH={'100%'}>
                            <IntroStepper steps={slides} onStepClick={(step) => setCurrentSlide(step)} activeStep={currentSlide} />
                            <Box
                                backgroundColor={useColorModeValue('gray.100', 'surface.500')}
                                p={4}
                                borderRadius={'3xl'}
                                width={'100%'}
                                overflowY="auto"
                                maxH="80vh"
                            >
                                {slides[currentSlide].component}
                            </Box>
                        </HStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            isDisabled={currentSlide === 0}
                            variant={'ghost'}
                            leftIcon={<ChevronLeftIcon />}
                            colorScheme="primary"
                            onClick={handleGoToPrevious}
                        >
                            {intl.formatMessage({ id: 'attendance.preview.previous' })}
                        </Button>
                        <Button
                            isDisabled={currentSlide === slides.length - 1}
                            variant={'ghost'}
                            rightIcon={<ChevronRightIcon />}
                            colorScheme="primary"
                            onClick={handleGoToNext}
                        >
                            {intl.formatMessage({ id: 'attendance.preview.next' })}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
