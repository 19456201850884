import React, { ReactNode } from 'react';
import { Box, Container, Stack, SimpleGrid, Text, Link, useColorModeValue, Image, VisuallyHidden, chakra } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

const SocialButton = ({ children, label, href }: { children: ReactNode; label: string; href: string }) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const AboutFooter: React.FC<{ boxProps?: React.ComponentProps<typeof Box> }> = ({ boxProps }) => {
    const intl = useIntl();

    return (
        <Box {...boxProps}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={8}>
                    <Stack align={'flex-start'}>
                        <ListHeader>
                            <Text color={'gray.200'}>Legal</Text>
                        </ListHeader>
                        {/* <Link href={'#'}>Cookies Policy</Link> */}
                        <Link color={'gray.500'} as={RouterLink} to={'/policy'}>
                            {intl.formatMessage({ id: 'privacyPolicy' })}
                        </Link>
                        <Link color={'gray.500'} as={RouterLink} to={'/terms-of-use'}>
                            {intl.formatMessage({ id: 'termsAndConditions' })}
                        </Link>
                        <Link color={'gray.500'} as={RouterLink} to={'/docs/home'}>
                            {intl.formatMessage({ id: 'docs.title' })}
                        </Link>
                    </Stack>

                    <Stack align={'flex-end'}>
                        <ListHeader>
                            <Text color={'gray.200'}>{intl.formatMessage({ id: 'about.installApp' })}</Text>
                        </ListHeader>
                        <a href="https://play.google.com/store/apps/details?id=com.supervizer">
                            <Image maxW={200} src={`${process.env.PUBLIC_URL}/images/google-play-icon.svg`} alt="Google Play Store" />
                        </a>
                        <a href="https://apps.apple.com/sk/app/supervizer/id6479220108">
                            <Image maxW={200} src={`${process.env.PUBLIC_URL}/images/apple-store-icon.svg`} alt="Apple app store" />
                        </a>
                    </Stack>
                </SimpleGrid>
            </Container>

            <Box borderTopWidth={1} borderStyle={'solid'} borderColor={useColorModeValue('gray.200', 'gray.700')}>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ md: 'space-between' }}
                    align={{ md: 'center' }}
                >
                    <Text color={'gray.500'}> © Supervizer | {new Date().getFullYear()} All rights reserved. </Text>

                    <SocialButton label={'Instagram'} href={'https://www.instagram.com/supervizer_dochadzka/#'}>
                        <FaInstagram size={34} color="white" />
                    </SocialButton>
                </Container>
            </Box>
        </Box>
    );
};

export default AboutFooter;
