import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import {
    Box,
    HStack,
    Heading,
    TableContainer,
    Table,
    Tbody,
    Tr,
    Td,
    Divider,
    Thead,
    Th,
    Checkbox,
    VStack,
    Button,
    useColorModeValue,
    Text,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    useDisclosure,
    PlacementWithLogical,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { ComponentProps, ReactNode } from 'react';
import { MdEdit } from 'react-icons/md';
import { DocumentStatus } from '../../../../../types/enums/DocumentStatus';
import { DocumentStatusBadge } from '../../../../documents/DocumentStatus';
import { EditStatementName, EditStatementShiftForm, EditStatementStatus, EditStatementSuspendedHours } from './EditActions';
import { LocalAttendanceStatement, StoredPairedShift } from '../../../../../types';
import { roundNumber } from '../../../../../utils/formatUtils';
import { getBreaksDurationStoredStatement, getHoursInWorkFromStoredStatement } from '../../../../../utils/DocumentUtils';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

type StatementEditorTableProps = {
    data: LocalAttendanceStatement;
    onSelectShiftIds: (ids: string[]) => void;
    handleToggleItemSelect: (shiftId: string) => void;
    selectedShiftsIds: string[];
    isEditing: boolean;
    isLoadingUpdate: boolean;
    onShiftUpdate: (shift: StoredPairedShift, reportId?: string) => void;
    onCreateShift: (shift: StoredPairedShift) => void;
    onDeleteShift: (shiftId: string, reportId?: string) => void;
    onSuspendedHoursUpdate: (hours: number) => void;
    onIdentifierUpdate?: (identifier: string) => void;
    onStatusUpdate?: (status: DocumentStatus) => void;
} & ComponentProps<typeof Box>;

export const StatementEditorTable: React.FC<StatementEditorTableProps> = ({
    data,
    onSelectShiftIds,
    isEditing,
    isLoadingUpdate,
    selectedShiftsIds,
    handleToggleItemSelect,
    onCreateShift,
    onDeleteShift,
    onShiftUpdate,
    onSuspendedHoursUpdate,
    onIdentifierUpdate,
    onStatusUpdate,
    ...boxProps // Ovverrides box styles
}) => {
    const { t, formatDate, formatTime } = useSuIntl();

    const isItemSelected = (shiftId: string) => {
        return selectedShiftsIds.includes(shiftId);
    };

    const isAllSelected = () => {
        const countOfShifts = data?.reports.flatMap((report) => report.shifts).length || 0;
        return countOfShifts === selectedShiftsIds.length;
    };

    const formatDateField = (date?: string) => {
        return date ? formatDate(date) : '-';
    };

    const formatTimeField = (time?: string) => {
        return time ? formatTime(time) : '-';
    };

    const getBreaksDuration = () => {
        return getBreaksDurationStoredStatement(data);
    };

    const getHoursBase = () => {
        return getHoursInWorkFromStoredStatement(data);
    };

    const hoverColor = useColorModeValue('gray.200', 'gray.800');
    const onHover = useColorModeValue('gray.400', 'gray.600');
    const backgroundColor = useColorModeValue('white', 'gray.700');
    return (
        <Box
            boxShadow={'lg'}
            w={{ base: '100%', md: 400, lg: 700, xl: 960 }}
            p={4}
            backgroundColor={backgroundColor}
            borderRadius={'xl'}
            {...boxProps}
        >
            <HStack justify={'space-between'}>
                <HStack>
                    {isEditing && (
                        <ActionMenu
                            key={'add-shift'}
                            title={t('doc.name')}
                            aria-label="add shift"
                            icon={<MdEdit />}
                            colorScheme="gray"
                            size="sm"
                            variant="ghost"
                            placement="right-end"
                        >
                            {(onClose, isOpen) => (
                                <EditStatementName
                                    onClose={onClose}
                                    onSubmit={(newName) => onIdentifierUpdate?.(newName)}
                                    initialValue={data?.identifier}
                                    isLoading={isLoadingUpdate}
                                    isOpen={isOpen}
                                />
                            )}
                        </ActionMenu>
                    )}
                    <Heading size={'md'}>{data.identifier || t('attendanceStatement.title.long.singular')}</Heading>
                </HStack>
                <HStack>
                    <DocumentStatusBadge status={data?.status || DocumentStatus.DRAFT} />
                    {isEditing && (
                        <ActionMenu
                            aria-label="change status"
                            icon={<MdEdit />}
                            colorScheme="gray"
                            title={t('field.status')}
                            size="sm"
                            variant="ghost"
                            placement="left-start"
                        >
                            {(onClose, isOpen) => (
                                <EditStatementStatus
                                    onClose={onClose}
                                    onSubmit={(newStatus) => onStatusUpdate?.(newStatus)}
                                    initialValue={data.status}
                                    isLoading={isLoadingUpdate}
                                    isOpen={isOpen}
                                />
                            )}
                        </ActionMenu>
                    )}
                </HStack>
            </HStack>

            <TableContainer mt={4} fontWeight={'500'}>
                <Table width={{ base: '100%', md: 200, lg: 350 }} size={'sm'} variant="unstyled" colorScheme="gray">
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text fontSize={'md'}>{t('EMPLOYEE')}</Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'}>{data?.name}</Text>
                            </Td>
                        </Tr>
                        <Tr fontSize={'md'}>
                            <Td fontSize={'md'}>{t('dateFrom')}</Td>
                            <Td>{formatDate(data?.dateFrom)}</Td>
                        </Tr>
                        <Tr fontSize={'md'}>
                            <Td fontSize={'md'}>{t('dateTo')}</Td>
                            <Td>{formatDate(data?.dateTo)}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

            <Divider my={4} />

            <TableContainer>
                <Table size={'sm'} variant="unstyled">
                    <Thead>
                        <Tr>
                            {isEditing && (
                                <Th>
                                    <Checkbox
                                        isChecked={isAllSelected()}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                const allItems = data?.reports.flatMap((report) => report.shifts.map((shift) => shift.id));
                                                onSelectShiftIds(allItems || []);
                                            } else {
                                                onSelectShiftIds([]);
                                            }
                                        }}
                                    />
                                </Th>
                            )}
                            <Th colSpan={2}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('field.date')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('IN')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('OUT')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('attendance.break')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="none">
                                    {t('attendance.workTime')}
                                </Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.reports
                            .map((report, index) =>
                                report.shifts.map((shift, index) => (
                                    <Tr key={`${report.date}-${index}`} role="group" _hover={{ backgroundColor: hoverColor }}>
                                        {isEditing && (
                                            <Td borderLeftRadius={'xl'}>
                                                <Checkbox
                                                    _groupHover={{ borderColor: onHover }}
                                                    colorScheme="gray"
                                                    isChecked={isItemSelected(shift.id)}
                                                    onChange={() => handleToggleItemSelect(shift.id)}
                                                />
                                            </Td>
                                        )}
                                        <Td colSpan={2} py={1}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {formatDateField(report.date)}
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {formatTimeField(shift.arrival?.date)}
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {formatTimeField(shift.departure?.date)}
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {shift.breakDuration || 0}h
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {shift.hoursWorked || 0}h
                                            </Text>
                                        </Td>
                                        {isEditing && (
                                            <Td p={0} borderRightRadius={'xl'}>
                                                <HStack spacing={0} p={0}>
                                                    <ActionMenu
                                                        key={'edit-shift' + shift.id}
                                                        aria-label={t('attendance.editShift')}
                                                        icon={<MdEdit />}
                                                        variant={'ghost'}
                                                        title={t('attendance.editShift')}
                                                        colorScheme="green"
                                                    >
                                                        {(onClose, isOpen) => (
                                                            <EditStatementShiftForm
                                                                shift={shift}
                                                                onSubmit={(data) => onShiftUpdate(data, report.id)}
                                                                onCancel={onClose}
                                                                isOpen={isOpen}
                                                                isLoading={isLoadingUpdate}
                                                                minDate={
                                                                    data?.dateFrom
                                                                        ? moment(data.dateFrom).subtract(1, 'day').toDate()
                                                                        : undefined
                                                                }
                                                                maxDate={data?.dateTo ? new Date(data.dateTo) : undefined}
                                                            />
                                                        )}
                                                    </ActionMenu>
                                                    <ActionMenu
                                                        key={'delete-shift' + shift.id}
                                                        aria-label={t('attendance.deleteShift')}
                                                        icon={<DeleteIcon />}
                                                        variant={'ghost'}
                                                        colorScheme="red"
                                                        title={t('attendance.deleteShift')}
                                                        closeOnBlur={true}
                                                    >
                                                        {(onClose, _isOpen) => (
                                                            <VStack align={'start'}>
                                                                <Text fontSize={'md'} fontWeight={'500'}>
                                                                    {t('deleteDataText')}
                                                                </Text>
                                                                <HStack>
                                                                    <Button
                                                                        onClick={() => onDeleteShift(shift.id, report.id)}
                                                                        colorScheme="red"
                                                                    >
                                                                        {t('delete')}
                                                                    </Button>
                                                                    <Button variant={'ghost'} onClick={onClose}>
                                                                        {t('cancel')}
                                                                    </Button>
                                                                </HStack>
                                                            </VStack>
                                                        )}
                                                    </ActionMenu>
                                                </HStack>
                                            </Td>
                                        )}
                                    </Tr>
                                ))
                            )
                            .flat() || null}
                        {isEditing && (
                            <Tr>
                                <Td colSpan={8}>
                                    <HStack justify={'right'}>
                                        <ActionMenu
                                            key={'add-shift'}
                                            aria-label={t('attendance.addShift')}
                                            icon={<AddIcon />}
                                            colorScheme="green"
                                            title={t('attendance.addShift')}
                                            size="sm"
                                        >
                                            {(onClose, isOpen) => (
                                                <EditStatementShiftForm
                                                    onSubmit={(data) => onCreateShift(data)}
                                                    onCancel={onClose}
                                                    isOpen={isOpen}
                                                    isLoading={isLoadingUpdate}
                                                    minDate={data?.dateFrom ? moment(data.dateFrom).subtract(1, 'day').toDate() : undefined}
                                                    maxDate={data?.dateTo ? new Date(data.dateTo) : undefined}
                                                />
                                            )}
                                        </ActionMenu>
                                    </HStack>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>

            <Divider my={4} />
            <Heading size={'sm'} ml={4}>
                {t('doc.total')}
            </Heading>
            <TableContainer mt={4} fontWeight={'500'}>
                <Table size={'sm'} variant="unstyled">
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text> {t('attendance.daysInWork')}</Text>
                            </Td>
                            <Td>
                                <Text>{data?.reports?.length}</Text>
                            </Td>
                            <Td>
                                <Text>{t('attendance.breaks')}</Text>
                            </Td>
                            <Td>
                                <Text>{getBreaksDuration()}h</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>{t('attendance.timeInWork')}</Td>
                            <Td>{getHoursBase()}h</Td>

                            <Td> {t('attendance.suspendedTime')}</Td>

                            <Td>
                                {data?.suspendedHours ?? 0}h
                                {isEditing && (
                                    <ActionMenu
                                        key={'add-shift'}
                                        aria-label="add shift"
                                        icon={<MdEdit />}
                                        colorScheme="gray"
                                        size="sm"
                                        variant="ghost"
                                    >
                                        {(onClose, isOpen) => (
                                            <EditStatementSuspendedHours
                                                onClose={onClose}
                                                onSubmit={(value) => onSuspendedHoursUpdate(value)}
                                                initialValue={data?.suspendedHours}
                                                isLoading={isLoadingUpdate}
                                                isOpen={isOpen}
                                                maxHours={data?.totalHours}
                                            />
                                        )}
                                    </ActionMenu>
                                )}
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <HStack justify={'space-between'} m={8} borderRadius={'xl'} borderWidth={1} p={4} borderColor={'gray.300'}>
                <Text fontSize={'lg'} fontWeight={'bold'}>
                    {t('attendance.timeWorked')}
                </Text>
                <Text fontWeight={'bold'} fontSize={'lg'}>
                    {data?.totalHours ? roundNumber(data.totalHours) : 0}h
                </Text>
            </HStack>
        </Box>
    );
};

type ActionMenuProps = {
    'icon': ReactNode;
    'variant'?: string;
    'aria-label': string;
    'title'?: string;
    'children': (onClose: VoidFunction, isOpen: boolean) => React.ReactNode;
    'colorScheme'?: string;
    'closeOnBlur'?: boolean;
    'size'?: string;
    'placement'?: PlacementWithLogical;
};

const ActionMenu: React.FC<ActionMenuProps> = ({ children, title, closeOnBlur = false, placement = 'left', ...buttonProps }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    return (
        <Popover placement={placement} isOpen={isOpen} onClose={onClose} onOpen={onOpen} closeOnBlur={closeOnBlur}>
            <PopoverTrigger>
                <IconButton {...(buttonProps as any)}></IconButton>
            </PopoverTrigger>
            <Portal>
                <PopoverContent minW={'fit-content'} p={4} borderRadius={'xl'}>
                    <PopoverArrow />
                    <PopoverHeader fontWeight={'bold'}>{title}</PopoverHeader>
                    <PopoverCloseButton />

                    <PopoverBody>{children(onClose, isOpen)}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
