import { Avatar, Box, useTheme, VStack, Text, HStack, Radio } from '@chakra-ui/react';
import { Role, User } from '../../types';
import { useIntl } from 'react-intl';
import { useGetEmployeesPagination } from '../../query/resource-hooks/company';
import SearchInput from '../../components/ui/SearchInput';
import { useState } from 'react';
import InfiniteSelectList from '../../components/lists/InfiniteSelectList';

const EmployeeSelectListItem: React.FC<{ user: User; onClick?: () => void; isSelected?: boolean }> = ({ user, onClick, isSelected }) => {
    const intl = useIntl();
    const theme = useTheme();
    return (
        <Radio
            _hover={{
                bg: theme.colors.primary[500],
                color: 'white',
            }}
            style={isSelected ? { backgroundColor: theme.colors.primary[500], color: 'white' } : {}}
            padding={1}
            role="group"
            cursor="pointer"
            borderRadius="lg"
            onClick={onClick}
            value={user.id.toString()}
        >
            <HStack>
                <Avatar name={user.surname || user.firstName} />
                <VStack align={'start'} spacing={0}>
                    <Text fontWeight={'600'}>{`${user?.surname || ''} ${user.firstName}`}</Text>
                    <Text fontSize={'small'}>{user.role ? intl.formatMessage({ id: user.role }) : '-'}</Text>
                </VStack>
            </HStack>
        </Radio>
    );
};

export type EmployeeSelectListProps = {
    onSelect?: (user: User) => void;
    selectedUserId?: number;
};

const EmployeesSelectList: React.FC<EmployeeSelectListProps> = ({ onSelect, selectedUserId }) => {
    const [search, setSearch] = useState('');

    const employeesQuery = useGetEmployeesPagination({ search: search, roles: [Role.ADMIN, Role.EMPLOYEE, Role.OWNER, Role.SUPER_OWNER] });

    return (
        <Box textAlign="center">
            <Box>
                <SearchInput variant={'filled'} onSearch={setSearch} />
            </Box>
            <Box p={2}>
                <InfiniteSelectList<User>
                    onSelect={onSelect}
                    data={employeesQuery.data}
                    fetchNextPage={employeesQuery.fetchNextPage}
                    hasNextPage={employeesQuery.hasNextPage}
                    isFetchingNextPage={employeesQuery.isFetchingNextPage}
                    isLoading={employeesQuery.isLoading || employeesQuery.isRefetching}
                    renderItem={(user, index) => <EmployeeSelectListItem isSelected={selectedUserId === user.id} key={index} user={user} />}
                />
            </Box>
        </Box>
    );
};

export default EmployeesSelectList;
