import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, IconButton, useMediaQuery } from '@chakra-ui/react';
import { Fit, Layout, useRive } from '@rive-app/react-canvas';
import React from 'react';
import { useIntl } from 'react-intl';

const Content: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    const intl = useIntl();

    const { rive, RiveComponent } = useRive({
        src: `${process.env.PUBLIC_URL}/animations/add_item.riv`,
        stateMachines: 'add_item_flow',
        artboard: isMobile ? 'attendance_list_mobile' : 'attendance_list',
        autoplay: true,
        shouldDisableRiveListeners: isMobile,
        layout: new Layout({
            fit: Fit.Contain,
        }),
    });

    rive?.setTextRunValue('subtitle_value', intl.formatMessage({ id: 'about.knowledge' }));

    return (
        <Box h={{ base: '750', md: '400', lg: '700', xl: '800' }} justifyItems={'center'} p={5} mt={{ base: -130, md: -180, lg: -250 }}>
            <IconButton
                icon={<ChevronDownIcon boxSize={10} color={'surface.500'} />}
                aria-label="scroll down"
                mb={-4}
                zIndex={1}
                colorScheme='primaryAbout'
                onClick={() => {
                    const box = document.getElementById('scroll-target');
                    if (box) {
                        box.scrollIntoView({ behavior: 'smooth' });
                    }
                }}
            />

            <Box
                id="scroll-target"
                backdropFilter={{ base: 'blur(10px)', md: 'blur(20px)' }}
                backgroundColor={'rgba(29, 27, 32, 0.4)'}
                height={'100%'}
                maxW={{ base: '100%', md: '1300' }}
                margin={'auto'}
                borderRadius={50}
                pt={5}
            >
                <RiveComponent />
            </Box>
        </Box>
    );
};

const AnimatedIntro: React.FC = () => {
    const [isMobile] = useMediaQuery('(max-width: 650px)');

    return isMobile ? (
        <Content key={'mobile_animated_intro'} isMobile={isMobile} />
    ) : (
        <Content key={'animated_intro'} isMobile={isMobile} />
    );
};

export default AnimatedIntro;
