import { useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import Turnstile from 'react-turnstile';

type TurnstileWidgetProps = {
    onVerify: (token: string) => void;
};

export const TurnstileWidget: React.FC<TurnstileWidgetProps> = ({ onVerify }) => {
    return (
        <Turnstile
            sitekey="0x4AAAAAAA-SagaAw1D4Ja4c"
            onVerify={onVerify}
            theme={useColorModeValue('light', 'dark')}
        />
    );
};
