import { Box, Collapse, Icon, Stack, Text, Tooltip, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { useSuToast } from '../../hooks/useSuToast';
import { useSuIntl } from '../../hooks/useSuIntl';

type TextValueFieldProps = {
    label: string;
    value: string | number;
    icon?: React.ReactNode;
    tooltipText?: string;
    wrapperStyle?: React.CSSProperties;
    smallVariant?: boolean;
    compactView?: boolean;
    maxWidth?: string;
    canCopy?: boolean;
};

export const TextValueField: React.FC<TextValueFieldProps> = ({
    label,
    value,
    icon,
    tooltipText,
    wrapperStyle,
    smallVariant = false,
    maxWidth = '200px',
    compactView = false,
    canCopy,
}) => {
    const { t } = useSuIntl();
    const hoverBackground = useColorModeValue('gray.100', 'gray.700');
    const [isCompact, setIsCompact] = useState(compactView);
    const toast = useSuToast();

    useEffect(() => {
        setIsCompact(compactView);
    }, [compactView]);

    function handleCopy() {
        if (canCopy) {
            navigator.clipboard.writeText(value.toString());
        }
        toast.success(t('copiedToClipboard'));
    }

    return (
        <Tooltip label={tooltipText ?? label + ': ' + value}>
            <Stack
                direction={'row'}
                style={wrapperStyle}
                justify={'left'}
                align={'start'}
                gap={1}
                _hover={{ backgroundColor: hoverBackground }}
                borderRadius={'xl'}
                p={1}
                transition="all 0.3s ease"
            >
                <Box pt={1}>{icon}</Box>
                <VStack justify={'left'} align={'start'} gap={isCompact ? 0 : 1} transition="all 0.3s ease">
                    <Collapse animateOpacity in={!isCompact}>
                        <Text
                            align={'center'}
                            maxWidth={maxWidth}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            fontSize={smallVariant ? 'md' : 'lg'}
                            fontWeight={'600'}
                            transition="all 0.3s ease"
                        >
                            {label}
                            {canCopy && <Icon pl={1} as={MdContentCopy} onClick={handleCopy} cursor={'pointer'} />}
                        </Text>
                    </Collapse>
                    <Text
                        maxWidth={maxWidth}
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        fontWeight={isCompact ? '600' : '400'}
                        pl={isCompact ? 0 : 2}
                        fontSize={smallVariant ? (isCompact ? 'md' : 'sm') : isCompact ? 'lg' : 'md'}
                        transition="all 0.3s ease"
                    >
                        {value}
                    </Text>
                </VStack>
            </Stack>
        </Tooltip>
    );
};
