import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import { WebAppPreview } from '../components/WebAppPreview';
import { useIntl } from 'react-intl';
import { InstallAppAction } from '../../common/InstallAppAction';

export const IntroAppOverview: React.FC = () => {
    const intl = useIntl();

    return (
        <Box pl={5}>
            <Heading>{intl.formatMessage({ id: 'intro.apps.title' })}</Heading>
            <Text>{intl.formatMessage({ id: 'intro.apps.intro' })}</Text>

            <Heading pt={5} size={'md'}>
                {intl.formatMessage({ id: 'intro.apps.webApp.title' })}
            </Heading>
            <Text>{intl.formatMessage({ id: 'intro.apps.webApp.text' })}</Text>
            <WebAppPreview />

            <Heading pt={8} size={'md'}>
                {intl.formatMessage({ id: 'intro.apps.mobileApp.title' })}
            </Heading>
            <Text>{intl.formatMessage({ id: 'intro.apps.mobileApp.text' })}</Text>

            <Flex m={3} alignItems={'center'} justifyContent={'center'}>
                <InstallAppAction />
            </Flex>
        </Box>
    );
};
