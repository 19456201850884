import React, { useState } from 'react';
import { Box, Button, Card, CardBody, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import { Zone } from '../../types';
import { FaMap } from 'react-icons/fa';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ZonesSelectModal } from '../../screens/zones/ZonesSelectModal';
import { useIntl } from 'react-intl';

type ZoneSelectButtonProps = {
    onSelect: (user: Zone) => void;
    initialZone?: Zone;
    canDelete?: boolean;
    onDelete?: () => void;
};

const ZoneSelectButton: React.FC<ZoneSelectButtonProps> = ({ onSelect, initialZone, canDelete, onDelete }) => {
    const intl = useIntl();
    const modalDisclosure = useDisclosure();
    const [selectedZone, setSelectedZone] = useState<Zone | null>(initialZone || null);

    function handleSelect(zone: Zone) {
        setSelectedZone(zone);
        onSelect(zone);
    }

    function handleClear() {
        setSelectedZone(null);
        onDelete && onDelete();
    }

    return (
        <>
            <Card borderRadius={'xl'} cursor={'pointer'} onClick={modalDisclosure.onOpen} variant={'filled'} width={'100%'}>
                <CardBody>
                    <Flex alignItems={'center'}>
                        <Icon color={selectedZone ? 'green' : undefined} as={FaMap} mr={2} />
                        <Heading size={'xs'}>{selectedZone ? selectedZone.name : `${intl.formatMessage({ id: 'selectZoneWarning' })} (${intl.formatMessage({ id: 'optional' })})`}</Heading>
                        <Icon ml={'auto'} color={selectedZone ? 'green' : undefined} as={selectedZone ? CheckIcon : ChevronRightIcon} />
                    </Flex>
                </CardBody>
            </Card>
            {onDelete && selectedZone && canDelete && (
                <Box justifyContent={'flex-end'} display={'flex'}>
                    <Button size={'sm'} onClick={() => handleClear()} colorScheme="red" variant={'ghost'}>
                        {intl.formatMessage({ id: 'delete' })}
                    </Button>
                </Box>
            )}

            <ZonesSelectModal
                onSelect={handleSelect}
                selectedZoneId={selectedZone?.id}
                isOpen={modalDisclosure.isOpen}
                onClose={modalDisclosure.onClose}
            />
        </>
    );
};

export default ZoneSelectButton;
