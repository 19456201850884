import { Card, CardHeader, Text, Heading } from '@chakra-ui/react';
import React from 'react';

export const HoverCard: React.FC<{
    text: string;
    title: string;
    onMouseEnter?: VoidFunction;
    onMouseExit?: VoidFunction;
    isSelected?: boolean;
}> = ({ text, title, onMouseEnter, onMouseExit, isSelected }) => {
    const isSelectedStyle = {
        boxShadow: 'lg',
        transform: 'scale(1.05)',
        transition: 'all 0.2s',
    };

    return (
        <Card
            {...(isSelected ? isSelectedStyle : {})}
            _hover={isSelectedStyle}
            borderRadius={'2xl'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseExit}
        >
            <CardHeader pb={0}>
                <Heading size="xs">{title}</Heading>
            </CardHeader>

            <Text fontSize={'sm'} p={5}>
                {text}
            </Text>
        </Card>
    );
};
