import {
    Card,
    HStack,
    VStack,
    Text,
    Tooltip,
    Icon,
    Button,
    IconButton,
    ButtonGroup,
    useDisclosure,
    Collapse,
    Divider,
    Image,
    Skeleton,
    useToast,
    Box,
    useColorModeValue,
} from '@chakra-ui/react';
import { AttendanceLogin, AttendanceLoginDetail } from '../../../types';
import { useIntl } from 'react-intl';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { FaceRecognitionIcon, ManualAttendanceIcon, OfflineAttendanceIcon, PositionValidationIcon } from '../../lists/AttendanceTableItem';
import { CheckIcon, ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { createImageUrl } from '../../../query/apiClient';
import { TextValueField } from '../../common/TextValueField';
import { MdGpsFixed, MdPermIdentity } from 'react-icons/md';
import { AxiosError } from 'axios';
import { useDeleteAttendance } from '../../../query/resource-hooks/company';
import UserPolicy from '../../../policies/UserPolicy';
import { useAuthStore } from '../../../store/auth/authStore';
import { DeleteAlertDialog } from '../../ui/DeleteAlertDialog';
import { UpdateAttendanceModal } from '../UpdateAttendanceModal';

type PairedShiftRecordCardProps = {
    attendance?: AttendanceLoginDetail;
    onUpdateSuccess?: (attendance: AttendanceLogin) => void;
    onDeleteSuccess?: () => void;
    onUpdateError?: (error: AxiosError) => void;
    onDeleteError?: (error: AxiosError) => void;
};

export const PairedShiftRecordCard: React.FC<PairedShiftRecordCardProps> = ({
    attendance,
    onUpdateError,
    onUpdateSuccess,
    onDeleteError,
    onDeleteSuccess,
}) => {
    const intl = useIntl();
    const deleteAttendance = useDeleteAttendance(handleDeleteSuccess, handleDeleteError);
    const updateRecordDisclosure = useDisclosure();
    const deleteAlertDisclosure = useDisclosure();
    const toast = useToast();
    const loggedUser = useAuthStore((state) => state.user);
    const { isOpen: isExpanded, onToggle: onToggleExpande } = useDisclosure();

    const handleInsideClick = (e: React.MouseEvent, callback?: VoidFunction) => {
        e.stopPropagation();
        callback?.();
    };

    function handleDeleteSuccess() {
        toast({
            title: intl.formatMessage({ id: 'data.deleted' }),
            status: 'success',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        onDeleteSuccess?.();
    }

    function handleDeleteError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        onDeleteError?.(error);
    }

    function handleDeleteAttendance() {
        deleteAttendance.mutate(attendance?.id!);
        deleteAlertDisclosure.onClose();
    }

    return (
        <>
            <Card onClick={onToggleExpande} borderRadius={'2xl'} p={3} boxShadow={'md'} w={'100%'}>
                <HStack justify={'space-between'} h={'100%'}>
                    <VStack gap={1} align={'start'}>
                        <HStack
                            width={'100%'}
                            maxW={150}
                            backgroundColor={useColorModeValue('gray.200', 'gray.800')}
                            borderRadius={'lg'}
                            p={1}
                            gap={4}
                            fontSize={'medium'}
                            fontWeight={'bolder'}
                        >
                            <Text color="primary.500">{intl.formatTime(attendance?.createdAt)}</Text>
                            <Text>{intl.formatDate(attendance?.createdAt)}</Text>
                        </HStack>
                        <HStack gap={4}>
                            <Tooltip label={intl.formatMessage({ id: attendance?.direction })}>
                                <Box>
                                    <Icon
                                        as={attendance?.direction === 'IN' ? FiLogIn : FiLogOut}
                                        boxSize="10"
                                        color={attendance?.direction === 'IN' ? 'green' : 'red'}
                                    />
                                </Box>
                            </Tooltip>
                            <VStack gap={0}>
                                <Text fontSize={'medium'} fontWeight={'500'}>
                                    {attendance?.zone?.name || intl.formatMessage({ id: 'attendance.noZone' })}
                                </Text>
                                <HStack pt={2}>
                                    <PositionValidationIcon item={attendance!} />
                                    <FaceRecognitionIcon item={attendance!} />
                                    <ManualAttendanceIcon creatorName={attendance?.creator?.fullName} item={attendance!} />
                                    <OfflineAttendanceIcon item={attendance!} />
                                </HStack>
                            </VStack>
                        </HStack>
                    </VStack>
                    <HStack height={'100%'} justify={'space-between'}>
                        <VStack height={'100%'} flex={1} gap={6}>
                            <Text fontSize={'large'} fontWeight={'600'}>
                                {attendance?.user?.fullName}
                            </Text>
                            <ButtonGroup>
                                <Button onClick={(e) => handleInsideClick(e, updateRecordDisclosure.onOpen)} leftIcon={<EditIcon />}>
                                    {intl.formatMessage({ id: 'edit' })}
                                </Button>
                                <IconButton
                                    isDisabled={!UserPolicy.canEditAttendance(loggedUser!, attendance!)}
                                    onClick={(e) => handleInsideClick(e, deleteAlertDisclosure.onOpen)}
                                    colorScheme="red"
                                    aria-label="delete"
                                    icon={<DeleteIcon />}
                                />
                            </ButtonGroup>
                        </VStack>

                        <IconButton
                            variant={'ghost'}
                            aria-label="expand"
                            onClick={onToggleExpande}
                            icon={
                                <ChevronDownIcon
                                    style={{
                                        transition: 'transform 0.3s ease',
                                        transform: isExpanded ? 'rotate(180deg)' : undefined,
                                    }}
                                    boxSize={7}
                                />
                            }
                        />
                    </HStack>
                </HStack>
                <Collapse in={isExpanded} animateOpacity>
                    <Divider my={2} />
                    {isExpanded && (
                        <HStack>
                            {attendance?.loginImageUrl && (
                                <Image
                                    src={createImageUrl(attendance?.loginImageUrl)}
                                    alt="Attendance Photo"
                                    backgroundColor={'gray.200'}
                                    borderWidth={10}
                                    border={'solid'}
                                    borderRadius={'xl'}
                                    borderColor={'gray.500'}
                                    zIndex={1000}
                                    h={{ base: 150, md: 250 }}
                                    w={{ base: 150, md: 250 }}
                                    aspectRatio={1}
                                    objectFit={'cover'}
                                    left={{ 'base': '50%', 'sm': '50%', 'md': '23%', 'lg': '18%', 'xl': '15%', '2xl': '14%' }}
                                    fallback={
                                        <Skeleton
                                            borderWidth={10}
                                            border={'solid'}
                                            borderRadius={'xl'}
                                            borderColor={'gray.500'}
                                            zIndex={1000}
                                            h={{ base: 150, md: 250 }}
                                            w={{ base: 150, md: 250 }}
                                            aspectRatio={1}
                                        />
                                    }
                                />
                            )}
                            <VStack align={'start'} gap={1}>
                                <TextValueField
                                    smallVariant
                                    label={intl.formatMessage({ id: attendance?.manual ? 'attendance.manual' : 'createdBy' })}
                                    value={attendance?.creator?.fullName || '-'}
                                    icon={<CheckIcon />}
                                />
                                {attendance?.approver && (
                                    <TextValueField
                                        smallVariant
                                        label={intl.formatMessage({ id: 'field.approvedBy' })}
                                        value={attendance?.approver?.fullName || '-'}
                                        icon={<CheckIcon />}
                                    />
                                )}
                                <TextValueField
                                    smallVariant
                                    label={intl.formatMessage({ id: 'positionValidation' })}
                                    value={intl.formatMessage({ id: `POSITION_${attendance?.positionValidation}` })}
                                    icon={<MdGpsFixed />}
                                />
                                <TextValueField
                                    smallVariant
                                    label={intl.formatMessage({ id: 'faceRecognition' })}
                                    value={intl.formatMessage({ id: `FACE_${attendance?.faceValidation}` })}
                                    icon={<MdPermIdentity />}
                                />
                                <TextValueField smallVariant label="Poznámka" value={attendance?.note || '-'} icon={<EditIcon />} />
                            </VStack>
                        </HStack>
                    )}
                </Collapse>
            </Card>
            <UpdateAttendanceModal
                onSuccess={onUpdateSuccess}
                onError={onUpdateError}
                isOpen={updateRecordDisclosure.isOpen}
                onClose={updateRecordDisclosure.onClose}
                updatedRecord={attendance}
            />
            <DeleteAlertDialog
                isLoading={deleteAttendance.isLoading}
                isOpen={deleteAlertDisclosure.isOpen}
                onClose={deleteAlertDisclosure.onClose}
                onConfirm={handleDeleteAttendance}
            />
        </>
    );
};
