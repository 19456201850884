import { Box, HStack, Icon, Radio, Text, useTheme } from '@chakra-ui/react';
import { useGetZonesPagination } from '../../query/resource-hooks/company';
import { Zone } from '../../types';
import { FiMap } from 'react-icons/fi';
import InfiniteSelectList from '../../components/lists/InfiniteSelectList';
import { useState } from 'react';
import SearchInput from '../../components/ui/SearchInput';

interface ZoneListItemProps {
    zone: Zone;
    onClick?: () => void;
}

const ZoneSelectListItem: React.FC<ZoneListItemProps> = ({ zone, onClick }) => {
    const theme = useTheme();

    return (
        <Radio
            onClick={onClick}
            _hover={{
                bg: theme.colors.primary[500],
                color: 'white',
            }}
            cursor={'pointer'}
            borderRadius={'lg'}
            value={zone.id.toString()}
        >
            <HStack p={1}>
                <Icon as={FiMap} color="green.500" />
                <Text fontWeight={600} fontSize={'md'}>
                    {zone.name}
                </Text>
            </HStack>
        </Radio>
    );
};

export type ZonesSelectListProps = {
    onSelect?: (zone: Zone) => void;
    selectedZoneId?: number;
};

const ZonesSelectList: React.FC<ZonesSelectListProps> = ({ onSelect }) => {
    const [search, setSearch] = useState('');

    const zonesQuery = useGetZonesPagination({ search: search });

    return (
        <Box textAlign="center">
            <Box>
                <SearchInput variant={'filled'} onSearch={setSearch} />
            </Box>
            <Box p={2}>
                <InfiniteSelectList<Zone>
                    data={zonesQuery.data}
                    fetchNextPage={zonesQuery.fetchNextPage}
                    hasNextPage={zonesQuery.hasNextPage}
                    isFetchingNextPage={zonesQuery.isFetchingNextPage}
                    renderItem={(zone, index) => <ZoneSelectListItem zone={zone} key={index} />}
                    isLoading={zonesQuery.isLoading || zonesQuery.isRefetching}
                    onSelect={onSelect}
                />
            </Box>
        </Box>
    );
};

export default ZonesSelectList;
