import {
    Button,
    Card,
    CardBody,
    Center,
    Flex,
    Heading,
    Icon,
    IconButton,
    Image,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    Tooltip,
    useColorModeValue,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { Role, User } from '../../types';
import { useIntl } from 'react-intl';
import { createImageUrl } from '../../query/apiClient';
import { ComponentProps } from 'react';
import { TextValueField } from '../common/TextValueField';
import { MdEdit, MdEmail, MdGroupAdd, MdGroups3, MdMap, MdOutlineCameraAlt, MdPerson, MdPhone, MdWifiOff } from 'react-icons/md';
import { EmployeeBusinessIdentityForm } from './EmployeeBusinessIdentityForm';
import { AxiosError } from 'axios';
import { TeamAccountSettingsForm } from './TeamAccountSettingsForm';

type ProfileCardProps = ComponentProps<typeof Card> & {
    user?: User;
    isLoading?: boolean;
    onPhotoUpdate?: () => void;
    onUpdate?: () => void;
    onError?: (error: AxiosError) => void;
    actions?: {
        onClick: () => void;
        icon: React.ReactElement;
        label: string;
        colorScheme?: string;
        variant?: string;
        show: boolean;
        isDisabled?: boolean;
    }[];
};

export const NewUserProfileCard: React.FC<ProfileCardProps> = ({
    user,
    onPhotoUpdate,
    isLoading,
    onUpdate,
    onError,
    actions,
    ...cardProps
}) => {
    const intl = useIntl();

    const businessIdentityDisclosure = useDisclosure();
    const teamAccountSettingsDisclosure = useDisclosure();

    const iconColor = useColorModeValue('primary.700', 'primary.200');
    const elevationColor = useColorModeValue('gray.200', 'gray.800');

    const filteredActions = actions?.filter((action) => action.show);

    if (isLoading) {
        return (
            <Card borderRadius={'2xl'} boxShadow={'md'} {...cardProps}>
                <CardBody>
                    <Stack direction={{ base: 'column', md: 'row' }}>
                        <Skeleton height={300} width={300} borderRadius={'xl'} />
                        <VStack>
                            <Skeleton height={10} minW={300} width={'100%'} borderRadius={'xl'} />
                            <Skeleton height={10} minW={300} width={'100%'} borderRadius={'xl'} />
                            <Skeleton height={10} minW={300} width={'100%'} borderRadius={'xl'} />
                        </VStack>
                    </Stack>
                </CardBody>
            </Card>
        );
    }

    return (
        <>
            <Card borderRadius={'2xl'} boxShadow={'md'} {...cardProps}>
                <CardBody>
                    <Stack spacing={4} direction={{ base: 'column', xl: 'row' }}>
                        <Center>
                            {user?.profilePhotoUrl ? (
                                <Image
                                    borderRadius={'xl'}
                                    maxH={300}
                                    maxW={300}
                                    objectFit="cover"
                                    boxSize="100%"
                                    aspectRatio={1}
                                    src={createImageUrl(user.profilePhotoUrl)}
                                    fallback={<Skeleton height={300} width={300} borderRadius={'xl'} />}
                                />
                            ) : (
                                <Center maxH={300} maxW={300} m={4} borderRadius={'xl'}>
                                    {user?.role === Role.TEAM_ACCOUNT ? (
                                        <Icon color={iconColor} as={MdGroups3} boxSize={150} />
                                    ) : (
                                        <Icon color={iconColor} as={MdOutlineCameraAlt} boxSize={150} />
                                    )}
                                </Center>
                            )}
                        </Center>
                        <Stack direction={{ base: 'column', lg: 'row' }} justify={{ base: 'left', md: 'space-between' }} width={'100%'}>
                            <VStack align={'start'}>
                                <Flex align={{ base: 'center', md: 'start' }} width={'100%'} flexDir={{ base: 'column' }}>
                                    <Heading>{user?.fullName}</Heading>
                                    <Tooltip label={intl.formatMessage({ id: 'role' })}>
                                        <Text fontWeight={600}>{intl.formatMessage({ id: user?.role })}</Text>
                                    </Tooltip>
                                </Flex>

                                <SimpleGrid spacing={4} columns={{ base: 1, sm: 3 }}>
                                    <VStack spacing={0} align={'start'} justify={'left'}>
                                        <TextValueField
                                            canCopy
                                            smallVariant
                                            label={intl.formatMessage({ id: 'user.username' })}
                                            value={user?.userName!}
                                            icon={<MdPerson />}
                                        />
                                        <TextValueField
                                            smallVariant
                                            label={intl.formatMessage({ id: 'field.createdAt' })}
                                            value={intl.formatDate(user?.createdAt!)}
                                            icon={<MdGroupAdd />}
                                        />
                                        <TextValueField
                                            smallVariant
                                            label={intl.formatMessage({ id: 'field.updatedAt' })}
                                            value={intl.formatDate(user?.updatedAt!)}
                                            icon={<MdEdit />}
                                        />
                                    </VStack>
                                    <VStack spacing={0} align={'start'} justify={'left'} bg={elevationColor} borderRadius={'xl'} p={2}>
                                        <Flex justifyContent={'space-between'} align={'center'} width={'100%'}>
                                            <Heading size={'sm'} pb={2}>
                                                {intl.formatMessage({ id: 'title.contact' })}
                                            </Heading>
                                            <Tooltip label={intl.formatMessage({ id: 'contacts.update' })}>
                                                <IconButton
                                                    variant={'ghost'}
                                                    size={'sm'}
                                                    aria-label="edit terminal settings"
                                                    icon={<MdEdit />}
                                                    onClick={businessIdentityDisclosure.onOpen}
                                                />
                                            </Tooltip>
                                        </Flex>
                                        <TextValueField
                                            canCopy
                                            smallVariant
                                            label={intl.formatMessage({ id: 'contacts.phone' })}
                                            value={user?.businessIdentity?.phone || '-'}
                                            icon={<MdPhone />}
                                        />
                                        <TextValueField
                                            canCopy
                                            smallVariant
                                            label={intl.formatMessage({ id: 'contacts.email' })}
                                            value={user?.businessIdentity?.email ?? '-'}
                                            icon={<MdEmail />}
                                        />
                                    </VStack>
                                    {user?.role === Role.TEAM_ACCOUNT && (
                                        <VStack borderRadius={'xl'} p={2} bg={elevationColor} spacing={0} align={'start'} justify={'left'}>
                                            <Flex width={'100%'} justifyContent={'space-between'} align={'center'}>
                                                <Heading size={'sm'} pb={2}>
                                                    {intl.formatMessage({ id: 'teamSettings' })}
                                                </Heading>
                                                <Tooltip label={intl.formatMessage({ id: 'update' })}>
                                                    <IconButton
                                                        variant={'ghost'}
                                                        size={'sm'}
                                                        aria-label="edit terminal settings"
                                                        icon={<MdEdit />}
                                                        onClick={teamAccountSettingsDisclosure.onOpen}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                            <TextValueField
                                                smallVariant
                                                label={intl.formatMessage({ id: 'defaultZone' })}
                                                value={user?.settings?.defaultZone?.name || '-'}
                                                icon={<MdMap />}
                                            />
                                            <TextValueField
                                                smallVariant
                                                label={intl.formatMessage({ id: 'attendance.enableOfflineAttendance' })}
                                                value={intl.formatMessage({ id: user?.settings?.offlineAttendance ? 'yes' : 'no' })}
                                                icon={<MdWifiOff />}
                                            />
                                        </VStack>
                                    )}
                                </SimpleGrid>
                            </VStack>
                            <Stack
                                minW={{ base: 'fit-content', lg: '180' }}
                                key={'actions'}
                                justify={{ base: 'center', md: 'end' }}
                                direction={{ base: 'column' }}
                                spacing={2}
                                ml={{ base: 0, md: 4 }}
                            >
                                {filteredActions?.map((action) => (
                                    <Button
                                        key={action.label}
                                        onClick={action.onClick}
                                        leftIcon={action.icon}
                                        colorScheme={action.colorScheme}
                                        variant={action.variant}
                                        isDisabled={action.isDisabled}
                                    >
                                        {action.label}
                                    </Button>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                </CardBody>
            </Card>
            <EmployeeBusinessIdentityForm
                isOpen={businessIdentityDisclosure.isOpen}
                onClose={businessIdentityDisclosure.onClose}
                updatedUser={user}
                onSuccess={onUpdate}
                onError={onError}
            />
            <TeamAccountSettingsForm
                isOpen={teamAccountSettingsDisclosure.isOpen}
                onClose={teamAccountSettingsDisclosure.onClose}
                updatedUser={user!}
                onSuccess={onUpdate}
                onError={onError}
            />
        </>
    );
};
