import { AttendanceLoginDetail, BillingAccount, Feature, Role, User } from '../types';

export const PrivilegeGroupWeightMap: Record<Role, number> = {
    [Role.SUPER_OWNER]: 10,
    [Role.OWNER]: 7,
    [Role.ADMIN]: 5,
    [Role.TEAM_ACCOUNT]: 4,
    [Role.EMPLOYEE]: 2,
};

class UserPolicy {
    public canDeleteUser(loggedUser: User, deletedUserRole: Role): boolean {
        return PrivilegeGroupWeightMap[loggedUser.role] > PrivilegeGroupWeightMap[deletedUserRole] && this.isAnyOwner(loggedUser);
    }

    public canEditUser(user?: User, editedUser?: User): boolean {
        if (!user || !editedUser) {
            return false;
        }
        
        return (
            (user.id !== editedUser.id || PrivilegeGroupWeightMap[user.role] > PrivilegeGroupWeightMap[editedUser.role]) &&
            this.isAnyAdmin(user)
        );
    }

    public canEditPhoto(user: User, editedUser: User): boolean {
        if (user.id === editedUser.id && user.role === Role.SUPER_OWNER) {
            return true;
        }

        return this.canEditUser(user, editedUser);
    }

    public canEditAttendance(user: User, editedAttendance: AttendanceLoginDetail): boolean {
        return (
            user.role === Role.SUPER_OWNER ||
            (!!editedAttendance?.user?.role &&
                PrivilegeGroupWeightMap[user.role] > PrivilegeGroupWeightMap[editedAttendance.user.role] &&
                this.isAnyOwner(user))
        );
    }

    public canCreateManualAttendance(user: User): boolean {
        return this.isAnyOwner(user);
    }

    public canCreateEmployee(user: User): boolean {
        return this.isAnyAdmin(user);
    }

    public canUpdatePassword(user: User, updatedUser: User): boolean {
        return (
            user.id === updatedUser.id ||
            (this.isAnyOwner(user) && PrivilegeGroupWeightMap[user.role] > PrivilegeGroupWeightMap[updatedUser.role])
        );
    }

    /**
     * Returns list of roles that can be assigned by logged user.
     *  - only roles with lower weight can be assigned
     */
    public assignableRoles(loggedUser: User): Role[] {
        return Object.values(Role).filter((role) => PrivilegeGroupWeightMap[loggedUser.role] > PrivilegeGroupWeightMap[role]);
    }

    public canUpdateRole(loggedUser: User, updatedUser: User): boolean {
        return loggedUser.id !== updatedUser.id && PrivilegeGroupWeightMap[loggedUser.role] > PrivilegeGroupWeightMap[updatedUser.role];
    }

    public canSeeEmployees(loggedUser: User): boolean {
        return this.isAnyAdmin(loggedUser);
    }

    public canInvite(loggedUser: User): boolean {
        return this.isAnyAdmin(loggedUser);
    }

    public hasMinimalRole(loggedUser: User, minimalRole: Role): boolean {
        return PrivilegeGroupWeightMap[loggedUser.role] >= PrivilegeGroupWeightMap[minimalRole];
    }

    public hasFeature(billieAccount: BillingAccount, feature: Feature) {
        return billieAccount.price?.features?.map((feature) => feature.name).includes(feature);
    }

    public canUpdateCompany(loggedUser: User): boolean {
        return this.isAnyOwner(loggedUser);
    }

    public isAnyOwner(user: User): boolean {
        return PrivilegeGroupWeightMap[user.role] >= PrivilegeGroupWeightMap[Role.OWNER];
    }

    public isAnyAdmin(user: User): boolean {
        return PrivilegeGroupWeightMap[user.role] >= PrivilegeGroupWeightMap[Role.ADMIN];
    }

    public canRestoreUser(user: User, restoredUser?: User) {
        return (
            [Role.OWNER, Role.SUPER_OWNER].includes(user.role) &&
            restoredUser &&
            PrivilegeGroupWeightMap[user.role] > PrivilegeGroupWeightMap[restoredUser?.role]
        );
    }

    public canApproveAttendance(user: User, attendance: AttendanceLoginDetail) {
        if (attendance?.approver) {
            return false;
        }

        return (
            user.role === Role.SUPER_OWNER ||
            (!!attendance?.user?.role &&
                PrivilegeGroupWeightMap[user.role] > PrivilegeGroupWeightMap[attendance.user.role] &&
                this.isAnyOwner(user))
        );
    }

}

export default new UserPolicy();
