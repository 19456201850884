import {
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    Box,
    StepTitle,
    StepDescription,
    StepSeparator,
    useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

// const steps: { title: string; description?: string }[] = [
//     { title: 'Vitajte' },
//     { title: 'Aplikácia', description: 'Úvod' },
//     { title: 'Third', description: 'Select Rooms' },
// ];

export const IntroStepper: React.FC<{steps: { title: string; description?: string }[], activeStep: number; onStepClick: (step: number) => void }> = ({ onStepClick, activeStep, steps }) => {



    return (
        <Box display={{base: 'none', md: 'block'}} backgroundColor={useColorModeValue('gray.100', 'surface.500')} p={4} borderRadius={'3xl'}>
            <Stepper index={activeStep} orientation="vertical" height="400px" gap="0">
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => onStepClick(index)} style={{ cursor: 'pointer' }}>
                        <StepIndicator>
                            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                        </StepIndicator>

                        <Box flexShrink="0">
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};
