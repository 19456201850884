import { Box, Card, CardBody, CardHeader, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';

export const IntroQuickStart: React.FC = () => {
    const intl = useIntl();
    return (
        <Box pl={5}>
            <Heading>{intl.formatMessage({ id: 'intro.quickStart.title' })}</Heading>
            <Text>{intl.formatMessage({ id: 'intro.quickStart.text' })}</Text>

            <VStack alignItems={'start'} gap={5} mt={5}>
                <Card borderRadius={'2xl'}>
                    <CardHeader>
                        <Heading size={'md'}>1. {intl.formatMessage({ id: 'intro.quickStart.users.title' })}</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>
                        {intl.formatMessage({ id: 'intro.quickStart.users.text' })}
                        </Text>
                    </CardBody>
                </Card>
                <Card borderRadius={'2xl'}>
                    <CardHeader>
                        <Heading size={'md'}>2. {intl.formatMessage({ id: 'intro.quickStart.zones.title' })}</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>{intl.formatMessage({ id: 'intro.quickStart.zones.text' })}</Text>
                    </CardBody>
                </Card>
                <Card borderRadius={'2xl'}>
                    <CardHeader>
                        <Heading size={'md'}>3. {intl.formatMessage({ id: 'intro.quickStart.teamAccount.title' })}</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>
                        {intl.formatMessage({ id: 'intro.quickStart.teamAccount.text' })}
                        </Text>
                    </CardBody>
                </Card>
            </VStack>
        </Box>
    );
};
