import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useSuIntl } from './useSuIntl';

type UseAlertDialogReturn = {
    showDialog: (onConfirm: () => void) => void;
    Dialog: React.FC;
};

export const useAlertDialog = (
    confirmText: string,
    cancelText: string,
    title: string,
    text: string,
    isLoading?: boolean
): UseAlertDialogReturn => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [onConfirmCallback, setOnConfirmCallback] = useState<(() => void) | null>(null);
    const cancelRef = useRef<HTMLButtonElement | null>(null);

    const showDialog = (onConfirm: () => void) => {
        setOnConfirmCallback(() => onConfirm);
        onOpen();
    };

    const Dialog: React.FC = () => (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent borderRadius={'2xl'}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {title}
                        </AlertDialogHeader>

                        <AlertDialogBody>{text}</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button isDisabled={isLoading} ref={cancelRef} onClick={onClose}>
                                {cancelText}
                            </Button>
                            <Button
                                isDisabled={isLoading}
                                isLoading={isLoading}
                                colorScheme="red"
                                onClick={() => onConfirmCallback?.()}
                                ml={3}
                            >
                                {confirmText}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );

    return { showDialog, Dialog };
};

export const useArchiveDialog = (isLoading?: boolean): UseAlertDialogReturn => {
    const { t } = useSuIntl();
    const alertDialog = useAlertDialog(t('editor.moveToArchive'), t('cancel'), t('archiveDataTitle'), t('archiveDataText'), isLoading);

    return {
        ...alertDialog,
    };
};


export const useDeleteDialog = (isLoading?: boolean): UseAlertDialogReturn => {
    const { t } = useSuIntl();
    const alertDialog = useAlertDialog(t('delete'), t('cancel'), t('deleteDataTitle'), t('deleteDataText'), isLoading);

    return {
        ...alertDialog,
    };
}