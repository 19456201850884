import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth/authStore';
import { useStoreLocalReport } from '../indexedDb/useStoreLocalReport';
import { useGetAttendanceStatementDetail, useUpdateAttendanceStatement } from '../resource-hooks/attendanceStatement';
import { db } from '../indexedDb/db';
import { useDeleteAttendanceStatementCombined } from './useDeleteAttendanceStatementCombined';
import { DocumentStatus } from '../../types/enums/DocumentStatus';

export const useUploadedAttendanceStatement = (id: number) => {
    const [isStoring, setIsStoring] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const navigate = useNavigate();

    const { data, isLoading } = useGetAttendanceStatementDetail(id);
    const loggedUser = useAuthStore((s) => s.user);
    const updateMutation = useUpdateAttendanceStatement();
    const useDeleteCombined = useDeleteAttendanceStatementCombined();

    const useStoreLocal = useStoreLocalReport();

    const startLocalUpdate = async () => {
        if (!data) {
            console.error('No data to work with');
            return;
        }
        setIsStoring(true);
        try {
            const existingRecord = await db.attendanceStatements.where('externalId').equals(data.id).first();
            if (existingRecord) {
                console.log('Record already exists in local db');
                setIsStoring(false);
                navigate(`/attendance/statement/loc/${existingRecord.id}`, { replace: true, state: { from: 'uploaded' } });
                return;
            }

            const newLocalId = await useStoreLocal.storeLocalCopy(data, loggedUser!);
            setIsStoring(false);
            navigate(`/attendance/statement/loc/${newLocalId}`, { replace: true, state: { from: 'uploaded' } });
            return;
        } catch (error) {
            console.error('Error storing report', error);
            setIsStoring(false);
            throw error;
        }
    };

    const deleteUploadedStatement = async () => {
        if (!data) {
            console.error('No data to work with');
            return;
        }

        setIsDeleting(true);

        try {
            await useDeleteCombined.deleteCombinedStatement({
                localId: undefined,
                externalId: data.id,
            });
            setIsDeleting(false);
            navigate('/attendance?tab=1', { replace: true });
        } catch (error) {
            console.error('Error deleting report', error);
            setIsDeleting(false);
            throw error;
        }
    };

    const updateStatus = async (status: DocumentStatus) => {
        if (!data) {
            console.error('No data to work with');
            return;
        }

        try {
            await updateMutation.mutateAsync({ id: data.id, data: { status } });
        } catch (error) {
            console.error('Error updating status', error);
            throw error;
        }
    };

    return {
        isLoading,
        data,
        isStoring,
        startLocalUpdate,
        isDeleting,
        deleteUploadedStatement,
        updateStatus,
    };
};
