import { Box, Card, Heading, Image, useColorModeValue, Text, VStack, Button, useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Role, StripeSubscriptionStatus, User } from '../../types';
import { LinkIcon } from '@chakra-ui/icons';
import { useCreateBillingPortalSession, useLogout, useRenewSubscription } from '../../query/resource-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';

export const SubscriptionInvalid: React.FC<{ loggedUser: User }> = ({ loggedUser }) => {
    const intl = useIntl();
    const toast = useToast();
    const createBillingPortalSession = useCreateBillingPortalSession();
    const renewSubscription = useRenewSubscription(handleSubscriptionResetSuccess, (_error) => {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            description: intl.formatMessage({ id: 'billing.setup.error.contactUs' }),
            status: 'error',
            duration: 10000,
            isClosable: true,
        });
    });
    const navigation = useNavigate();

    const logoutMutation = useLogout(() => navigation('/login'));

    const handleRedirectToBilling = async () => {
        try {
            if (loggedUser.company?.billingAccount?.redirectUrl) {
                window.location.href = loggedUser.company.billingAccount.redirectUrl;
                return;
            }

            const result = await createBillingPortalSession.mutateAsync();
            if (result.url) {
                return (window.location.href = result.url);
            }
            throw new Error('No billing portal session');
        } catch (error) {
            toast({
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                description: intl.formatMessage({ id: 'billing.setup.error.contactUs' }),
                status: 'error',
                duration: 10000,
                isClosable: true,
            });
        }
    };

    function handleSubscriptionResetSuccess() {
        navigation('/result', {
            state: {
                title: intl.formatMessage({ id: 'billing.setup.reset.title' }),
                text: intl.formatMessage({ id: 'billing.setup.reset.text' }),
                type: 'success',
            },
        });
    }

    function handleSubscriptionReset() {
        renewSubscription.mutate();
    }

    return (
        <Box
            justifyContent={'center'}
            alignItems={'start'}
            display="flex"
            w={'100vw'}
            h={'100vh'}
            pt={4}
            px={2}
            bg={useColorModeValue('gray.100', 'gray.900')}
        >
            <Card p={4}>
                <VStack maxW={'450px'}>
                    <Box p={2} textAlign={'center'}>
                        <Heading color={'red.500'}>{intl.formatMessage({ id: 'billing.invalid.title' })}!</Heading>
                        {loggedUser?.role === Role.SUPER_OWNER ? (
                            <VStack>
                                <Text>{intl.formatMessage({ id: 'billing.setting.actionRequiredText' })}</Text>
                                {loggedUser.company?.billingAccount?.stripeStatus === StripeSubscriptionStatus.CANCELLED ? (
                                    <Button
                                        isLoading={renewSubscription.isLoading}
                                        onClick={() => handleSubscriptionReset()}
                                        leftIcon={<LinkIcon />}
                                    >
                                        {intl.formatMessage({ id: 'billing.renew' })}
                                    </Button>
                                ) : (
                                    <Button
                                        isLoading={createBillingPortalSession.isLoading}
                                        onClick={() => handleRedirectToBilling()}
                                        leftIcon={<LinkIcon />}
                                    >
                                        {intl.formatMessage({ id: 'billing.settings' })}
                                    </Button>
                                )}
                            </VStack>
                        ) : (
                            <Text>{intl.formatMessage({ id: 'billing.invalid.text' })}</Text>
                        )}
                    </Box>
                    <Box p={2} alignItems={'center'} display={'flex'} justifyContent={'center'}>
                        <Image maxW={{ base: 280, md: 380 }} src="images/StripePayment.svg"></Image>
                    </Box>
                </VStack>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Button as={Link} to={'/docs/subscription'} variant={'link'}>
                        {intl.formatMessage({ id: 'docs.help' })}
                    </Button>
                    <Button colorScheme="red" onClick={() => logoutMutation.mutate()} variant={'link'}>
                        {intl.formatMessage({ id: 'logOut' })}
                    </Button>
                </Box>
            </Card>
        </Box>
    );
};
