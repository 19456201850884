import { Button, Flex, Table, TableContainer, Tbody, Td, Tfoot, Tr, Text, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { AttendanceStatementListItemData } from '../../../../../query/indexedDb/useLocalAttendanceStatementList';
import './StatementComponents.css';
import { useIntl } from 'react-intl';

type StatementTableProps = {
    data?: AttendanceStatementListItemData[];
    hasNextPage: boolean;
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
    renderItem: (item: AttendanceStatementListItemData) => JSX.Element;
    isLoading?: boolean;
};

export const StatementTable: React.FC<StatementTableProps> = ({
    data,
    renderItem,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
}) => {
    const intl = useIntl();

    return (
        <TableContainer>
            <Table className="report_table" variant={'unstyled'} size={'sm'} overflow={'hidden'}>
                <Tbody>
                    {data?.map((report) => renderItem(report))}

                    {!data &&
                        isLoading &&
                        Array.from({ length: 2 }).map((_, index) => (
                            <Tr key={index}>
                                <Td colSpan={3}>
                                    <Flex>
                                        <Skeleton borderRadius={'lg'} height={12} width={'100%'} />
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
                <Tfoot>
                    {hasNextPage && (
                        <Tr>
                            <Td colSpan={3}>
                                <Flex p={2} width={'full'} alignItems={'center'} justifyContent={'center'}>
                                    <Button colorScheme="primary" onClick={fetchNextPage} isLoading={isFetchingNextPage}>
                                        {intl.formatMessage({ id: 'viewMore' })}
                                    </Button>
                                </Flex>
                            </Td>
                        </Tr>
                    )}

                    {data?.length === 0 && (
                        <Tr>
                            <Td colSpan={3}>
                                <Flex p={2} width={'full'} alignItems={'center'} justifyContent={'center'}>
                                    <Text fontSize={'lg'} fontWeight={'600'}>
                                        {intl.formatMessage({ id: 'noData' })}
                                    </Text>
                                </Flex>
                            </Td>
                        </Tr>
                    )}
                </Tfoot>
            </Table>
        </TableContainer>
    );
};
