import { useNavigate } from 'react-router-dom';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useAuthStore } from '../../store/auth/authStore';
import { useIntl } from 'react-intl';
import { Box, useDisclosure } from '@chakra-ui/react';
import { NewUserProfileCard } from '../../components/employee/UserProfileCard';
import { useGetUserProfileDetail, useUpdateEmployee } from '../../query/resource-hooks/company';
import { FileDropZone } from '../../components/ui/FileDropZone';
import UserPolicy from '../../policies/UserPolicy';
import { MdCameraAlt } from 'react-icons/md';

const ProfileScreen: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const user = useAuthStore((s) => s.user);
    const userDetail = useGetUserProfileDetail(user?.id!);
    const updateEmployee = useUpdateEmployee(() => userDetail.refetch());
    const editPhotoDisclosure = useDisclosure();

    const handleImageUpload = (files: File[]) => {
        updateEmployee.mutate({ data: { id: userDetail.data?.id!, image: files[0] } });
    };

    return (
        <>
            <SecondPageHeader title={intl.formatMessage({ id: 'profile' })} onBack={() => navigate(-1)} />
            <Box display={'flex'} width={'100%'} pb={4} justifyContent={'center'} px={{ base: 0, xl: 2 }} pt={4}>
                <NewUserProfileCard
                    user={userDetail?.data || user || undefined}
                    isLoading={userDetail.isLoading}
                    actions={[
                        {
                            label: intl.formatMessage({ id: 'updatePhoto' }),
                            onClick: editPhotoDisclosure.onOpen,
                            show: !!userDetail.data && UserPolicy.canEditPhoto(user!, userDetail.data),
                            icon: <MdCameraAlt />,
                            variant: 'outline',
                        },
                    ]}
                />
            </Box>
            <FileDropZone
                isOpen={editPhotoDisclosure.isOpen}
                onClose={editPhotoDisclosure.onClose}
                onDrop={handleImageUpload}
                title={intl.formatMessage({ id: 'updatePhoto' })}
            />
        </>
    );
};

export default ProfileScreen;
