import { useLiveQuery } from 'dexie-react-hooks';
import { db } from './db';
import { useAuthStore } from '../../store/auth/authStore';
import { DocumentStatus } from '../../types/enums/DocumentStatus';
import { useState } from 'react';
import { useDeleteAttendanceStatementCombined } from '../combined/useDeleteAttendanceStatementCombined';
import { LocalAttendanceStatement } from '../../types';
import { useUpdateAttendanceStatement } from '../resource-hooks/attendanceStatement';

export type AttendanceStatementListItemData = {
    id: number;
    externalId?: number;
    name: string;
    dateFrom: string;
    dateTo: string;
    totalHours: number;
    lastChangeAt: string;
    lastUploadAt: string | null;
    suspendedHours: number;
    createdAt: string;
    status: DocumentStatus;
};

type LocalAttendanceStatementListProps = {
    limit?: number;
    userId?: number;
    status?: DocumentStatus;
};

export const useLocalAttendanceStatementList = ({ limit = 10, userId, status }: LocalAttendanceStatementListProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const loggedUser = useAuthStore((s) => s.user)!;
    const useDeleteCombined = useDeleteAttendanceStatementCombined();
    const useUpdateMutation = useUpdateAttendanceStatement();

    function andFilter(report: LocalAttendanceStatement) {
        if (report.externalId !== undefined) {
            return false;
        }

        if (userId && status) {
            return report.userId === userId && report.status === status;
        }

        if (userId) {
            return report.userId === userId;
        }

        if (status) {
            return report.status === status;
        }

        return true;
    }

    const count = useLiveQuery(
        () => db.attendanceStatements.where('companyId').equals(loggedUser?.companyId!).and(andFilter).count(),
        [userId, status]
    );

    const localCopiesMap = useLiveQuery(() =>
        db.attendanceStatements
            .where('externalId')
            .above(0)
            .toArray()
            .then((reports) => reports.map((report) => ({ id: report.id, externalId: report.externalId! })))
    );

    const data: AttendanceStatementListItemData[] | undefined = useLiveQuery(
        () =>
            db.attendanceStatements
                .where('companyId')
                .equals(loggedUser?.companyId!)
                .and(andFilter)
                .reverse()
                .limit(limit)
                .sortBy('createdAt')
                .then((reports) =>
                    reports.map((report) => {
                        return {
                            id: report.id,
                            externalId: report.externalId,
                            name: report.name,
                            dateFrom: report.dateFrom,
                            dateTo: report.dateTo,
                            totalHours: report.totalHours,
                            lastChangeAt: report.lastChangeAt,
                            lastUploadAt: report.lastUploadAt,
                            suspendedHours: report.suspendedHours,
                            createdAt: report.createdAt,
                            status: report.status,
                        };
                    })
                ),
        [limit, userId, status]
    );

    const deleteReport = async (params: { localId?: number; externalId?: number }) => {
        setIsLoading(true);
        try {
            await useDeleteCombined.deleteCombinedStatement(params);
        } catch (error) {
            setIsLoading(false);
            console.error('Error deleting report', error);
        }

        setIsLoading(false);
    };

    const updateLocalStatus = async (localId: number, status: DocumentStatus) => {
        setIsLoading(true);
        try {
            const report = await db.attendanceStatements.where('id').equals(localId).first();
            if (!report) {
                throw new Error('Report not found');
            }

            report.status = status;
            report.lastChangeAt = new Date().toISOString();
            await db.attendanceStatements.update(report.id, report);
        } catch (error) {
            setIsLoading(false);
            console.error('Error marking report as done', error);
        }

        setIsLoading(false);
    };

    const updateCombinedStatus = async (params: { localId?: number; externalId?: number; status: DocumentStatus }) => {
        try {
            if (params.localId) {
                await updateLocalStatus(params.localId, params.status);
            }

            if (params.externalId) {
                await useUpdateMutation.mutateAsync({ id: params.externalId, data: { status: params.status } });
            }
        } catch (error) {
            console.error('Error marking report as done', error);
        }
    };

    const markAsDone = (localId: number) => updateLocalStatus(localId, DocumentStatus.DONE);
    const markAsDraft = (localId: number) => updateLocalStatus(localId, DocumentStatus.DRAFT);

    return { data, count, deleteReport, isLoading, updateLocalStatus, updateCombinedStatus, markAsDone, markAsDraft, localCopiesMap };
};
