import React from 'react';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, useColorModeValue, Text } from '@chakra-ui/react';
import './Pricing.css';
import { useIntl } from 'react-intl';

export const PricingTable: React.FC = () => {
    const intl = useIntl();
    return (
        <TableContainer>
            <Table px={2} size={{ base: 'md', md: 'lg' }} className="pricing_table" variant={'unstyled'}>
                <Thead>
                    <Tr>
                        <Th>
                            <Text textColor="gray.400">{intl.formatMessage({ id: 'pricing.numberOfUsers' })}</Text>
                        </Th>
                        <Th>
                            <Text textColor="gray.400">{intl.formatMessage({ id: 'pricing.priceMonthly' })}</Text>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr bg={useColorModeValue('white', 'gray.700')} boxShadow={'md'}>
                        <Td border="none" borderLeftRadius={'lg'} fontWeight={'500'} fontSize={'lg'}>
                            {intl.formatMessage({ id: 'pricing.to' })} 10
                        </Td>
                        <Td border="none" borderRightRadius={'lg'} fontWeight={'500'} fontSize={'lg'}>
                            1€ / {intl.formatMessage({ id: 'pricing.user' })}
                        </Td>
                    </Tr>
                    <Tr bg={useColorModeValue('white', 'gray.700')} boxShadow={'md'}>
                        <Td border="none" borderLeftRadius={'lg'} fontWeight={'500'} fontSize={'lg'}>
                            {intl.formatMessage({ id: 'pricing.to' })} 20
                        </Td>
                        <Td border="none" borderRightRadius={'lg'} fontWeight={'500'} fontSize={'lg'}>
                            0.8€ / {intl.formatMessage({ id: 'pricing.user' })}
                        </Td>
                    </Tr>
                    <Tr bg={useColorModeValue('white', 'gray.700')} boxShadow={'md'}>
                        <Td border="none" borderLeftRadius={'lg'} fontWeight={'500'} fontSize={'lg'}>
                            {intl.formatMessage({ id: 'pricing.from' })} 21
                        </Td>
                        <Td border="none" borderRightRadius={'lg'} fontWeight={'500'} fontSize={'lg'}>
                            0.6€ / {intl.formatMessage({ id: 'pricing.user' })}
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    );
};
