import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Statistics, TimeGroup } from '../../types';
import { curveCardinal } from 'd3-shape';
import { useIntl } from 'react-intl';
import { Box, Card, Skeleton, Text, useColorModeValue, useTheme } from '@chakra-ui/react';
type ChartProps = {
    statistics: Statistics;
};

const cardinal = curveCardinal.tension(0.2);

const AttendanceChart: React.FC<ChartProps> = ({ statistics }) => {
    const intl = useIntl();
    const theme = useTheme();
    const chartLabelsColor = useColorModeValue(theme.colors.gray[600], theme.colors.gray[200]);
    const chartGridColor = useColorModeValue(theme.colors.gray[300], theme.colors.gray[600]);
    const chartLineColor = useColorModeValue(theme.colors.primary[500], theme.colors.primary[300]);

    const dateToShort = (isoDate?: string) => {
        if (!isoDate) {
            return '-';
        }

        return intl.formatDate(isoDate, { day: 'numeric', month: 'short' }).replace(/\s/g, '');
    };

    const formatLabel = (label: string) => {
        switch (statistics?.loginsByDay.groupBy) {
            case TimeGroup.DAY:
                return dateToShort(label);
            case TimeGroup.WEEK:
                const weekLater = new Date(label);
                weekLater.setDate(weekLater.getDate() + 6);
                return `${dateToShort(label)} - ${dateToShort(weekLater.toISOString())}`;
            case TimeGroup.MONTH:
                return intl.formatDate(label, { month: 'long' });
            case TimeGroup.YEAR:
                return intl.formatDate(label, { year: 'numeric' });
        }
    };

    if (!statistics?.loginsByDay) {
        return (
            <Box width={'100%'} height={'100%'} p={5}>
                <Skeleton width={'100%'} height={'100%'} />
            </Box>
        );
    }


    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={statistics.loginsByDay.data?.length ? statistics.loginsByDay.data : [{ group: '', count: 0 }]}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={chartGridColor} />
                <XAxis
                    dataKey="group"
                    tickFormatter={(value, _index) => {
                        return formatLabel(value);
                    }}
                    tick={{ fill: chartLabelsColor }}
                />
                <YAxis dataKey={'count'} tick={{ fill: chartLabelsColor }} />
                <Tooltip
                    content={({ payload, label, active }) => {
                        if (active && payload && payload.length) {
                            return (
                                <Card p={2}>
                                    <Text>{formatLabel(label)}</Text>
                                    <Text>
                                        {intl.formatMessage({ id: 'home.logins' })} {': '} {payload[0].value}
                                    </Text>
                                </Card>
                            );
                        }

                        return null;
                    }}
                />
                <Area type={cardinal} dataKey="count" label={'pes'} stroke={chartLineColor} fill="#bac4d4" fillOpacity={0.3} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AttendanceChart;
