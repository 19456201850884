import React, { useEffect } from 'react';
import SecondPageHeader from '../../../components/ui/SecondPageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingCard } from '../../../components/ui/LoadingCard';
import { db } from '../../../query/indexedDb/db';

export const NewCreatedStatementScreen: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const createdStatementId = location?.state?.newStatementLocalId as number | undefined;

    useEffect(() => {
        const fetchAndRedirect = async () => {
            if (createdStatementId) {
                const record = await db.attendanceStatements.get(createdStatementId);
                if (record) {
                    navigate(`/attendance/statement/loc/${record.id}`, { replace: true });
                } else {
                    // Handle the case where the record does not exist
                    console.error('Record not found');
                }
            }
        };

        fetchAndRedirect();
    }, [createdStatementId, navigate]);

    return (
        <>
            <SecondPageHeader title="Výpis dochádzky" />
            <LoadingCard />
        </>
        
    );
};
