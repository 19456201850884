import { Box, HStack, Heading, TableContainer, Table, Tbody, Tr, Td, Divider, Thead, Th, useColorModeValue, Text } from '@chakra-ui/react';
import React, { ComponentProps } from 'react';
import { DocumentStatus } from '../../../../../types/enums/DocumentStatus';
import { DocumentStatusBadge } from '../../../../documents/DocumentStatus';
import { useIntl } from 'react-intl';
import { AttendanceStatement } from '../../../../../types/documents';
import {
    getBreaksDurationForUploadedStatement,
    getHoursInWorkFromUploadedStatement,
    getTotalHoursFromUploadedStatement,
} from '../../../../../utils/DocumentUtils';
import { useSuIntl } from '../../../../../hooks/useSuIntl';

type UploadedStatementTableProps = {
    data: AttendanceStatement;
} & ComponentProps<typeof Box>;

export const UploadedStatementTable: React.FC<UploadedStatementTableProps> = ({ data: document, ...boxProps }) => {
    const intl = useIntl();
    const { t } = useSuIntl();
    const formatDateField = (date?: string) => {
        return date ? intl.formatDate(date) : '-';
    };

    const formatTimeField = (time?: string) => {
        return time ? intl.formatTime(time) : '-';
    };

    const getBreaksDuration = () => {
        return getBreaksDurationForUploadedStatement(document);
    };

    const getTotalInWork = () => {
        return getHoursInWorkFromUploadedStatement(document);
    };

    const hoverColor = useColorModeValue('gray.200', 'gray.800');
    const backgroundColor = useColorModeValue('white', 'gray.700');
    return (
        <Box
            boxShadow={'lg'}
            w={{ base: '100%', md: 400, lg: 700, xl: 960 }}
            p={4}
            backgroundColor={backgroundColor}
            borderRadius={'xl'}
            {...boxProps}
        >
            <HStack justify={'space-between'}>
                <Heading size={'md'}>{document?.identifier ?? t('attendanceStatement.title.long.singular')}</Heading>
                <DocumentStatusBadge status={document?.status || DocumentStatus.DRAFT} />
            </HStack>

            <TableContainer mt={4} fontWeight={'500'}>
                <Table width={{ base: '100%', md: 200, lg: 350 }} size={'sm'} variant="unstyled" colorScheme="gray">
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text fontSize={'md'}>{t('EMPLOYEE')}</Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'}>{document?.data?.data?.name}</Text>
                            </Td>
                        </Tr>
                        <Tr fontSize={'md'}>
                            <Td fontSize={'md'}>{t('dateFrom')}</Td>
                            <Td>{intl.formatDate(document?.data?.data?.dateFrom)}</Td>
                        </Tr>
                        <Tr fontSize={'md'}>
                            <Td fontSize={'md'}>{t('dateTo')}</Td>
                            <Td>{intl.formatDate(document?.data?.data?.dateTo)}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

            <Divider my={4} />

            <TableContainer>
                <Table size={'sm'} variant="unstyled">
                    <Thead>
                        <Tr>
                            <Th colSpan={2}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('field.date')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('IN')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('OUT')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="capitalize">
                                    {t('attendance.break')}
                                </Text>
                            </Th>
                            <Th textAlign={'center'}>
                                <Text fontSize={'md'} fontWeight={'700'} textTransform="none">
                                    {t('attendance.workTime')}
                                </Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {document?.data?.data?.reports
                            .map((report, index) =>
                                report.shifts.map((shift, index) => (
                                    <Tr key={`${report.date}-${index}`} role="group" _hover={{ backgroundColor: hoverColor }}>
                                        <Td colSpan={2} py={1}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {formatDateField(report.date)}
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {formatTimeField(shift.arrival?.date)}
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {formatTimeField(shift.departure?.date)}
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {shift.breakDuration || 0}h
                                            </Text>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Text fontSize={'md'} fontWeight={'500'}>
                                                {shift.hoursWorked}h
                                            </Text>
                                        </Td>
                                    </Tr>
                                ))
                            )
                            .flat() || null}
                    </Tbody>
                </Table>
            </TableContainer>

            <Divider my={4} />
            <Heading size={'sm'} ml={4}>
                {t('doc.total')}
            </Heading>
            <TableContainer mt={4} fontWeight={'500'}>
                <Table size={'sm'} variant="unstyled">
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text> {t('attendance.daysInWork')}</Text>
                            </Td>
                            <Td>
                                <Text>{document?.data?.data?.reports?.length}</Text>
                            </Td>
                            <Td>
                                <Text>{t('attendance.breaks')}</Text>
                            </Td>
                            <Td>
                                <Text>{getBreaksDuration()}h</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>{t('attendance.timeInWork')}</Td>
                            <Td>{getTotalInWork()}h</Td>

                            <Td> {t('attendance.suspendedTime')}</Td>

                            <Td>{document?.data?.data?.suspendedHours ?? 0}h</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <HStack justify={'space-between'} m={8} borderRadius={'xl'} borderWidth={1} p={4} borderColor={'gray.300'}>
                <Text fontSize={'lg'} fontWeight={'bold'}>
                    {t('attendance.timeWorked')}
                </Text>
                <Text fontWeight={'bold'} fontSize={'lg'}>
                    {getTotalHoursFromUploadedStatement(document)} h
                </Text>
            </HStack>
        </Box>
    );
};
