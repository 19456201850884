import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Collapse,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    PopoverTrigger,
    Stack,
    Tooltip,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
    useToast,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { RangeDatepicker } from '../../calendarPicker';
import { useIntl } from 'react-intl';
import { ComponentProps, useState } from 'react';
import { AdditionalReportSettingsData } from './SimpleReportModal';
import { BreakCreator, BreakCreatorData } from './BreakTimePeriod';
import { TextValueField } from '../../common/TextValueField';
import { AttendanceReport, User } from '../../../types';
import { MdAlarm, MdCalendarToday, MdInfoOutline, MdTune } from 'react-icons/md';
import { ReportBreakTextVisualizer } from './BreakVisualizer';
import { useStoreLocalReport } from '../../../query/indexedDb/useStoreLocalReport';
import { useAuthStore } from '../../../store/auth/authStore';
import { useNavigate } from 'react-router-dom';
import { TimeRangeVisualizer } from '../../common/DateRangeVisualizer';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FilterChip } from '../AttendanceListFilter';
import moment from 'moment';

type UserAttendanceReportFilterProps = ComponentProps<typeof Card> & {
    onFilterChange: (dates: Date[], additionalSettings?: AdditionalReportSettingsData) => void;
    selectedDates: Date[];
    data?: AttendanceReport;
    initialBreakCreatorData?: BreakCreatorData;
    user: User;
};

export const UserAttendanceReportFilter: React.FC<UserAttendanceReportFilterProps> = ({
    onFilterChange,
    selectedDates,
    data,
    initialBreakCreatorData,
    user,
    ...restCardProps
}) => {
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const [breakCreatorData, setBreakCreatorData] = useState<BreakCreatorData | undefined>(initialBreakCreatorData);
    const { storeNewStatement, isLoading } = useStoreLocalReport();
    const toast = useToast();
    const navigate = useNavigate();
    const orientation = useBreakpointValue<'row' | 'column'>({ base: 'column', xl: 'row' });
    const { isOpen: filterExpanded, onToggle: onToggleFilter } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: additionalSettingsVisible, onToggle: onToggleAdditionalSettings } = useDisclosure({ defaultIsOpen: true });

    async function handleStoreNewLocalReport() {
        if (data && loggedUser) {
            const newId = await storeNewStatement(data, loggedUser);
            if (newId) {
                toast({
                    title: 'Výkaz bol vytvorený',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: 'top',
                });
                navigate('/attendance/statement/new', { state: { newStatementLocalId: newId } });
            } else {
                toast({
                    title: intl.formatMessage({ id: 'somethingWentWrong' }),
                    status: 'error',
                    position: 'top',
                });
            }
        }
    }

    function handleChipSelected(dates: Date[]) {
        onFilterChange(dates, breakCreatorData ? { breakCreatorData } : undefined);
    }

    function isChipSelected(chipType: 'day' | 'week' | 'month'): boolean {
        let start = moment().startOf('day');
        let end = moment().endOf('day');
        switch (chipType) {
            case 'day':
                break;
            case 'week':
                start.startOf('week');
                end.endOf('week');
                break;
            case 'month':
                start.subtract(1, 'month').startOf('month');
                end.subtract(1, 'month').endOf('month');
                break;
        }

        if (start.isSame(selectedDates[0], 'day') && end.isSame(selectedDates[1], 'day')) {
            return true;
        }

        return false;
    }

    return (
        <Card borderRadius={'2xl'} {...restCardProps} maxW={'100%'}>
            <CardBody minW={'300'}>
                <VStack align={'start'} spacing={2}>
                    <Flex width={'100%'} align={'center'} flexDir={'row'} justify={'space-between'}>
                        <Heading alignItems={'center'} size={'md'}>
                            Filter
                        </Heading>
                        <IconButton
                            aria-label="filter expanded"
                            variant={'ghost'}
                            icon={
                                <ChevronDownIcon
                                    style={{
                                        transition: 'transform 0.3s ease',
                                        transform: filterExpanded ? 'rotate(180deg)' : undefined,
                                    }}
                                    boxSize={7}
                                    onClick={onToggleFilter}
                                />
                            }
                        />
                    </Flex>

                    <Box w={'100%'}>
                        <Collapse in={filterExpanded} animateOpacity>
                            <Stack direction={{ base: 'row', xl: 'column' }} justify={'left'} align={{ base: 'center', xl: 'start' }}>
                                <VStack align={'start'} justify={'left'}>
                                    <RangeDatepicker
                                        selectedDates={selectedDates}
                                        onDateChange={(dates) => {
                                            onFilterChange(dates, breakCreatorData ? { breakCreatorData } : undefined);
                                        }}
                                        usePortal
                                        configs={{
                                            placeHolders: [intl.formatMessage({ id: 'dateFrom' }), intl.formatMessage({ id: 'dateTo' })],
                                        }}
                                    >
                                        {(dates) => (
                                            <PopoverTrigger>
                                                <Box>
                                                    <TimeRangeVisualizer
                                                        interactive
                                                        orientation={orientation}
                                                        cursor={'pointer'}
                                                        dateFrom={intl.formatDate(dates[0])}
                                                        dateTo={intl.formatDate(dates[1])}
                                                    />
                                                </Box>
                                            </PopoverTrigger>
                                        )}
                                    </RangeDatepicker>
                                    <Wrap mt={2}>
                                        <WrapItem>
                                            <FilterChip
                                                colorScheme="gray"
                                                label={intl.formatMessage({ id: 'timefilter.today' })}
                                                onClear={() => {}}
                                                selected={isChipSelected('day')}
                                                onSelect={() => {
                                                    const start = moment().startOf('day').toDate();
                                                    const end = moment().endOf('day').toDate();
                                                    handleChipSelected([start, end]);
                                                }}
                                            />
                                        </WrapItem>
                                        <WrapItem>
                                            <FilterChip
                                                colorScheme="gray"
                                                label={intl.formatMessage({ id: 'timefilter.week' })}
                                                onClear={() => {}}
                                                selected={isChipSelected('week')}
                                                onSelect={() => {
                                                    const start = moment().startOf('week').toDate();
                                                    const end = moment().endOf('week').toDate();
                                                    handleChipSelected([start, end]);
                                                }}
                                            />
                                        </WrapItem>
                                        <WrapItem>
                                            <FilterChip
                                                colorScheme="gray"
                                                label={intl.formatDate(moment().subtract(1, 'month').toDate(), { month: 'long' })}
                                                onClear={() => {}}
                                                selected={isChipSelected('month')}
                                                onSelect={() => {
                                                    const start = moment().subtract(1, 'month').startOf('month').toDate();
                                                    const end = moment().subtract(1, 'month').endOf('month').toDate();
                                                    handleChipSelected([start, end]);
                                                }}
                                            />
                                        </WrapItem>
                                    </Wrap>
                                </VStack>
                                <Stack direction={'column'}>
                                    <TextValueField
                                        label={intl.formatMessage({ id: 'attendanceReport.totalTime' })}
                                        value={data?.totalHours ? `${data.totalHours}h` : '-'}
                                        icon={<MdAlarm />}
                                        smallVariant
                                    />
                                    <TextValueField
                                        label={intl.formatMessage({ id: 'attendance.daysInWork' })}
                                        value={data?.reports?.length || '-'}
                                        icon={<MdCalendarToday />}
                                        smallVariant
                                    />
                                </Stack>
                            </Stack>
                            <Button
                                variant={'ghost'}
                                borderRadius={'xl'}
                                width={'100%'}
                                rightIcon={
                                    <ChevronDownIcon
                                        style={{
                                            transition: 'transform 0.3s ease',
                                            transform: additionalSettingsVisible ? 'rotate(180deg)' : undefined,
                                        }}
                                        boxSize={7}
                                    />
                                }
                                onClick={onToggleAdditionalSettings}
                            >
                                {intl.formatMessage({ id: 'editor.additionalSettings' })}
                            </Button>
                            <Box w={'100%'}>
                                <Collapse in={additionalSettingsVisible} animateOpacity>
                                    <Box p={4} borderRadius={'xl'} backgroundColor={useColorModeValue('gray.100', 'gray.800')}>
                                        <HStack justify={'space-between'}>
                                            <Heading size={'sm'}>{intl.formatMessage({ id: 'attendance.breaks' })}</Heading>
                                            <Menu placement="left">
                                                <MenuButton
                                                    size={'sm'}
                                                    as={IconButton}
                                                    aria-label="additional settings"
                                                    icon={<MdTune />}
                                                ></MenuButton>

                                                <MenuList p={4} borderRadius={'xl'}>
                                                    <BreakCreator
                                                        requireConfirmation
                                                        onChange={(data) => {
                                                            console.log('onChange on creator', data);
                                                            onFilterChange(selectedDates, { breakCreatorData: data });
                                                            setBreakCreatorData(data);
                                                        }}
                                                    />
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <Divider borderColor={'white'} my={2} />
                                        <ReportBreakTextVisualizer
                                            breakDurationMinutes={breakCreatorData?.breakDurationInMinutes}
                                            breaks={breakCreatorData?.breaks}
                                            maxShiftWithoutBreakInHours={breakCreatorData?.maxShiftWithoutBreakInHours}
                                        />
                                    </Box>
                                </Collapse>
                            </Box>

                            <HStack mt={4} justify={'space-between'} w={'100%'} align={'center'}>
                                <Button flex={1} isLoading={isLoading} onClick={handleStoreNewLocalReport}>
                                    {intl.formatMessage({ id: 'attendanceStatement.create' })}
                                </Button>
                                <Tooltip label={intl.formatMessage({ id: 'attendanceStatement.create.text' })}>
                                    <Center>
                                        <Icon as={MdInfoOutline} boxSize={22} />
                                    </Center>
                                </Tooltip>
                            </HStack>
                        </Collapse>
                    </Box>
                </VStack>
            </CardBody>
        </Card>
    );
};
