import { HStack, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { ComponentProps } from 'react';
import { IconType } from 'react-icons';
import { MdDateRange, MdSyncAlt } from 'react-icons/md';

type DateRangeVisualizerProps = ComponentProps<typeof HStack> & {
    dateFrom?: string;
    dateTo?: string;
    size?: string;
    orientation?: 'row' | 'column';
    interactive?: boolean;
    leftIcon?: IconType;
    middleIcon?: IconType;
    onLeftIconClick?: () => void;
    onMiddleIconClick?: () => void;
};

export const TimeRangeVisualizer: React.FC<DateRangeVisualizerProps> = ({
    dateFrom,
    dateTo,
    size = 'sm',
    orientation = 'row',
    interactive = false,
    leftIcon = MdDateRange,
    middleIcon = MdSyncAlt,
    onLeftIconClick,
    onMiddleIconClick,
    ...stackProps
}) => {
    const textColor = useColorModeValue('gray.900', 'gray.300');
    const borderColor = useColorModeValue('gray.400', 'gray.500');
    const hoverColor = useColorModeValue('gray.100', 'gray.700');
    return (
        <Stack
            width={'fit-content'}
            minW={'fit-content'}
            borderRadius={'xl'}
            borderWidth={1}
            justify={'center'}
            p={1.5}
            px={2}
            spacing={2}
            backgroundColor={useColorModeValue('gray.200', 'gray.800')}
            borderColor={borderColor}
            color={'gray.600'}
            direction={orientation}
            align={'center'}
            _hover={interactive ? { borderColor: 'gray.600', backgroundColor: hoverColor } : {}}
            {...stackProps}
        >
            <Icon onClick={onLeftIconClick} cursor={onLeftIconClick ? 'pointer' : 'default'} color={textColor} as={leftIcon} />
            <Text fontSize={size} fontWeight={'500'} color={textColor} isTruncated>
                {dateFrom || '-'}
            </Text>
            <Icon  onClick={onMiddleIconClick} cursor={onMiddleIconClick ? 'pointer' : 'default'} color={textColor} as={middleIcon} transform={orientation === 'column' ? 'rotate(90deg)' : 'none'} />
            <Text fontSize={size} fontWeight={'500'} color={textColor} isTruncated>
                {dateTo || '-'}
            </Text>
        </Stack>
    );
};
