import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorModeValue,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { NewUserProfileCard } from '../../components/employee/UserProfileCard';
import {
    useDeleteEmployee,
    useGetAttendancePagination,
    useGetUserProfileDetail,
    useUpdateEmployee,
} from '../../query/resource-hooks/company';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import AttendanceTable from '../attendance/AttendanceTable';
import { AttendanceTableItem } from '../../components/lists/AttendanceTableItem';
import { AttendanceListFilter } from '../../components/attendance/AttendanceListFilter';
import { useState } from 'react';
import { LoginDirection, Role } from '../../types';
import { adjustDateToUserTimezone } from '../../components/calendarPicker';
import { DeleteIcon, EditIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { UpdateAttendanceModal } from '../../components/attendance/UpdateAttendanceModal';
import { EmployeeForm } from '../../components/employee/EmployeeForm';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';
import { MdCameraAlt, MdDevices } from 'react-icons/md';
import { FileDropZone } from '../../components/ui/FileDropZone';
import { DesktopRecommendedDialog } from '../../components/ui/DesktopRecomendedDialog';
import { UserAttendanceReportTab } from '../../components/attendance/reports/UserAttendanceReportTab';
import { usePlatform } from '../../hooks/usePlatform';
import { StoredAttendanceStatementsList } from '../../components/attendance/reports/local/AttendanceStatementsList';
import { useArchiveDialog } from '../../hooks/useAlertDialog';

const EmployeeDetailScreen: React.FC = () => {
    const routeParams = useParams<{ id: string }>();
    const navigate = useNavigate();

    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const user = useGetUserProfileDetail(+routeParams.id!);
    const toastContext = useToast();
    const updateEmployee = useUpdateEmployee(() => user.refetch());
    const platform = usePlatform();

    const [searchParams, setSearchParams] = useSearchParams();

    const tabIndex = parseInt(searchParams.get('tab') || '0', 10);

    const tabsHoverColor = useColorModeValue('gray.100', 'gray.700');

    const deleteUser = useDeleteEmployee(
        () => {
            toastContext({
                status: 'success',
                title: intl.formatMessage({ id: 'data.deleted' }),
                duration: 3000,
                isClosable: true,
                position: 'top',
            });
            navigate('/employees');
        },
        (_error) => {
            toastContext({
                status: 'error',
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                duration: 3000,
                isClosable: true,
                position: 'top',
            });
            deleteUser.reset();
        }
    );

    const { Dialog: ArchiveDialog, showDialog: showArchiveDialog } = useArchiveDialog(deleteUser.isLoading);

    const addAttendanceDisclosure = useDisclosure();
    const editUserDisclosure = useDisclosure();
    const editPhotoDisclosure = useDisclosure();
    const desktopRecommendedDisclosure = useDisclosure();

    const [filterLoginDirection, setFilterLoginDirection] = useState<LoginDirection>(LoginDirection.NOT_SET);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);

    const attendanceQuery = useGetAttendancePagination({
        userIds: [+routeParams.id!],
        ...(selectedDates[0] && { dateFrom: adjustDateToUserTimezone(selectedDates[0]).toISOString() }),
        ...(selectedDates[1] && { dateTo: new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString() }),
        ...(filterLoginDirection !== LoginDirection.NOT_SET && { direction: filterLoginDirection }),
        ...(user.data?.role === Role.TEAM_ACCOUNT && { includeCreatedBy: true }),
    });


    const handleImageUpload = (files: File[]) => {
        updateEmployee.mutate({ data: { id: user.data?.id!, image: files[0] } });
    };

    function setTabIndex(index: number) {
        setSearchParams({ tab: index.toString() });
    }

    const handleTabChange = (index: number) => {
        if (index === 1 && platform !== 'desktop') {
            desktopRecommendedDisclosure.onOpen();
            return;
        }
        setTabIndex(index);
    };

    if (user.error) {
        return <Navigate to="/404" />;
    }
    return (
        <>
            <SecondPageHeader
                title={intl.formatMessage({ id: 'tab.employees.detail.title' })}
                onBack={() => navigate(-1)}
                menuListItems={[
                    ...(user.data && UserPolicy.canEditUser(loggedUser!, user.data)
                        ? [
                              {
                                  onClick: editUserDisclosure.onOpen,
                                  title: intl.formatMessage({ id: 'edit' }),
                                  icon: <Icon as={EditIcon} />,
                              },
                          ]
                        : []),
                ]}
            />
            <Flex justify={'center'} width={'100%'}>
                <NewUserProfileCard
                    w={'100%'}
                    onUpdate={user.refetch}
                    maxW={1400}
                    user={user.data}
                    isLoading={user.isLoading}
                    m={4}
                    actions={[
                        {
                            label: intl.formatMessage({ id: 'edit' }),
                            onClick: editUserDisclosure.onOpen,
                            show: !!user.data && UserPolicy.canEditUser(loggedUser!, user.data),
                            icon: <EditIcon />,
                            // variant: 'outline',
                        },
                        {
                            label: intl.formatMessage({ id: 'device.list.title' }),
                            onClick: () => navigate(`devices`),
                            show: !!user.data && UserPolicy.canEditUser(loggedUser!, user.data),
                            icon: <MdDevices />,
                            // variant: 'outline',
                        },
                        {
                            label: intl.formatMessage({ id: 'updatePhoto' }),
                            onClick: editPhotoDisclosure.onOpen,
                            show: !!user.data && user.data.role !== Role.TEAM_ACCOUNT && UserPolicy.canEditPhoto(loggedUser!, user.data),
                            icon: <MdCameraAlt />,
                            variant: 'outline',
                        },
                        {
                            label: intl.formatMessage({ id: 'editor.moveToArchive' }),
                            onClick: () => showArchiveDialog(() => deleteUser.mutate(user.data?.id!)),
                            show: !!user.data && UserPolicy.canDeleteUser(loggedUser!, user.data.role),
                            icon: <DeleteIcon />,
                            colorScheme: 'red',
                            variant: 'ghost',
                        },
                    ]}
                />
                <FileDropZone
                    isOpen={editPhotoDisclosure.isOpen}
                    onClose={editPhotoDisclosure.onClose}
                    onDrop={handleImageUpload}
                    title={intl.formatMessage({ id: 'updatePhoto' })}
                />
            </Flex>
            <Heading size={'md'} ml={4}>
                {intl.formatMessage({ id: 'attendance' })}
            </Heading>

            <Tabs index={tabIndex} m={4} mt={2} isFitted variant={'onBackground'} isLazy>
                <TabList>
                    <Tab onClick={() => handleTabChange(0)}>
                        <Heading size={'sm'}>{intl.formatMessage({ id: 'attendance.list' })}</Heading>
                    </Tab>
                    <Tab
                        onClick={(e) => {
                            handleTabChange(1);
                        }}
                        isDisabled={user.data?.role === Role.TEAM_ACCOUNT}
                    >
                        <Heading size={'sm'}>{intl.formatMessage({ id: 'attendance.shifts' })}</Heading>
                    </Tab>
                    <Tab
                        onClick={(e) => {
                            handleTabChange(2);
                        }}
                        isDisabled={user.data?.role === Role.TEAM_ACCOUNT}
                    >
                        <Heading size={'sm'}>{intl.formatMessage({ id: 'attendanceStatement.title.short.plural' })}</Heading>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Stack flexDir={{ base: 'column', lg: 'row' }} width={'100%'}>
                            <Box order={{ base: 2, lg: 1 }} width={'100%'}>
                                <Box p={2} display={'flex'} flexDir={'row'} justifyContent={'space-between'}>
                                    <Button
                                        ml={2}
                                        size={'sm'}
                                        onClick={addAttendanceDisclosure.onOpen}
                                        borderRadius={'full'}
                                        leftIcon={<PlusSquareIcon />}
                                        isDisabled={!UserPolicy.canCreateManualAttendance(loggedUser!)}
                                    >
                                        {intl.formatMessage({ id: 'tab.Add' })}
                                    </Button>
                                </Box>
                                <AttendanceTable
                                    fetchNextPage={attendanceQuery.fetchNextPage}
                                    hasNextPage={attendanceQuery.hasNextPage}
                                    isFetchingNextPage={attendanceQuery.isFetchingNextPage}
                                    isLoading={attendanceQuery.isLoading && attendanceQuery.isRefetching}
                                    data={attendanceQuery.data}
                                    renderItem={(item, showPreview) => {
                                        return (
                                            <AttendanceTableItem
                                                _hover={{
                                                    bg: tabsHoverColor,
                                                    boxShadow: 'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                }}
                                                onClick={() => showPreview()}
                                                item={item}
                                                key={item.id}
                                            />
                                        );
                                    }}
                                    handleNavigateToDetail={(id) => navigate(`attendance/${id}`)}
                                />
                            </Box>
                            <Box order={{ base: 1, lg: 2 }}>
                                <AttendanceListFilter
                                    onDateChange={setSelectedDates}
                                    selectedDates={selectedDates}
                                    filterLoginDirection={filterLoginDirection}
                                    onFilterLoginDirectionChange={setFilterLoginDirection}
                                    totalRecords={attendanceQuery.data?.pages?.[0].meta?.total}
                                    position="sticky"
                                    top="22px"
                                    mt={4}
                                    ml={{ base: 2, lg: 0 }}
                                    mr={2}
                                />
                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel pl={0}>
                        <UserAttendanceReportTab user={user.data!} />
                    </TabPanel>
                    <TabPanel>
                        <StoredAttendanceStatementsList filter={{ userId: user.data?.id }} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <UpdateAttendanceModal
                isOpen={addAttendanceDisclosure.isOpen}
                onClose={addAttendanceDisclosure.onClose}
                onSuccess={() => attendanceQuery.refetch()}
                preselectedDate={new Date().toISOString()}
                preselectedUser={user.data}
            />

            <EmployeeForm
                isOpen={editUserDisclosure.isOpen}
                onClose={editUserDisclosure.onClose}
                updatedUser={user.data}
                onSuccess={() => user.refetch()}
            />
            <ArchiveDialog />

            <DesktopRecommendedDialog
                isOpen={desktopRecommendedDisclosure.isOpen}
                onClose={desktopRecommendedDisclosure.onClose}
                onContinue={() => {
                    desktopRecommendedDisclosure.onClose();
                    setTabIndex(1);
                }}
            />
        </>
    );
};

export default EmployeeDetailScreen;
