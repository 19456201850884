import React from 'react';
import { PairedShift } from '../../../types';
import { Box, Stack, Step, StepDescription, StepIndicator, Stepper, StepSeparator, StepStatus, StepTitle, Tooltip } from '@chakra-ui/react';
import { TextValueField } from '../../common/TextValueField';
import { useIntl } from 'react-intl';
import { FiLogIn } from 'react-icons/fi';
import { MdAlarm, MdModeNight, MdPause, MdPlayArrow, MdSunny } from 'react-icons/md';
import { TimeRangeVisualizer } from '../../common/DateRangeVisualizer';
import { useSuIntl } from '../../../hooks/useSuIntl';

export type ReportsBreaksVisualizerProps = {
    breaks?: { start: string; end: string }[];
    breakDurationMinutes?: number;
    maxShiftWithoutBreakInHours?: number;
    orientation?: 'horizontal' | 'vertical';
};

export const ReportsBreaksStepperVisualizer: React.FC<ReportsBreaksVisualizerProps> = ({
    breaks,
    breakDurationMinutes,
    orientation = 'horizontal',
}) => {
    const intl = useIntl();
    const numberOfItems = breaks?.length ? breaks.length * 2 + 1 : 3;
    if (!breaks?.length && !breakDurationMinutes) {
        return null;
    }

    if (breaks?.length || breakDurationMinutes) {
        return (
            <Stepper
                gap={orientation === 'vertical' ? 0 : undefined}
                index={4}
                orientation={orientation}
                w={orientation === 'horizontal' ? 200 * numberOfItems : undefined}
                h={orientation === 'horizontal' ? 'auto' : 60 * numberOfItems}
            >
                <Step key={'dayStart'}>
                    <StepIndicator>
                        <StepStatus complete={<MdSunny />} incomplete={<MdSunny />} active={<MdSunny />} />
                    </StepIndicator>
                    <StepSeparator />
                </Step>
                {breaks?.map((breakPeriod, _index) => (
                    <>
                        <Step key={breakPeriod.start}>
                            <Tooltip label={'Začiatok prestávky'} aria-label={'Začiatok prestávky'}>
                                <Box>
                                    <StepIndicator>
                                        <StepStatus complete={<MdPause />} incomplete={<MdPause />} active={<FiLogIn />} />
                                    </StepIndicator>
                                </Box>
                            </Tooltip>
                            <StepTitle>{intl.formatTime(breakPeriod.start)}</StepTitle>
                            <StepSeparator />
                        </Step>

                        <Step key={breakPeriod.end}>
                            <Tooltip label={'Začiatok prestávky'} aria-label={'Začiatok prestávky'}>
                                <Box>
                                    <StepIndicator>
                                        <StepStatus complete={<MdPlayArrow />} incomplete={<MdPlayArrow />} active={<FiLogIn />} />
                                    </StepIndicator>
                                </Box>
                            </Tooltip>
                            <StepTitle>{intl.formatTime(breakPeriod.start)}</StepTitle>
                            <StepSeparator />
                        </Step>
                    </>
                ))}
                {!!breakDurationMinutes && (
                    <Step key={'breakDurationStep'}>
                        <StepIndicator>
                            <StepStatus complete={<MdAlarm />} incomplete={<MdAlarm />} active={<FiLogIn />} />
                        </StepIndicator>

                        <StepTitle>{'Prestávka'}</StepTitle>
                        <StepDescription>{`${breakDurationMinutes}min`} </StepDescription>
                        <StepSeparator />
                    </Step>
                )}

                <Step key={'dayEnd'}>
                    <StepIndicator>
                        <StepStatus complete={<MdModeNight />} incomplete={<MdModeNight />} active={<MdModeNight />} />
                    </StepIndicator>
                </Step>
            </Stepper>
        );
    }

    return null;
};

type BreakVisualizerProps = {
    data: PairedShift;
};

export const ShiftsBreakVisualizer: React.FC<BreakVisualizerProps> = ({ data }) => {
    const { t, formatTime } = useSuIntl();
    if (data?.breaks?.length === 0 && !data?.breakDuration) {
        return null;
    }

    return (
        <Box key={'StepVisualizer'}>
            {data.breaks?.map((breakPeriod, index) => (
                <>
                    <Step key={breakPeriod.start} style={{ marginTop: 4, marginBottom: 10 }}>
                        <StepIndicator>
                            <StepStatus complete={<MdPause />} incomplete={<MdPause />} active={<FiLogIn />} />
                        </StepIndicator>
                        <TextValueField smallVariant label={t('attendance.breakStart')} value={formatTime(breakPeriod.start)} />
                        <StepSeparator />
                    </Step>

                    <Step key={breakPeriod.end} style={{ marginBottom: 4 }}>
                        <StepIndicator>
                            <StepStatus complete={<MdPlayArrow />} incomplete={<MdPlayArrow />} active={<FiLogIn />} />
                        </StepIndicator>
                        <TextValueField smallVariant label={t('attendance.breakEnd')} value={formatTime(breakPeriod.end)} />
                        <StepSeparator />
                    </Step>
                </>
            ))}

            {!data?.breaks?.length && data?.breakDuration && (
                <Step key={'breakDurationStep'}>
                    <StepIndicator marginBottom={10}>
                        <StepStatus complete={<MdAlarm />} incomplete={<MdAlarm />} active={<FiLogIn />} />
                    </StepIndicator>
                    <TextValueField label={t('attendance.break')} value={`${data.breakDuration}h`} />
                    <StepSeparator />
                </Step>
            )}
        </Box>
    );
};

export const ReportBreakTextVisualizer: React.FC<ReportsBreaksVisualizerProps> = ({
    breaks,
    breakDurationMinutes,
    maxShiftWithoutBreakInHours,
}) => {
    const intl = useIntl();
    const {t} = useSuIntl();
    return (
        <Stack>
            {breaks?.map((breakPeriod, _index) => (
                <TimeRangeVisualizer smallVariant dateFrom={intl.formatTime(breakPeriod.start)} dateTo={intl.formatTime(breakPeriod.end)} />
            ))}
            {!!breakDurationMinutes && (
                <TextValueField smallVariant label={t('attendance.break')} value={`${breakDurationMinutes}min`} icon={<MdAlarm />} />
            )}
            {!!maxShiftWithoutBreakInHours && (
                <TextValueField
                    smallVariant
                    label={t('attendance.maxShiftWithoutBreak')}
                    value={`${maxShiftWithoutBreakInHours}h`}
                    icon={<MdAlarm />}
                />
            )}
        </Stack>
    );
};
