import {
    AttendanceLogin,
    AttendanceLoginDetail,
    AttendanceLoginHistory,
    AttendanceReport,
    AttendanceReportFilter,
    BasePagination,
    BasePaginationFilter,
    BusinessIdentity,
    Company,
    EmployeesFilter,
    LoginPagination,
    LoginsFilter,
    Marker,
    Role,
    Statistics,
    User,
    UserPagination,
    Zone,
    ZonePagination,
    ZonesFilter,
} from '../../types';
import { AttendanceStatement, AttendanceStatementSourceData, AttendanceStatementUpdateData, DocumentType } from '../../types/documents';
import { AccountMode } from '../../types/enums/AccountMode';
import apiClient, { addParametersToString } from '../apiClient';

class CompanyService {
    async addUserToCompanyByCode(inviteCode: string) {
        return (await apiClient.post('/api/company/invite', { code: inviteCode })).data;
    }

    async createCompany(data: { companyName: string }) {
        return (await apiClient.post<Company>('/api/company', { name: data.companyName })).data;
    }

    async getCompanyDetail() {
        return (await apiClient.get<Company>('/api/company')).data;
    }

    async getCompanyStatistics(params: { dateFrom?: string; dateTo?: string }) {
        return (await apiClient.get<Statistics>(`/api/company/statistics${addParametersToString(params)}`)).data;
    }

    async getEmployeeProfileDetail(id: number) {
        return (await apiClient.get<User>(`/api/company/employees/${id}`)).data;
    }

    async getEmployeesPaginated(filter: EmployeesFilter) {
        return (
            await apiClient.get<UserPagination>(
                `/api/company/employees${addParametersToString({
                    ...filter,
                    page: filter?.page ? filter.page : 1,
                    perPage: filter?.perPage ? filter.perPage : 20,
                    search: filter?.search ? filter.search : '',
                })}`
            )
        ).data;
    }

    async getZoneById(id: number) {
        return (await apiClient.get<Zone>(`/api/company/zones/${id}`)).data;
    }

    async getAttendanceById(id: number) {
        return (await apiClient.get<AttendanceLoginDetail>(`/api/attendance/logins/${id}`)).data;
    }

    async getZonesPaginated(filter: ZonesFilter) {
        return (
            await apiClient.get<ZonePagination>(
                `/api/company/zones${addParametersToString({
                    ...filter,
                    page: filter?.page ? filter.page : 1,
                    perPage: filter?.perPage ? filter.perPage : 20,
                    search: filter?.search ? filter.search : '',
                })}`
            )
        ).data;
    }

    async getAttendancePaginated(filter: LoginsFilter) {
        return (
            await apiClient.post<LoginPagination>('/api/attendance/logins/filter', {
                ...filter,
                page: filter?.page ? filter.page : 1,
                perPage: filter?.perPage ? filter.perPage : 20,
            })
        ).data;
    }

    async createAttendancePeriodReport(filter: AttendanceReportFilter) {
        return (await apiClient.post<AttendanceReport[]>('/api/attendance/reports', filter)).data;
    }

    async updateAttendance(id: number, data: Record<string, any>) {
        return (await apiClient.put<AttendanceLogin>(`/api/attendance/logins/${id}`, data)).data;
    }

    async deleteAttendance(id: number) {
        return (await apiClient.delete<void>(`/api/attendance/logins/${id}`)).data;
    }

    async createManualAttendance(data: Record<string, any>) {
        return (await apiClient.post<AttendanceLogin>('/api/attendance/logins/manual', data)).data;
    }

    async createEmployee(data: {
        userName: string;
        password: string;
        firstName: string;
        surname?: string;
        inviteCode?: string;
        role: Role;
        mode?: AccountMode;
    }) {
        return (await apiClient.post<User>('/api/company/employees', data)).data;
    }

    async updateEmployee(user: Partial<User> & { image?: File }) {
        const formData = new FormData();
        let jsonData: Record<string, any> = {};
        if (user.image) {
            formData.append('id', user.id!.toString());
            if (user.firstName) {
                formData.append('firstName', user.firstName);
            }

            if (user.surname) {
                formData.append('surname', user.surname);
            }

            if (user.role) {
                formData.append('role', user.role);
            }

            if (user.password) {
                formData.append('password', user.password);
            }
            formData.append('image', user.image);
        } else {
            if (user.settings?.id) {
                delete user.settings.id;
            }

            jsonData = {
                id: user.id,
                firstName: user.firstName,
                surname: user.surname,
                role: user.role,
                password: user.password,
                settings: user.settings,
            };
        }
        return (await apiClient.post<User>(`/api/profile/update/data`, user.image ? formData : jsonData)).data; // TODO to be replaced with new endpoint
    }

    async updateEmployeeIdentity(userid: number, data: BusinessIdentity) {
        return (await apiClient.put<User>(`/api/company/employees/${userid}/business-identity`, data)).data;
    }

    async getAttendanceHistory(loginId: number, filter: BasePaginationFilter) {
        return (
            await apiClient.get<BasePagination<AttendanceLoginHistory>>(
                `/api/attendance/logins/${loginId}/history${addParametersToString(filter)}`
            )
        ).data;
    }

    async updateCompany(companyData: Partial<BusinessIdentity> & { logo?: File }) {
        let body: any;
        if (companyData?.logo) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(companyData)) {
                formData.append(key, value as string);
            }
            body = formData;
        } else {
            body = companyData;
        }

        return (await apiClient.put<Company>('/api/company', body)).data;
    }

    async createZone(data: { name: string; markers: Marker[] }) {
        return (await apiClient.post<Zone>('/api/company/zones', data)).data;
    }

    async updateZone(data: { zoneId: number; markers: Marker[]; name: string }) {
        return (await apiClient.put<Zone>(`/api/company/zones`, data)).data;
    }

    async deleteZone(id: number) {
        return (await apiClient.delete<void>(`/api/company/zones/${id}`)).data;
    }

    async getArchivedEmployees(filter: BasePaginationFilter) {
        return (await apiClient.get<UserPagination>(`/api/company/employees/archive${addParametersToString(filter)}`)).data;
    }

    async restoreArchivedUser(userId: number) {
        return (await apiClient.put<User>(`/api/company/employees/archive/${userId}`)).data;
    }

    async deleteEmployee(userId: number) {
        return (await apiClient.delete<void>(`/api/company/employees/${userId}`)).data;
    }

    async getZonesArchive(filter: BasePaginationFilter) {
        return (await apiClient.get<ZonePagination>(`/api/company/zones/archive${addParametersToString(filter)}`)).data;
    }

    async getArchivedZoneDetail(id: number) {
        return (await apiClient.get<Zone>(`/api/company/zones/archive/${id}`)).data;
    }

    async uploadAttendanceStatement(data: AttendanceStatementSourceData) {
        return (await apiClient.post('/api/attendance/statements', data)).data;
    }

    async updateAttendanceStatement(id: number, data: AttendanceStatementUpdateData) {
        return (await apiClient.put(`/api/attendance/statements/${id}`, data)).data;
    }

    async deleteAttendanceStatement(id: number) {
        return (await apiClient.delete<void>(`/api/attendance/statements/${id}`)).data;
    }

    async archiveAttendanceStatement(id: number) {
        return (await apiClient.put<AttendanceStatement>(`/api/attendance/statements/archive/${id}`)).data;
    }

    async getUploadedStatements(filter: BasePaginationFilter) {
        return (
            await apiClient.get<BasePagination<any>>('/api/attendance/statements', {
                params: {
                    ...filter,
                    documentType: DocumentType.ATTENDANCE_STATEMENT,
                },
            })
        ).data;
    }

    async getUploadedStatementDetail(id: number) {
        return (await apiClient.get<AttendanceStatement>(`/api/attendance/statements/${id}`)).data;
    }
}

export default new CompanyService();
