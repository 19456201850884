import React from 'react';
import {
    Box,
    Card,
    Flex,
    Heading,
    useColorModeValue,
    Text,
    Image,
    useTheme,
    VStack,
    CardBody,
    Radio,
    Tooltip,
    RadioGroup,
    useToast,
    Button,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import SchemaProvider from '../../utils/SchemaProvider';
import { Form, Formik } from 'formik';
import { InferType } from 'yup';
import FormikTextInput from '../../components/formik/FormikTextInput';
import { CheckIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useAddUserToCompany, useCreateCompany } from '../../query/resource-hooks/company';
import { useNavigate } from 'react-router-dom';

enum CompanyMode {
    CREATE = 'CREATE', // Register and create new company
    JOIN = 'JOIN', // Register and join existing company with code
}

const JoinCompanyScreen: React.FC = () => {
    const intl = useIntl();
    const theme = useTheme();
    const createCompany = useCreateCompany(onSuccess, onError);
    const joinCompany = useAddUserToCompany(onSuccess, onError);
    const toast = useToast();
    const navigate = useNavigate();
    const companySetupSchema = SchemaProvider.getRegisterSchema(intl).companySetup;

    const handleSubmit = (values: InferType<typeof companySetupSchema>) => {
        console.log(values);

        if (values.companyMode === CompanyMode.JOIN) {
            joinCompany.mutate({ inviteCode: values.inviteCode! });
        } else createCompany.mutate({ companyName: values.companyName! });
    };

    function onError() {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            description: intl.formatMessage({ id: 'somethingWentWrong.tryLater' }),
            status: 'error'
        });
    }

    function onSuccess() {
        navigate('/home');
    }

    const headingColor = useColorModeValue('white', 'gray.100');
    const textColor = useColorModeValue('gray.300', 'gray.300');
    return (
        <Box minH="100vh" justifyContent={'center'} display={'flex'} bg={useColorModeValue('gray.100', 'gray.900')}>
            <Box w={'100%'} maxW={'1000'} p={{ base: 4, md: 8 }}>
                <Card w={'100%'} borderRadius={'lg'}>
                    <Flex minH={600} direction={{ base: 'column', md: 'row' }}>
                        <Box maxW={{ md: '380px' }} bg={theme.colors.primary[500]} p="5" borderRadius={'lg'}>
                            <Heading color={headingColor} size="lg">
                                {intl.formatMessage({ id: 'company' })}
                            </Heading>
                            <Text color={textColor} mt="4">
                                {intl.formatMessage({ id: 'company.setup.text' })}
                            </Text>
                            <Image
                                display={{ base: 'none', md: 'block' }}
                                justifySelf={'center'}
                                src={`${process.env.PUBLIC_URL}/logo512.png`}
                                alt="Supervizer logo"
                            />
                        </Box>
                        <Box p="5" display={'flex'} flex={1} flexDir={'column'}>
                            <Formik
                                onSubmit={handleSubmit}
                                initialValues={{ companyMode: CompanyMode.CREATE, companyName: '', inviteCode: '', captchaVerified: true }}
                                validationSchema={companySetupSchema}
                            >
                                {({ errors, touched, handleChange, handleBlur, values }) => (
                                    <Form>
                                        <RadioGroup onChange={handleChange('companyMode')} value={values['companyMode']}>
                                            <VStack spacing={2} pt={4}>
                                                <Card
                                                    width={'100%'}
                                                    variant={values['companyMode'] === CompanyMode.CREATE ? 'outline' : 'filled'}
                                                    onClick={() => handleChange('companyMode')(CompanyMode.CREATE)}
                                                    borderRadius={'xl'}
                                                >
                                                    <Radio pt={2} pl={2} value={CompanyMode.CREATE}>
                                                        <Text fontWeight={600}>{intl.formatMessage({ id: 'createCompany' })}</Text>
                                                    </Radio>
                                                    <CardBody>
                                                        <FormikTextInput
                                                            errors={errors}
                                                            touched={touched}
                                                            name="companyName"
                                                            fieldName="companyName"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            label={intl.formatMessage({ id: 'companyName' })}
                                                            values={values}
                                                            isDisabled={values['companyMode'] === CompanyMode.JOIN}
                                                        />
                                                    </CardBody>
                                                </Card>

                                                <Card
                                                    width={'100%'}
                                                    variant={values['companyMode'] === CompanyMode.JOIN ? 'outline' : 'filled'}
                                                    onClick={() => handleChange('companyMode')(CompanyMode.JOIN)}
                                                    borderRadius={'xl'}
                                                >
                                                    <Radio pt={2} pl={2} value={CompanyMode.JOIN}>
                                                        <Text fontWeight={600}>
                                                            {intl.formatMessage({ id: 'joinCompany' })}{' '}
                                                            <Tooltip label={intl.formatMessage({ id: 'insertInviteCodeText' })}>
                                                                <InfoOutlineIcon />
                                                            </Tooltip>
                                                        </Text>
                                                    </Radio>
                                                    <CardBody>
                                                        <FormikTextInput
                                                            errors={errors}
                                                            touched={touched}
                                                            name="inviteCode"
                                                            fieldName="inviteCode"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            label={intl.formatMessage({ id: 'inviteCode' })}
                                                            values={values}
                                                            isDisabled={values['companyMode'] === 'CREATE'}
                                                        />
                                                    </CardBody>
                                                </Card>
                                                <Button isLoading={joinCompany.isLoading || createCompany.isLoading} mt={2} rightIcon={<CheckIcon />} colorScheme="green" type="submit">
                                                    {intl.formatMessage({ id: 'form.submit' })}
                                                </Button>
                                            </VStack>
                                        </RadioGroup>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Flex>
                </Card>
            </Box>
        </Box>
    );
};

export default JoinCompanyScreen;
