import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ComponentProps } from 'react';
import EmployeesSelectList, { EmployeeSelectListProps } from './EmployeesSelectList';
import { useIntl } from 'react-intl';

type EmployeeSelectModalProps = Omit<ComponentProps<typeof Modal>, 'children'> & EmployeeSelectListProps;

export const EmployeeSelectModal: React.FC<EmployeeSelectModalProps> = ({ onSelect, selectedUserId, isOpen, onClose }) => {
    const intl = useIntl();
    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{intl.formatMessage({id: 'selectAccount'})}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <EmployeesSelectList onSelect={onSelect} selectedUserId={selectedUserId} />
                </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>{intl.formatMessage({id: 'form.select'})}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
