import * as React from 'react';
import { ChakraProvider, extendTheme, ThemeConfig, withDefaultColorScheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import router from './routing/routes';
import { IntlProvider } from 'react-intl';
import { getLanguageMessages, isLanguageAvailable } from './i18n/languages';
import { useSettingsStore } from './store/ui/SettingsStore';
import useAOS from './hooks/useAos';
import { tabsTheme } from './components/about/FeatureTabs/FeatureTabs';
import { customButtonTheme } from './themes/customCommonThemes';

export const App = () => {
    const customLocale = useSettingsStore((state) => state.language);
    const customTheme = extendTheme(
        {
            colors: {
                primary: {
                    '50': '#fff7de',
                    '100': '#fdde74',
                    '200': '#f5be00',
                    '300': '#FCC300',
                    '400': '#b88e00',
                    '500': '#9b7800',
                    '600': '#836500',
                    '700': '#695100',
                    '800': '#594500',
                    '900': '#403200',
                },
                secondary: {
                    '50': '#FFF9E5',
                    '100': '#FFEEB8',
                    '200': '#FCC300',
                    '300': '#FFD95C',
                    '400': '#FFCE2E',
                    '500': '#FFC300',
                    '600': '#CC9C00',
                    '700': '#997500',
                    '800': '#664E00',
                    '900': '#332700',
                },
                errorElevation: {
                    '50': '#39252B',
                    '100': '#4A1F23',
                    '200': '#5B191B',
                    '300': '#6C1313',
                    '400': '#7D0D0B',
                },
                warningElevationColors: {
                    '50': '#FFF3CD',
                    '100': '#FFECB3',
                    '200': '#FFE082',
                    '300': '#FFD54F',
                    '400': '#FFCA28',
                    '500': '#FFCA28',
                    '600': '#FFCA28',
                    '700': '#FFCA28',
                    '800': '#FFCA28',
                    '900': '#FFCA28',
                },
                surface: {
                    '50': '#010815',
                    '100': '#010815',
                    '200': '#010815',
                    '300': '#010815',
                    '400': '#010815',
                    '500': '#010815',
                    '600': '#010815',
                    '700': '#010815',
                    '800': '#010815',
                    '900': '#010815',
                },
                elevation: {
                    '50': '#1D1B20',
                    '100': '#1D1B20',
                    '200': '#1D1B20',
                    '300': '#1D1B20',
                    '400': '#1D1B20',
                    '500': '#1D1B20',
                    '600': '#1D1B20',
                    '700': '#1D1B20',
                    '800': '#1D1B20',
                    '900': '#1D1B20',
                },
                primaryAbout: {
                    '50': '#594500',
                    '100': '#FCC300',
                    '200': '#FCC300',
                    '300': '#FCC300',
                    '400': '#FCC300',
                    '500': '#FCC300',
                    '600': '#FCC300',
                    '700': '#FCC300',
                    '800': '#FCC300',
                    '900': '#FCC300',
                }
            },
            config: {
                initialColorMode: 'dark',
                useSystemColorMode: false,
            } as ThemeConfig,
            components: {
                Tabs: tabsTheme,
                Button: customButtonTheme,
            },
        },
        withDefaultColorScheme({
            colorScheme: 'primary',
        })
    );

    const queryClient = new QueryClient();

    const getUsedLocale = () => {
        if (customLocale) {
            return customLocale;
        }

        const browserLanguage = navigator.language?.slice(0, 2)?.toLowerCase();

        if (isLanguageAvailable(browserLanguage)) {
            return browserLanguage;
        }

        return 'sk';
    };
    
    return (
        useAOS(),
        (
            <IntlProvider defaultLocale="sk" locale={getUsedLocale()} messages={getLanguageMessages(getUsedLocale())}>
                <ChakraProvider theme={customTheme}>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </ChakraProvider>
            </IntlProvider>
        )
    );
};
