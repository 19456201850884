import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Box, Button, ButtonGroup, Heading, VStack, Divider, ScaleFade } from '@chakra-ui/react';
import { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { convertToUserTime } from '../../../utils/timeUtils';
import { SuNumberInput } from '../../common/SuNumberInput';
import { TimePeriodInput } from '../../common/TimePeriodInput';
import { useSuIntl } from '../../../hooks/useSuIntl';
import moment from 'moment';

export type BreakCreatorData = {
    breaks?: { start: string; end: string }[];
    breakDurationInMinutes?: number;
    maxShiftWithoutBreakInHours?: number;
};

type BreakCreatorProps = {
    onChange: (data: BreakCreatorData) => void;
    initialData?: BreakCreatorData;
    requireConfirmation?: boolean;
};

enum BreakType {
    FIXED = 'FIXED',
    PERIOD = 'PERIOD',
}

export const BreakCreator: React.FC<BreakCreatorProps> = ({ onChange, initialData, requireConfirmation }) => {
    const { t } = useSuIntl();
    const [breaks, setBreaks] = useState<{ id: string; start: string; end: string }[]>(
        initialData?.breaks
            ? initialData.breaks.map((dates) => ({
                  id: uuidv4(),
                  start: convertToUserTime(dates.start),
                  end: convertToUserTime(dates.end),
              }))
            : [{ id: uuidv4(), start: '', end: '' }]
    );

    const [breakDuration, setBreakDuration] = useState<number>(initialData?.breakDurationInMinutes ?? 60);
    const [maxShiftWithoutBreak, setMaxShiftWithoutBreak] = useState<number>(initialData?.maxShiftWithoutBreakInHours ?? 7);
    const [mode, setMode] = useState<BreakType>(BreakType.FIXED);

    const addBreak = () => {
        setBreaks([...breaks, { id: uuidv4(), start: '', end: '' }]);
    };

    function callChange(
        mode: BreakType,
        updatedBreaks?: { id: string; start: string; end: string }[],
        newBreakDuration?: number,
        newMaxShift?: number
    ) {
        if (!requireConfirmation) {
            if (mode === BreakType.PERIOD) {
                return onChange({
                    breaks: (updatedBreaks || breaks)
                        .filter(({ start, end }) => !!start && !!end)
                        .map(({ start, end }) => ({ start, end })),
                });
            } else {
                return onChange({
                    breakDurationInMinutes: newBreakDuration ?? breakDuration,
                    maxShiftWithoutBreakInHours: newMaxShift ?? maxShiftWithoutBreak,
                });
            }
        }
    }

    function handleConfirm() {
        if (mode === BreakType.PERIOD) {
            return onChange({
                breaks: breaks.filter(({ start, end }) => !!start && !!end).map(({ start, end }) => ({ start, end })),
            });
        } else {
            return onChange({
                breakDurationInMinutes: breakDuration,
                maxShiftWithoutBreakInHours: maxShiftWithoutBreak,
            });
        }
    }

    function changeMode(mode: BreakType) {
        setMode(mode);
        const defaultBreaks = [{ id: uuidv4(), start: '', end: '' }];

        setBreaks(defaultBreaks);
        callChange(mode, defaultBreaks);
    }

    const removeBreak = (id: string) => {
        const filteredBreaks = breaks.filter((breakItem) => breakItem.id !== id);
        setBreaks(filteredBreaks);
        callChange(mode, filteredBreaks);
    };

    const handleBreakChange = (updatedId: string, data: { start: string; end: string }) => {
        const targetBreak = breaks.find((item) => item.id === updatedId);
        if (targetBreak && (targetBreak.start !== data.start || targetBreak.end !== data.end)) {
            const updatedBreaks = [...breaks].map((existingItem) =>
                updatedId === existingItem.id ? { id: existingItem.id, ...data } : existingItem
            );

            setBreaks(updatedBreaks);

            callChange(mode, updatedBreaks);
        }
    };

    const memoizedBreaks = useMemo(() => breaks, [breaks]);

    return (
        <Box>
            <HStack mb={2} justifyContent={'space-between'}>
                <Heading size={'sm'}>{t('attendance.breaks')}</Heading>
                <ButtonGroup isAttached borderRadius={'xl'}>
                    <Button
                        onClick={() => changeMode(BreakType.PERIOD)}
                        borderRadius={'lg'}
                        size={'sm'}
                        variant={mode === BreakType.PERIOD ? 'solid' : 'outline'}
                    >
                        {t('editor.breaks.timeRange')}
                    </Button>
                    <Button
                        onClick={() => changeMode(BreakType.FIXED)}
                        borderRadius={'lg'}
                        size={'sm'}
                        variant={mode === BreakType.FIXED ? 'solid' : 'outline'}
                    >
                        {t('editor.breaks.fixedTime')}
                    </Button>
                </ButtonGroup>
            </HStack>
            <ScaleFade initialScale={0.9} in key={mode}>
                {mode === BreakType.PERIOD ? (
                    <VStack mt={2} gap={0}>
                        {memoizedBreaks.map((breakItem) => (
                            <ScaleFade in key={breakItem.id}>
                                <Box key={breakItem.id} width="100%">
                                    <Divider my={2} />
                                    <TimePeriodInput
                                        index={breakItem.id}
                                        onEndTimeChange={(value) => handleBreakChange(breakItem.id, { ...breakItem, end: value })}
                                        onStartTimeChange={(value) => handleBreakChange(breakItem.id, { ...breakItem, start: value })}
                                        startTime={breakItem.start ? moment(breakItem.start).format('HH:mm') : ''}
                                        endTime={breakItem.end ? moment(breakItem.end).format('HH:mm') : ''}
                                        onRemove={removeBreak}
                                    />
                                </Box>
                            </ScaleFade>
                        ))}
                        <HStack justify={'right'} width={'100%'} pt={3}>
                            <IconButton
                                colorScheme="green"
                                isDisabled={breaks.length >= 5}
                                onClick={addBreak}
                                aria-label="add pause"
                                size={'sm'}
                                icon={<AddIcon />}
                            />
                        </HStack>
                    </VStack>
                ) : (
                    <VStack mt={4}>
                        <SuNumberInput
                            w={'100%'}
                            title={t('attendance.breakDuration')}
                            onChange={(value) => {
                                setBreakDuration(parseInt(value));
                                callChange(mode, undefined, parseInt(value));
                            }}
                            value={breakDuration}
                            format={(value) => `${value} min`}
                            step={10}
                        />
                        <SuNumberInput
                            title={t('attendance.maxShiftWithoutBreak')}
                            onChange={(value) => {
                                setMaxShiftWithoutBreak(parseInt(value));
                                callChange(mode, undefined, breakDuration, parseInt(value));
                            }}
                            value={maxShiftWithoutBreak}
                            step={1}
                            max={16}
                            format={(value) => `${value}h`}
                        />
                    </VStack>
                )}
            </ScaleFade>
            {requireConfirmation && (
                <Button mt={4} colorScheme="green" onClick={handleConfirm} rightIcon={<CheckIcon />}>
                    {t('editor.use')}
                </Button>
            )}
        </Box>
    );
};
