import { useIntl } from 'react-intl';

export const useSuIntl = () => {
    const intl = useIntl();

    function t(id: string, values?: Record<string, any>): string {
        return intl.formatMessage({ id }, values);
    }

    function formatTime(value: Parameters<Intl.DateTimeFormat['format']>[0] | string, options?: Intl.DateTimeFormatOptions) {
        if (!value) return '';
        return intl.formatTime(value, options);
    }

    return { t, formatDate: intl.formatDate, formatTime };
};
