import { Box, Divider, Heading, HStack, ListItem, Text, UnorderedList, useTheme, VStack } from '@chakra-ui/react';
import { AboutLayout } from '../../components/about/AboutHeader';
import { useIntl } from 'react-intl';
import { PolicyQuestionResponse } from './PolicyQuestionResponse';

const PolicyScreen: React.FC = () => {
    const theme = useTheme();
    const intl = useIntl();
    return (
        <AboutLayout>
            <Box color={'gray.200'}>
                <HStack spacing={2} my={10} maxWidth={'100%'}>
                    <Divider borderColor={'gray.400'} />
                    <Box width={'100%'} minWidth={'fit-content'}>
                        <Heading size={'lg'} color={theme.colors.primary[500]} textAlign={'center'}>
                            {intl.formatMessage({ id: 'privacyPolicy' })}
                        </Heading>
                        <Text size={'lg'} textAlign={'center'}>
                            Kontakt: info.supervizer@gmail.com
                        </Text>
                    </Box>
                    <Divider borderColor={'gray.400'} />
                </HStack>
                <Box maxW={'1140px'} mx={'auto'} px={2}>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <Text>
                                Pri poskytovaní nášho systému od Vás získavame Vaše osobné údaje. Pri spracúvaní osobných údajov ste
                                dotknutou osobou, t. j. osobou o ktorej sú spracúvané osobné údaje, ktoré sa jej týkajú.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                Vaše osobné údaje budú uchovávané bezpečne, len po dobu nevyhnutnú na splnenie účelu spracúvania t.j. po
                                dobu existencie Vášho účtu v našom systéme . Prístup k Vašim osobným údajom budete mať výlučne vy, a ľudia
                                ktorým to sami umožníte v rámci nastavenia systému. Po vymazaní účtu budú všetky Vaše údaje odstránené zo
                                všetkých našich úložísk.
                            </Text>
                        </ListItem>
                    </UnorderedList>
                    <VStack textAlign={'left'} alignItems={'start'} gap={4}>
                        <PolicyQuestionResponse
                            question="Na základe čoho budú Vaše údaje spracúvané?"
                            response="Vaše osobné údaje budú spracúvané na základe vašej registrácie do systému, čiže súhlasu."
                        />
                        <PolicyQuestionResponse
                            question="Kto sú príjemcovia Vašich osobných údajov?"
                            response="Vaše údaje nebudú sprístupnené žiadnym tretím stranám s možnou výnimkou poskytovateľa platobnej brány Stripe."
                        />
                        <PolicyQuestionResponse
                            question="Budú Vaše osobné údaje poskytnuté mimo Európskej únie?"
                            response="Prenos osobných údajov do tretej krajiny alebo medzinárodnej organizácie sa neuskutočňuje."
                        />
                        <PolicyQuestionResponse
                            question="Budú Vaše údaje použité na automatizované individuálne rozhodovanie?"
                            response="Osobné údaje nebudú použité na automatizované individuálne rozhodovanie vrátane profilovania."
                        />
                        <PolicyQuestionResponse
                            question="Ako dlho budú Vaše osobné údaje uchovávané?"
                            response="Vaše údaje budú uchovávané po dobu existencie Vášho účtu v systéme."
                        />
                        <PolicyQuestionResponse
                            question="Aké údaje získavame?"
                            response="Získavame len údaje nevyhnutné pre správne fungovanie systému, ako napríklad: meno, priezvisko, fotografiu, e-mailovú adresu"
                        />
                        <PolicyQuestionResponse
                            question="Na Čo a kedy bude použitá moja fotografia?"
                            response="Fotografie sú použité výlučne na overenie totožnosti pri zaznamenávaní dochádzky. Fotografie sú prístupné len registrovaným, oprávneným osobám spojeným s Vašim účtom v systéme."
                        />
                        <PolicyQuestionResponse
                            question="Na Čo a kedy sú použité moje biometrické údaje?"
                            response="Biometrické údaje sú použité výlučne pri zaznamenávaní dochádzky. Biometrické údaje neukladáme ani neposkytujeme tretím stranám."
                        />
                        <Box>
                            <Heading textDecoration={'underline'} size={'xs'}>
                                Aké máte práva ako dotknutá osoba?
                            </Heading>
                            <UnorderedList mb={4}>
                                <ListItem>
                                    Odvolať súhlas - v prípadoch, kedy Vaše osobné údaje spracúvame na základe Vášho súhlasu, máte právo
                                    tento súhlas kedykoľvek odvolať. Súhlas môžete odvolať elektronicky, na adrese zodpovednej osoby.
                                </ListItem>
                                <ListItem>
                                    Právo na prístup - máte právo na poskytnutie kópie osobných údajov, ktoré o Vás máme k dispozícii, ako
                                    aj na informácie o tom, ako Vaše osobné údaje spracúvame. Vo väčšine prípadov Vám budú Vaše osobné údaje
                                    poskytnuté v elektronickej forme.
                                </ListItem>
                                <ListItem>
                                    Právo na opravu - prijímame primerané opatrenia, aby sme zabezpečili presnosť, úplnosť a aktuálnosť
                                    informácií, ktoré o Vás máme k dispozícii. Ak sú Vaše osobné údaje, ktorými disponujeme nepresné,
                                    neúplné alebo neaktuálne, prosím, neváhajte nás požiadať, aby sme tieto informácie upravili,
                                    aktualizovali alebo doplnili. Väčšinou môžete všetky informácie upraviť samostatne v nastaveniach
                                    systému.
                                </ListItem>
                                <ListItem>
                                    Právo na výmaz (na zabudnutie) - máte právo nás požiadať o vymazanie Vašich osobných údajov. Údaje sú
                                    vymazané vždy spoločne s celým účtom a všetkými s ním súvisiacimi dátami.
                                </ListItem>
                                <ListItem>
                                    Právo na obmedzenie spracúvania - za určitých okolností máte ako dotknutá osoba právo požiadať nás o
                                    obmedzenie spracúvania Vašich osobných údajov . Ide napríklad o prípady, keď si myslíte, že osobné
                                    údaje, ktoré o Vás máme, môžu byť nepresné alebo keď si myslíte, že už Vaše osobné údaje nepotrebujeme
                                    využívať.
                                </ListItem>
                                <ListItem>
                                    Právo na prenosnosť údajov - za určitých okolností máte právo požiadať nás o prenos Vašich osobných
                                    údajov, ktoré ste nám poskytli, inému prevádzkovateľovi. Právo na prenosnosť sa však týka len osobných
                                    údajov, ktoré sme od Vás získali na základe súhlasu alebo na základe zmluvy, ktorej ste zmluvnou stranou
                                    a zároveň sa spracúvanie predmetných osobných údajov vykonáva automatizovanými prostriedkami a prenos
                                    týchto osobných údajov je technicky možný.
                                </ListItem>
                                <ListItem>
                                    Právo namietať - máte právo namietať voči spracúvaniu údajov, ktoré je založené na našich oprávnených
                                    záujmoch alebo za účelom splnenia nám zverených úloh vo verejnom záujme alebo pri výkone verejnej moci.
                                    V prípade, ak nemáme preukázateľný legitímny oprávnený dôvod na predmetné spracúvanie Vašich osobných
                                    údajov a Vy podáte námietku, nebudeme Vaše osobné údaje ďalej spracúvať.
                                </ListItem>
                                <ListItem>
                                    Právo podať návrh na začatie konania o ochrane osobných údajov - ak sa domnievate, že spracúvaním Vašich
                                    osobných údajov dochádza k porušovaniu Vašich práv ako dotknutej osoby, máte právo podať návrh na
                                    začatie konania v zmysle ustanovenia § 100 zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a
                                    doplnení niektorých zákonov na príslušný dozorný orgán, ktorým je Úrad na ochranu osobných údajov
                                    Slovenskej republiky, Hraničná 12, 820 07 Bratislava 27; tel. číslo: +421 /2/ 3231 3214; mail:
                                    statny.dozor@pdp.gov.sk, https://dataprotection.gov.sk. V prípade podania návrhu elektronickou formou je
                                    potrebné, aby spĺňal náležitosti podľa § 19 ods. 1 zákona č. 71/1967 Zb. o správnom konaní (správny
                                    poriadok).
                                </ListItem>
                            </UnorderedList>
                        </Box>
                    </VStack>
                </Box>
            </Box>
        </AboutLayout>
    );
};

export default PolicyScreen;
