import React from 'react';
import { Select as ChakraSelect, SelectProps as ChakraSelectProps } from '@chakra-ui/react';

interface Option {
    value: string | number;
    label: string;
}

interface CustomSelectProps extends ChakraSelectProps {
    options: Option[];
    selectedOption?: string | number;
}

const SuSelect: React.FC<CustomSelectProps> = ({ options, selectedOption, ...props }) => {
    return (
        <ChakraSelect
            fontWeight="500"
            value={selectedOption}
            {...props}
            
        >
            {options.map((option) => (
                <option style={{margin: 1}} key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </ChakraSelect>
    );
};

export default SuSelect;
