export enum TimeFilterValue {
    TODAY = 'TODAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    ALL = 'ALL',
}

export enum TimeGroup {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}


export enum RoundType  {
    NORMAL = 'NORMAL',
    UP = 'UP',
    DOWN = 'DOWN',
}