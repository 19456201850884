import {
    Badge,
    Card,
    CardBody,
    FormLabel,
    Heading,
    HStack,
    Icon,
    IconButton,
    Wrap,
    WrapItem,
    Tag,
    TagLabel,
    TagCloseButton,
    PopoverTrigger,
    Box,
} from '@chakra-ui/react';
import { LoginDirection } from '../../types';
import { CalendarIcon, CloseIcon } from '@chakra-ui/icons';
import { RangeDatepicker } from '../calendarPicker';
import { LoginDirectionButton } from '../ui/LogindirectionButton';
import { useIntl } from 'react-intl';
import React from 'react';
import { isSameDay } from 'date-fns';
import { TimeRangeVisualizer } from '../common/DateRangeVisualizer';

type AttendanceListFilterProps = {
    onDateChange: (dates: Date[]) => void;
    selectedDates: Date[];
    filterLoginDirection: LoginDirection;
    onFilterLoginDirectionChange: (loginDirection: LoginDirection) => void;
    totalRecords?: number;
    [key: string]: any;
};

export const FilterChip: React.FC<{
    label: string;
    onClear: () => void;
    selected: boolean;
    onSelect: () => void;
    size?: string;
    colorScheme?: string;
    borderRadius?: string;
}> = ({ label, onClear, selected, onSelect, size = 'md', colorScheme = 'primary', borderRadius = 'xl' }) => {
    function handleClick() {
        if (selected) {
            return onClear();
        }

        onSelect();
    }

    return (
        <HStack spacing={4} onClick={handleClick} cursor={'pointer'}>
            <Tag colorScheme={colorScheme} borderRadius={borderRadius} size={size} key={label} variant={selected ? 'solid' : 'outline'}>
                <TagLabel style={{ textTransform: 'capitalize' }}>{label}</TagLabel>
                {selected && <TagCloseButton />}
            </Tag>
        </HStack>
    );
};

export const AttendanceListFilter: React.FC<AttendanceListFilterProps> = ({
    onDateChange,
    onFilterLoginDirectionChange,
    filterLoginDirection,
    selectedDates,
    totalRecords,
    ...restCardProps
}) => {
    const intl = useIntl();

    function isChipSelected(chipType: 'day' | 'week' | 'month'): boolean {
        let start = new Date();
        let end = new Date();
        switch (chipType) {
            case 'day':
                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);
                break;
            case 'week':
                start.setHours(0, 0, 0, 0);
                start.setDate(start.getDate() - start.getDay());
                end.setHours(23, 59, 59, 999);
                end.setDate(start.getDate() + 6);
                break;
            case 'month':
                start.setDate(1);
                start.setHours(0, 0, 0, 0);
                end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
                end.setHours(23, 59, 59, 999);
                break;
        }

        if (isSameDay(selectedDates[0], start) && isSameDay(selectedDates[1], end)) {
            return true;
        }

        return false;
    }

    return (
        <Card {...restCardProps} boxShadow={'md'} borderRadius={'2xl'}>
            <CardBody>
                <HStack mb={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Heading alignItems={'center'} size={'md'}>
                        Filter
                    </Heading>
                    <Badge borderRadius={'md'} alignItems={'center'} display={'flex'}>
                        {totalRecords ?? 0}
                        <Icon ml={1} as={CalendarIcon} />
                    </Badge>
                </HStack>

                <FormLabel>{intl.formatMessage({ id: 'period' })}</FormLabel>
                <HStack>
                    <RangeDatepicker
                        selectedDates={selectedDates}
                        onDateChange={(dates) => {
                            onDateChange(dates);
                        }}
                        usePortal
                        configs={{
                            placeHolders: [intl.formatMessage({ id: 'dateFrom' }), intl.formatMessage({ id: 'dateTo' })],
                        }}
                    >
                        {(dates) => (
                            <PopoverTrigger>
                                <Box>
                                    <TimeRangeVisualizer
                                        interactive
                                        cursor={'pointer'}
                                        dateFrom={dates[0] ? intl.formatDate(dates[0]) : intl.formatMessage({ id: 'dateFrom' })}
                                        dateTo={dates[1] ? intl.formatDate(dates[1]) : intl.formatMessage({ id: 'dateTo' })}
                                    />
                                </Box>
                            </PopoverTrigger>
                        )}
                    </RangeDatepicker>

                    {selectedDates.length > 0 && (
                        <IconButton
                            onClick={() => onDateChange([])}
                            aria-label="clear dates"
                            size={'sm'}
                            icon={<CloseIcon color={'red.500'} />}
                            variant={'ghost'}
                        ></IconButton>
                    )}
                </HStack>
                <Wrap mt={2}>
                    <WrapItem>
                        <FilterChip
                            colorScheme="gray"
                            label={intl.formatMessage({ id: 'timefilter.today' })}
                            onClear={() => {
                                onDateChange([]);
                            }}
                            selected={isChipSelected('day')}
                            onSelect={() => {
                                const start = new Date();
                                const end = new Date();
                                end.setHours(23, 59, 59, 999);
                                start.setHours(0, 0, 0, 0);
                                onDateChange([start, end]);
                            }}
                        />
                    </WrapItem>
                    <WrapItem>
                        <FilterChip
                            colorScheme="gray"
                            label={intl.formatMessage({ id: 'timefilter.week' })}
                            onClear={() => {
                                onDateChange([]);
                            }}
                            selected={isChipSelected('week')}
                            onSelect={() => {
                                const start = new Date();
                                start.setHours(0, 0, 0, 0);
                                start.setDate(start.getDate() - start.getDay());
                                const end = new Date();
                                end.setHours(23, 59, 59, 999);
                                end.setDate(start.getDate() + 6);
                                onDateChange([start, end]);
                            }}
                        />
                    </WrapItem>
                    <WrapItem>
                        <FilterChip
                            colorScheme="gray"
                            label={intl.formatMessage({ id: 'timefilter.month' })}
                            onClear={() => {
                                onDateChange([]);
                            }}
                            selected={isChipSelected('month')}
                            onSelect={() => {
                                const start = new Date();
                                start.setDate(1);
                                start.setHours(0, 0, 0, 0);
                                const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
                                end.setHours(23, 59, 59, 999);
                                onDateChange([start, end]);
                            }}
                        />
                    </WrapItem>
                </Wrap>
                <FormLabel mt={2}>{intl.formatMessage({ id: 'type' })}</FormLabel>
                <LoginDirectionButton showAll loginDirection={filterLoginDirection} onChange={onFilterLoginDirectionChange} />
            </CardBody>
        </Card>
    );
};
