import { Stack } from '@chakra-ui/react';
import { UploadedStatementTable } from './components/UploadedStatementTable';
import { UploadedStatementSideMenu } from './components/UpoadedStatementSideMenu';
import { useUploadedAttendanceStatement } from '../../../../query/combined/useUploadedAttendanceStatement';
import { StatementEditorSkeleton } from '../local/statementEditorCopoments/StatementSkeleton';
import { useSuToast } from '../../../../hooks/useSuToast';
import { useNavigate } from 'react-router-dom';
import { useDeleteDialog } from '../../../../hooks/useAlertDialog';

type UploadedAttendanceReportEditorProps = {
    reportId: number;
};

export const UploadedAttendanceStatementEditor: React.FC<UploadedAttendanceReportEditorProps> = ({ reportId }) => {
    const toast = useSuToast();
    const navigate = useNavigate();
    
    const { data, isLoading, startLocalUpdate, isStoring, deleteUploadedStatement } = useUploadedAttendanceStatement(reportId);
    const {Dialog: DeleteDialog, showDialog: showDeleteDialog} = useDeleteDialog(isLoading);
    
    if (isLoading || !data) {
        return <StatementEditorSkeleton />;
    }

    function handleDeleteStatement() {
        toast.baseDelete(async () => {
            await deleteUploadedStatement();
            navigate('/attendance?tab=1', { replace: true });
        });
    }

    return (
        <>
            <Stack px={2} direction={{ base: 'column', md: 'row' }} align={'start'} justify={'space-around'} maxW={1700} mx={'auto'}>
                <UploadedStatementTable data={data} />
                <UploadedStatementSideMenu 
                    data={data}
                    onEdit={startLocalUpdate}
                    isLoadingUpdate={isStoring}
                    onDelete={() => showDeleteDialog(handleDeleteStatement)}
                />
            </Stack>
            <DeleteDialog />
        </>
    );
};
