import { FormLabel, HStack, IconButton, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { DocumentStatus } from '../../../types/enums/DocumentStatus';
import { useSuIntl } from '../../../hooks/useSuIntl';
import SuSelect from '../../common/Select';
import { AddIcon } from '@chakra-ui/icons';
import { AdditionalReportSettingsData, SimpleAttendanceReportModal, simpleReportModalDataToQueryString } from './SimpleReportModal';
import { useNavigate } from 'react-router-dom';

type AttendanceStatementFilterProps = {
    filteredStatus?: DocumentStatus;
    onFilterStatusChange: (status: DocumentStatus | undefined) => void;
    userId?: number;
    onUserIdChange?: (userId: number) => void;
};

export const AttendanceStatementFilter: React.FC<AttendanceStatementFilterProps> = ({ filteredStatus, onFilterStatusChange }) => {
    const { t } = useSuIntl();
    const navigate = useNavigate();

    const reportModalDisclosure = useDisclosure();
    const FILTERABLE_STATUSES = [{ label: '-', value: 'ALL' }].concat(
        [DocumentStatus.DRAFT, DocumentStatus.DONE, DocumentStatus.ARCHIVED].map((status) => ({
            label: t(`doc.status.${status}`),
            value: status,
        }))
    );

    const handleCreateReport = (dateFrom: string, dateTo: string, userId: number, additionalSettings?: AdditionalReportSettingsData) => {
        const search = simpleReportModalDataToQueryString(dateFrom, dateTo, userId, additionalSettings);

        return navigate(`/attendance/reports?${search}`);
    };

    return (
        <>
            <HStack>
                <Wrap spacing={2}>
                    <WrapItem>
                        <IconButton
                            mr={2}
                            aria-label="Create report"
                            onClick={reportModalDisclosure.onOpen}
                            size={'sm'}
                            alignSelf={'center'}
                            className="days_content"
                            borderRadius={'xl'}
                            icon={<AddIcon />}
                            boxShadow={'md'}
                            py={4}
                        />
                    </WrapItem>
                    <WrapItem flexDir={'column'}>
                        <FormLabel fontSize={'sm'}>Status</FormLabel>
                        <SuSelect
                            colorScheme="primary"
                            selectedOption={filteredStatus ? filteredStatus : 'ALL'}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (selectedValue === 'ALL') {
                                    onFilterStatusChange(undefined);
                                } else {
                                    onFilterStatusChange(selectedValue as DocumentStatus);
                                }
                            }}
                            variant={'ounline'}
                            options={FILTERABLE_STATUSES}
                        />
                    </WrapItem>
                </Wrap>
            </HStack>
            <SimpleAttendanceReportModal
                isOpen={reportModalDisclosure.isOpen}
                onClose={reportModalDisclosure.onClose}
                onSubmit={handleCreateReport}
            />
        </>
    );
};
