import React, { useEffect, useState } from 'react';
import { RoundType, StoredPairedShift } from '../../../../../types';
import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    FormLabel,
    Text,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    VStack,
    Wrap,
    WrapItem,
    Input,
} from '@chakra-ui/react';
import { SuNumberInput } from '../../../../common/SuNumberInput';
import { DateTimeInput } from '../../../../common/DateTimeInput';
import { v4 as uuid } from 'uuid';
import { getDuration, roundToNearestHalfHour } from '../../../../../utils/timeUtils';
import { MdArrowDownward, MdArrowRightAlt, MdArrowUpward, MdClose } from 'react-icons/md';
import { TimeRangeVisualizer } from '../../../../common/DateRangeVisualizer';
import { roundNumber } from '../../../../../utils/formatUtils';
import { useSuIntl } from '../../../../../hooks/useSuIntl';
import { DocumentStatus } from '../../../../../types/enums/DocumentStatus';

type EditStatementShiftFormProps = {
    shift?: StoredPairedShift;
    onSubmit: (updatedShift: StoredPairedShift) => void;
    onCancel: () => void;
    isOpen: boolean;
    isLoading?: boolean;
    minDate?: Date;
    maxDate?: Date;
};

export const EditStatementShiftForm: React.FC<EditStatementShiftFormProps> = ({
    minDate,
    maxDate,
    shift,
    onCancel,
    onSubmit,
    isOpen,
    isLoading,
}) => {
    const { t } = useSuIntl();

    const [breakDuration, setBreakDuration] = React.useState<number>((shift?.breakDuration || 0) * 60);

    const [dateFrom, setDateFrom] = React.useState<Date | null>(shift?.arrival?.date ? new Date(shift.arrival?.date) : new Date());
    const [dateTo, setDateTo] = React.useState<Date | null>(shift?.departure?.date ? new Date(shift.departure?.date) : new Date());

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setDateFrom(shift?.arrival?.date ? new Date(shift.arrival?.date) : minDate || new Date());
        setDateTo(shift?.departure?.date ? new Date(shift.departure?.date) : null);
        setBreakDuration((shift?.breakDuration || 0) * 60);
    }, [isOpen, minDate, shift]);

    function getTotalTime() {
        if (!dateFrom || !dateTo) {
            return 0;
        }

        return getDuration(dateFrom.toISOString(), dateTo.toISOString(), 'hours');
    }

    function handleSubmit() {
        if (!dateFrom || !dateTo) {
            return;
        }
        if (shift) {
            const updatedShift: StoredPairedShift = {
                ...shift,
                arrival: {
                    ...shift.arrival!,
                    date: dateFrom.toISOString(),
                },
                departure: {
                    ...shift.departure!,
                    date: dateTo.toISOString(),
                },
                breakDuration: roundNumber(breakDuration / 60),
            };
            onSubmit(updatedShift);
        } else {
            const calculatedBreak = breakDuration ? roundNumber(breakDuration / 60) : 0;

            const newShift: StoredPairedShift = {
                id: uuid(),
                arrival: {
                    date: dateFrom.toISOString(),
                },
                departure: {
                    date: dateTo.toISOString(),
                },
                breakDuration: calculatedBreak,
                hoursInShift: getTotalTime() - calculatedBreak,
            };

            onSubmit(newShift);
        }
        onCancel();
    }

    function handleClose() {
        onCancel();
    }

    return (
        <Box>
            <Stack mb={4} direction={'column'} gap={2}>
                <VStack align={'start'}>
                    <HStack justify={'space-between'} width={'100%'}>
                        <FormLabel>{t('editor.time')}</FormLabel>
                        <Badge borderRadius={'full'} textTransform="none">
                            {getTotalTime()} h
                        </Badge>
                    </HStack>
                    <Box pl={2} width={'100%'}>
                        <FormLabel fontSize={'sm'}> {t('editor.from')}</FormLabel>
                        <DateTimeInput
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date) => {
                                setDateFrom(date);
                                // Adjust dateTo if it is before dateFrom
                                if (date && (!dateTo || (date && dateTo < date))) {
                                    const newDateTo = new Date(date?.getTime());
                                    if (dateTo) {
                                        newDateTo?.setHours(dateTo.getHours(), dateTo.getMinutes(), dateTo.getSeconds());
                                    }
                                    setDateTo(newDateTo);
                                }
                            }}
                            value={dateFrom}
                        />
                    </Box>
                    <Box pl={2} width={'100%'}>
                        <FormLabel fontSize={'sm'}>{t('editor.to')}</FormLabel>
                        <DateTimeInput
                            maxDate={maxDate}
                            minDate={minDate}
                            onChange={(date) => {
                                setDateTo(date);
                                // Adjust dateFrom if it is before dateTo
                                if (date && (!dateFrom || (date && dateFrom > date))) {
                                    const newDateFrom = new Date(date?.getTime() + 1000 * 60 * 60);
                                    if (dateFrom) {
                                        newDateFrom?.setHours(dateFrom.getHours(), dateFrom.getMinutes(), dateFrom.getSeconds());
                                    }
                                    setDateFrom(newDateFrom);
                                }
                            }}
                            value={dateTo}
                        />
                    </Box>
                </VStack>
                <Box minW={300} mt={2}>
                    <FormLabel>{t('attendance.break')}</FormLabel>
                    <SuNumberInput
                        title={t('attendance.break')}
                        max={(getTotalTime() < 0 ? 0 : getTotalTime()) * 60}
                        minW={'20px'}
                        value={breakDuration}
                        onChange={(value) => setBreakDuration(parseInt(value))}
                        format={(value) => `${value} min`}
                        step={15}
                    />
                </Box>
            </Stack>
            <HStack>
                <Button isDisabled={getTotalTime() <= 0} isLoading={isLoading} colorScheme="green" onClick={handleSubmit}>
                    {t('save')}
                </Button>
                <Button isDisabled={isLoading} colorScheme="red" variant={'ghost'} onClick={handleClose}>
                    {t('cancel')}
                </Button>
            </HStack>
        </Box>
    );
};

type EditStatementSUspendedHoursProps = {
    onSubmit: (value: number) => void;
    maxHours?: number;
    onClose: () => void;
    initialValue?: number;
    isLoading?: boolean;
    isOpen: boolean;
};

export const EditStatementSuspendedHours: React.FC<EditStatementSUspendedHoursProps> = ({
    onSubmit,
    maxHours,
    onClose,
    initialValue,
    isLoading,
    isOpen,
}) => {
    const { t } = useSuIntl();
    const [hours, setHours] = React.useState<number>(initialValue || 0);

    useEffect(() => {
        if (isOpen) {
            setHours(initialValue || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    function handleSubmit() {
        onSubmit(hours);
        onClose();
    }

    return (
        <Box>
            <VStack align={'start'} spacing={5} pt={4}>
                <SuNumberInput
                    title={t('attendance.suspendedTime')}
                    onChange={(value) => setHours(parseInt(value))}
                    step={1}
                    min={0}
                    max={maxHours}
                    value={hours}
                    format={(value) => `${value} h`}
                />
                <HStack justify={'left'}>
                    <Button
                        isDisabled={!!initialValue ? initialValue === hours : false}
                        isLoading={isLoading}
                        colorScheme="green"
                        onClick={handleSubmit}
                    >
                        {t('save')}
                    </Button>
                    <Button isDisabled={isLoading} colorScheme="red" variant={'ghost'} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </HStack>
            </VStack>
        </Box>
    );
};

type EditStatementNameProps = {
    initialValue?: string;
    onSubmit: (value: string) => void;
    onClose: () => void;
    isLoading?: boolean;
    isOpen: boolean;
};

export const EditStatementName: React.FC<EditStatementNameProps> = ({ initialValue, onSubmit, onClose, isLoading, isOpen }) => {
    const { t } = useSuIntl();
    const [name, setName] = useState<string>(initialValue || '');

    useEffect(() => {
        if (isOpen) {
            setName(initialValue || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    function handleSubmit() {
        onSubmit(name);
        onClose();
    }

    return (
        <Box>
            <VStack align={'start'} spacing={5} pt={4}>
                <Input
                    w={500}
                    variant={'filled'}
                    size={'lg'}
                    isInvalid={name.length < 3}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <HStack justify={'left'}>
                    <Button
                        isDisabled={!!initialValue ? initialValue === name : name.length < 3}
                        isLoading={isLoading}
                        colorScheme="green"
                        onClick={handleSubmit}
                    >
                        {t('save')}
                    </Button>
                    <Button isDisabled={isLoading} colorScheme="red" variant={'ghost'} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </HStack>
            </VStack>
        </Box>
    );
};

type EditStatementStatusProps = {
    initialValue?: DocumentStatus;
    onSubmit: (value: DocumentStatus) => void;
    onClose: () => void;
    isLoading?: boolean;
    isOpen: boolean;
};
export const EditStatementStatus: React.FC<EditStatementStatusProps> = ({ initialValue, onSubmit, onClose, isLoading, isOpen }) => {
    const { t } = useSuIntl();

    function handleSubmit(newStatus: DocumentStatus) {
        onSubmit(newStatus);
        onClose();
    }

    return (
        <Box>
            <VStack align={'center'} spacing={1} pt={4}>
                <Button
                    isLoading={isLoading}
                    colorScheme="green"
                    onClick={() => handleSubmit(initialValue === DocumentStatus.DRAFT ? DocumentStatus.DONE : DocumentStatus.DRAFT)}
                >
                    {t(`doc.status.markAs.${initialValue === DocumentStatus.DRAFT ? DocumentStatus.DONE : DocumentStatus.DRAFT}`)}
                </Button>
                <Button isDisabled={isLoading} colorScheme="red" variant={'ghost'} onClick={onClose}>
                    {t('cancel')}
                </Button>
            </VStack>
        </Box>
    );
};

type BulkRoundTimeActionProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: Promise<StoredPairedShift[]>) => void;
    dataForUpdate: StoredPairedShift[];
    onUnSelectId: (id: string) => void;
    isLoading?: boolean;
};

export const BulkRoundTimeAction: React.FC<BulkRoundTimeActionProps> = ({ isOpen, onClose, onSubmit, dataForUpdate, onUnSelectId }) => {
    const { t, formatTime } = useSuIntl();
    const [roundArrivalType, setRoundArrivalType] = useState<RoundType>(RoundType.UP);
    const [roundDepartureType, setRoundDepartureType] = useState<RoundType>(RoundType.DOWN);
    const [showAll, setShowAll] = useState(false);

    async function performRound(): Promise<StoredPairedShift[]> {
        return new Promise((resolve) => {
            const updatedShifts = dataForUpdate.map((shift) => {
                if (shift.arrival?.date) {
                    shift.arrival.date = roundToNearestHalfHour(shift.arrival.date);
                }

                if (shift.departure?.date) {
                    shift.departure.date = roundToNearestHalfHour(shift.departure.date);
                }

                return shift;
            });

            resolve(updatedShifts);
        });
    }

    async function handleSubmit() {
        onClose();
        setTimeout(() => {
            onSubmit(performRound()); // Start the async action after the modal has closed
        }, 300);
    }

    const changeExamples = () => {
        return showAll && dataForUpdate ? dataForUpdate : dataForUpdate.slice(0, 8);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'lg'} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent borderRadius={'xl'}>
                <ModalHeader>{t('editor.roundTime')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HStack mb={4} justify={'space-between'}>
                        <Text fontWeight={'500'}>{t('attendance.arrivals')}</Text>
                        <RoundTypeButton value={roundArrivalType} onChange={setRoundArrivalType} />
                    </HStack>

                    <Wrap>
                        {changeExamples().map((shift) => (
                            <WrapItem key={shift.id}>
                                <TimeRangeVisualizer
                                    onLeftIconClick={() => onUnSelectId(shift.id)}
                                    dateFrom={formatTime(shift.arrival?.date)}
                                    dateTo={
                                        shift.arrival?.date
                                            ? formatTime(roundToNearestHalfHour(shift.arrival?.date, roundArrivalType))
                                            : undefined
                                    }
                                    middleIcon={MdArrowRightAlt}
                                    leftIcon={MdClose}
                                />
                            </WrapItem>
                        ))}
                        {dataForUpdate.length > 8 && !showAll && (
                            <WrapItem>
                                <Text>....</Text>
                            </WrapItem>
                        )}
                    </Wrap>
                    {dataForUpdate.length > 8 && (
                        <Button onClick={() => setShowAll(!showAll)} size={'sm'} variant={'link'}>
                            {t(showAll ? 'viewLess' : 'viewMore')}
                        </Button>
                    )}
                    <HStack mb={4} mt={8} justify={'space-between'}>
                        <Text fontWeight={'500'}>{t('attendance.departures')}</Text>
                        <RoundTypeButton value={roundDepartureType} onChange={setRoundDepartureType} />
                    </HStack>

                    <Wrap>
                        {changeExamples().map((shift) => (
                            <WrapItem key={shift.id}>
                                <TimeRangeVisualizer
                                    onLeftIconClick={() => onUnSelectId(shift.id)}
                                    dateFrom={formatTime(shift.departure?.date)}
                                    dateTo={
                                        shift.departure?.date
                                            ? formatTime(roundToNearestHalfHour(shift.departure?.date, roundDepartureType))
                                            : undefined
                                    }
                                    middleIcon={MdArrowRightAlt}
                                    leftIcon={MdClose}
                                />
                            </WrapItem>
                        ))}
                        {dataForUpdate.length > 8 && !showAll && (
                            <WrapItem>
                                <Text>....</Text>
                            </WrapItem>
                        )}
                    </Wrap>
                    {dataForUpdate.length > 8 && (
                        <Button mb={8} onClick={() => setShowAll(!showAll)} size={'sm'} variant={'link'}>
                            {t(showAll ? 'viewLess' : 'viewMore')}
                        </Button>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="green" onClick={handleSubmit}>
                        {t('editor.roundTime')}
                    </Button>
                    <Button colorScheme="red" variant={'ghost'} mr={3} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

type RoundTypeButtonProps = {
    value: RoundType;
    onChange: (value: RoundType) => void;
};

export const RoundTypeButton: React.FC<RoundTypeButtonProps> = ({ value, onChange }) => {
    const { t } = useSuIntl();
    return (
        <ButtonGroup isAttached size={'sm'}>
            <Button
                leftIcon={<MdArrowDownward />}
                variant={value === RoundType.DOWN ? 'solid' : 'outline'}
                onClick={() => onChange(RoundType.DOWN)}
            >
                {t('editor.roundTime.down')}
            </Button>

            <Button variant={value === RoundType.NORMAL ? 'solid' : 'outline'} onClick={() => onChange(RoundType.NORMAL)}>
                {t('editor.roundTime.nearest')}
            </Button>

            <Button
                rightIcon={<MdArrowUpward />}
                variant={value === RoundType.UP ? 'solid' : 'outline'}
                onClick={() => onChange(RoundType.UP)}
            >
                {t('editor.roundTime.up')}
            </Button>
        </ButtonGroup>
    );
};
