import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Flex, SlideFade } from '@chakra-ui/react';
import { useGetAttendanceById } from '../../query/resource-hooks/company';
import { useIntl } from 'react-intl';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { AttendancePreview } from './AttendancePreview';
import { FaInfo } from 'react-icons/fa';
import { useState } from 'react';
import { User } from '../../types';

type AttendanceDetailModalProps = {
    isOpen: boolean;
    onClose: () => void;
    currentId?: number;
    handleGoToNext: () => void;
    handleGoToPrevious: () => void;
    hasNext?: boolean;
    hasPrevious?: boolean;
    handleGoToDetail: (id: number) => void;
    loggedUser?: User;
};

export const AttendanceDetailModal: React.FC<AttendanceDetailModalProps> = ({
    onClose,
    isOpen,
    currentId,
    handleGoToDetail,
    handleGoToNext,
    handleGoToPrevious,
    hasNext,
    hasPrevious,
    loggedUser,
}) => {
    const intl = useIntl();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const attendanceQuery = useGetAttendanceById(currentId);

    function handleDebounceButtons(callback: () => void) {
        callback();
        setButtonsDisabled(true);
        setTimeout(() => {
            setButtonsDisabled(false);
        }, 500);
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent maxW={'1180px'}maxH={'100vh'} borderRadius={'2xl'}>
                <ModalHeader>{intl.formatMessage({ id: 'attendance.preview.title' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SlideFade in key={currentId} offsetY={'-30px'}>
                        <AttendancePreview
                            loggedUser={loggedUser}
                            onUpdateSuccess={() => attendanceQuery.refetch()}
                            onDeleteSuccess={() => onClose()}
                            attendance={attendanceQuery.data}
                            isLoading={attendanceQuery.isLoading}
                        />
                    </SlideFade>
                </ModalBody>
                <Flex mt={{ base: 0, md: 100 }} p={4} justifyContent={'space-between'}>
                    <Button
                        isDisabled={!hasPrevious}
                        isLoading={buttonsDisabled}
                        variant={'ghost'}
                        leftIcon={<ChevronLeftIcon />}
                        colorScheme="primary"
                        onClick={() => handleDebounceButtons(handleGoToPrevious)}
                    >
                        {intl.formatMessage({ id: 'attendance.preview.previous' })}
                    </Button>
                    <Button
                        leftIcon={<FaInfo />}
                        isDisabled={!!!currentId}
                        colorScheme="primary"
                        onClick={() => handleGoToDetail(currentId!)}
                    >
                        {intl.formatMessage({ id: 'attendance.preview.goToDetail' })}
                    </Button>
                    <Button
                        isDisabled={!hasNext}
                        isLoading={buttonsDisabled}
                        variant={'ghost'}
                        rightIcon={<ChevronRightIcon />}
                        colorScheme="primary"
                        onClick={() => handleDebounceButtons(handleGoToNext)}
                    >
                        {intl.formatMessage({ id: 'attendance.preview.next' })}
                    </Button>
                </Flex>
            </ModalContent>
        </Modal>
    );
};
