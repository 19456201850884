import { Stack, VStack, Box, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { HoverCard } from './HoverCard';
import { useRive, Layout, Fit, useStateMachineInput } from '@rive-app/react-canvas';
import { useIsMobile } from '../../../hooks/usePlatform';
import { useIntl } from 'react-intl';

export const WebAppPreview: React.FC = () => {
    const isMobile = useIsMobile();
    const intl = useIntl();

    const RIVE_STATE_MAP: Record<string, number> = {
        clear: 0,
        show_content: 2,
        show_nav: 1,
        show_header: 3,
    };

    const [selectedState, setSelectedState] = useState(0);

    const { rive, RiveComponent } = useRive({
        src: `${process.env.PUBLIC_URL}/animations/su_intro.riv`,
        stateMachines: 'show_parts',
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
        }),
        onStateChange: (event) => {
            if (Array.isArray(event.data) && event.data.length > 0) {
                const state = RIVE_STATE_MAP[event.data[0] as string];
                setSelectedState(state ?? 0);
            }
        },
    });

    const animationState = useStateMachineInput(rive, 'show_parts', 'state', 0);

    function handleContentPartHover(partState: number) {
        if (animationState) {
            animationState.value = partState;
        }
    }

    const warningTextColor = useColorModeValue('white', 'surface.500');
    if (isMobile) {
        return (
            <Box bg={'warningElevationColors.600'} p={2} mt={3} borderRadius={'2xl'} boxShadow={'md'}>
                <Text fontWeight={'600'} color={warningTextColor}>
                    {intl.formatMessage({ id: 'intro.apps.webApp.useDesktop' })}
                </Text>
            </Box>
        );
    }

    return (
        <Box borderRadius={'2xl'}>
            <Stack direction={{ base: 'column', md: 'row' }}>
                <VStack p={3} maxW={400} gap={10}>
                    <HoverCard
                        title={intl.formatMessage({ id: 'intro.apps.webApp.topBar.title' })}
                        text={intl.formatMessage({ id: 'intro.apps.webApp.topBar.text' })}
                        onMouseEnter={() => handleContentPartHover(3)}
                        onMouseExit={() => handleContentPartHover(0)}
                        isSelected={selectedState === 3}
                    />
                    <HoverCard
                        title={intl.formatMessage({ id: 'intro.apps.webApp.sideBar.title' })}
                        text={intl.formatMessage({ id: 'intro.apps.webApp.sideBar.text' })}
                        onMouseEnter={() => handleContentPartHover(1)}
                        onMouseExit={() => handleContentPartHover(0)}
                        isSelected={selectedState === 1}
                    />
                    <HoverCard
                        title={intl.formatMessage({ id: 'intro.apps.webApp.content.title' })}
                        text={intl.formatMessage({ id: 'intro.apps.webApp.content.text' })}
                        onMouseEnter={() => handleContentPartHover(2)}
                        onMouseExit={() => handleContentPartHover(0)}
                        isSelected={selectedState === 2}
                    />
                </VStack>
                <Box p={5} display={'flex'} width={'100%'}>
                    <RiveComponent />
                </Box>
            </Stack>
        </Box>
    );
};
