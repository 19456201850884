import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Fit, Layout, useRive } from '@rive-app/react-canvas';
import { useIntl } from 'react-intl';

export const IntroAttendance: React.FC = () => {
    const intl = useIntl();

    const { RiveComponent } = useRive({
        src: `${process.env.PUBLIC_URL}/animations/su_intro.riv`,
        stateMachines: 'corlor_modes',
        autoplay: true,
        artboard: 'attendance_record',
        layout: new Layout({
            fit: Fit.Contain,
        }),
    });

    return (
        <Box pl={5}>
            <Heading>{intl.formatMessage({ id: 'intro.attendance.title' })}</Heading>
            <Text>{intl.formatMessage({ id: 'intro.attendance.intro' })}</Text>

            <Heading pt={5} size={'md'}>
                {intl.formatMessage({ id: 'intro.attendance.subtitle' })}
            </Heading>
            <Text>{intl.formatMessage({ id: 'intro.attendance.record' })}</Text>
            <Flex h={300} w={'100%'}>
                <RiveComponent />
            </Flex>

            <Heading>{intl.formatMessage({ id: 'intro.attendance.zones.title' })}</Heading>
            <Text>{intl.formatMessage({ id: 'intro.attendance.zones.text' })}</Text>
        </Box>
    );
};
