import { db } from '../indexedDb/db';
import { useDeleteLocalStatement } from '../indexedDb/useStoreLocalReport';
import { useDeleteAttendanceStatement } from '../resource-hooks/attendanceStatement';

export const useDeleteAttendanceStatementCombined = () => {
    const deleteLocal = useDeleteLocalStatement();
    const deleteUploaded = useDeleteAttendanceStatement();

    /**
     * Deletes only locally stored statement
     */
    async function deleteLocalStatementOnly(localId: number) {
        await deleteLocal.deleteStatement(localId);
    }

    /**
     * Deletes only uploaded statement
     */
    async function deleteUploadedStatementOnly(externalId: number) {
        await deleteUploaded.mutateAsync(externalId);
    }

    /**
     * Deletes both locally stored and uploaded statement
     */
    async function deleteCombinedStatement({ localId, externalId }: { localId?: number; externalId?: number }) {
        
        if (localId) {
            await deleteLocalStatementOnly(localId);
        }

        if (externalId) {
            const localData = await db.attendanceStatements.where('externalId').equals(externalId).first();
            if (localData) {
                console.log('Local record found, deleting it');
                await deleteLocalStatementOnly(localData.id);
            }

            await deleteUploadedStatementOnly(externalId);
        }
    }

    return {
        deleteLocalStatementOnly,
        deleteUploadedStatementOnly,
        deleteCombinedStatement,
    };
};
