import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import CompanyService from '../services/CompanyService';
import { CACHE_KEY_COMPANY, CACHE_KEY_USER } from '../constants';
import {
    AttendanceLogin,
    AttendanceLoginDetail,
    AttendanceLoginHistoryPagination,
    AttendanceReport,
    AttendanceReportFilter,
    BasePaginationFilter,
    BusinessIdentity,
    Company,
    EmployeesFilter,
    LoginDirection,
    LoginPagination,
    LoginsFilter,
    Role,
    Statistics,
    User,
    UserPagination,
    Zone,
    ZonePagination,
    ZonesFilter,
} from '../../types';
import { AccountMode } from '../../types/enums/AccountMode';
import { Marker } from '../../types';

const useAddUserToCompany = (onSuccess?: (data: any) => void, onError?: (error: AxiosError<any>) => void) => {
    const queryClient = useQueryClient();

    return useMutation<any, AxiosError, { inviteCode: string }>({
        mutationFn: (data) => CompanyService.addUserToCompanyByCode(data.inviteCode),
        onSuccess: (data) => {
            queryClient.invalidateQueries(CACHE_KEY_USER);
            onSuccess && onSuccess(data);
        },
        onSettled: () => {
            queryClient.refetchQueries(CACHE_KEY_USER, { inactive: true });
        },
        onError: onError,
    });
};

const useCreateCompany = (onSuccess?: (data: Company) => void, onError?: (error: AxiosError<any>) => void) => {
    const queryClient = useQueryClient();
    return useMutation<Company, AxiosError, { companyName: string }>({
        mutationFn: CompanyService.createCompany,
        onSuccess: (data) => {
            queryClient.invalidateQueries(CACHE_KEY_USER);
            onSuccess && onSuccess(data);
        },
        onError: onError,
    });
};

const useGetCompanyDetail = () => {
    return useQuery<Company, AxiosError<any>>({
        queryFn: CompanyService.getCompanyDetail,
        queryKey: CACHE_KEY_COMPANY,
    });
};

const useGetCompanyStatistics = (params: { dateFrom?: string; dateTo?: string }) => {
    return useQuery<Statistics, AxiosError<any>>({
        queryFn: () => CompanyService.getCompanyStatistics(params),
        queryKey: ['statistics', params],
    });
};

const useGetUserProfileDetail = (id: number) => {
    return useQuery<User, AxiosError<any>>({
        queryFn: () => CompanyService.getEmployeeProfileDetail(id),
        queryKey: ['user', id],
        enabled: !!id,
    });
};

const useGetZoneById = (id: number) => {
    return useQuery<Zone, AxiosError<any>>({
        queryFn: () => CompanyService.getZoneById(id),
        queryKey: ['zone', id],
    });
};

const useGetAttendanceReport = (filter?: AttendanceReportFilter) => {
    return useQuery<AttendanceReport[], AxiosError<any>>({
        queryFn: () => CompanyService.createAttendancePeriodReport(filter!),
        queryKey: ['attendanceReport', filter],
        enabled: !!filter?.dateFrom && !!filter?.dateTo && filter.userIds?.length > 0,
    });
};

const useGetAttendanceById = (id?: number | null) => {
    return useQuery<AttendanceLoginDetail, AxiosError<any>>({
        queryFn: () => CompanyService.getAttendanceById(id!),
        queryKey: ['attendance', id],
        enabled: !!id,
    });
};

const useGetZonesPagination = (filter: ZonesFilter) => {
    return useInfiniteQuery<ZonePagination, AxiosError<any>>({
        queryKey: ['zones', filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getZonesPaginated(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
    });
};

const useGetAttendancePagination = (filter: LoginsFilter) => {
    return useInfiniteQuery<LoginPagination, AxiosError<any>>({
        queryKey: ['attendance', filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getAttendancePaginated(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
    });
};

const useGetEmployeesPagination = (filter: EmployeesFilter) => {
    return useInfiniteQuery<UserPagination, AxiosError<any>>({
        queryKey: ['employees', filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getEmployeesPaginated(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
    });
};

const useGetAttendanceLoginHistory = (loginId: number, filter: BasePaginationFilter) => {
    return useInfiniteQuery<AttendanceLoginHistoryPagination, AxiosError<any>>({
        queryKey: ['attendanceHistory', loginId, filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getAttendanceHistory(loginId, pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
    });
};

const useCreateAttendanceReport = (onSuccess?: (data: AttendanceReport[]) => void, onError?: (error: AxiosError<any>) => void) => {
    // const queryClient = useQueryClient();
    return useMutation<AttendanceReport[], AxiosError, AttendanceReportFilter>({
        mutationFn: CompanyService.createAttendancePeriodReport,
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError: onError,
    });
};

const useUpdateAttendance = (onSuccess?: (data: AttendanceLogin) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<
        AttendanceLogin,
        AxiosError,
        {
            id: number;
            data: {
                note?: string | null;
                createdAt?: string;
                zoneId?: number | null;
                direction?: LoginDirection;
                updateNote?: string;
                approve?: boolean;
            };
        }
    >({
        onSuccess: onSuccess,
        onError: onError,
        mutationFn: ({ id, data }) => CompanyService.updateAttendance(id, data),
    });
};

const useDeleteAttendance = (onSuccess?: () => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<void, AxiosError, number>({
        onSuccess: onSuccess,
        onError: onError,
        mutationFn: (id) => CompanyService.deleteAttendance(id),
    });
};

const useCreateManualAttendance = (onSuccess?: (data: AttendanceLogin) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<
        AttendanceLogin,
        AxiosError,
        { createdAt: string; zoneId: number | null | undefined; direction: LoginDirection; userId: number; note?: string }
    >({
        onSuccess: onSuccess,
        onError: onError,
        mutationFn: (data) => CompanyService.createManualAttendance(data),
    });
};

const useCreateEmployee = (onSuccess?: (data: User) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<
        User,
        AxiosError,
        {
            userName: string;
            password: string;
            firstName: string;
            surname?: string;
            role: Role;
            mode?: AccountMode;
            defaultZoneId?: number | null;
        }
    >({
        mutationFn: CompanyService.createEmployee,
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useUpdateEmployee = (onSuccess?: (data: User) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<User, AxiosError, { data: Partial<User> & { image?: File } }>({
        mutationFn: ({ data }) => CompanyService.updateEmployee(data),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useUpdateEmployeeIdentity = (onSuccess?: (data: User) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<User, AxiosError, { userId: number; data: BusinessIdentity }>({
        mutationFn: ({ data, userId }) => CompanyService.updateEmployeeIdentity(userId, data),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useUpdateCompany = (onSuccess?: (data: Company) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<Company, AxiosError, Partial<BusinessIdentity> & { logo?: File }>({
        mutationFn: CompanyService.updateCompany,
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useCreateZone = (onSuccess?: (data: Zone) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<Zone, AxiosError, { name: string; markers: Marker[] }>({
        mutationFn: CompanyService.createZone,
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useUpdateZone = (onSuccess?: (data: Zone) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<Zone, AxiosError, { zoneId: number; name: string; markers: Marker[] }>({
        mutationFn: CompanyService.updateZone,
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useDeleteZone = (onSuccess?: () => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<void, AxiosError, number>({
        mutationFn: CompanyService.deleteZone,
        onSuccess: onSuccess,
        onError: onError,
    });
};

// ------ Archive -----

const useArchivedEmployeesQuery = (filter: BasePaginationFilter) => {
    return useInfiniteQuery<UserPagination, AxiosError<any>>({
        queryKey: ['archived_employees', filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getArchivedEmployees(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
    });
};

const useRestoreArchivedUser = (onSuccess?: () => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<User, AxiosError, number>({
        mutationFn: CompanyService.restoreArchivedUser,
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useDeleteEmployee = (onSuccess?: () => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<void, AxiosError, number>({
        mutationFn: CompanyService.deleteEmployee,
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useArchivedZonesQuery = (filter: BasePaginationFilter) => {
    return useInfiniteQuery<ZonePagination, AxiosError<any>>({
        queryKey: ['archived_zones', filter],
        queryFn: ({ pageParam = filter }) => CompanyService.getZonesArchive(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                ...filter,
                page: lastPage.meta.current_page + 1,
            };
        },
    });
};

const useGetArchivedZoneDetail = (id: number) => {
    return useQuery<Zone, AxiosError<any>>({
        queryFn: () => CompanyService.getArchivedZoneDetail(id),
        queryKey: ['archived_zone', id],
    });
};

export {
    useAddUserToCompany,
    useCreateCompany,
    useGetCompanyDetail,
    useGetCompanyStatistics,
    useGetUserProfileDetail,
    useGetZoneById,
    useGetAttendanceById,
    useGetZonesPagination,
    useGetAttendancePagination,
    useGetEmployeesPagination,
    useCreateAttendanceReport,
    useGetAttendanceReport,
    useUpdateAttendance,
    useDeleteAttendance,
    useCreateManualAttendance,
    useCreateEmployee,
    useUpdateEmployee,
    useUpdateEmployeeIdentity,
    useGetAttendanceLoginHistory,
    useUpdateCompany,
    useCreateZone,
    useUpdateZone,
    useDeleteZone,
    useArchivedEmployeesQuery,
    useRestoreArchivedUser,
    useDeleteEmployee,
    useArchivedZonesQuery,
    useGetArchivedZoneDetail,
};
